import React, { useState } from 'react'
import { BsFillCameraFill, BsPencilFill } from "react-icons/bs";
import EditProfilePopup from '../editProfilePopup/EditProfilePopup';

function UserProfile() {

    const data = {
        userDetails: [
            {
                title: "First Name",
                value: "Mohit",
            },
            {
                title: "Last Name",
                value: "Singh",
            },
            {
                title: "Email",
                value: "lsdm.mohit@gmail.com",
            },
            {
                title: "Phone Number",
                value: "+91 99232 99203",
            },
            {
                title: "Date of Birth",
                value: "2025-03-29",
            },
            {
                title: "Home Address",
                value: "Ferozepur Rd, Gurdev Nagar, Ludhiana, Punjab 141003",
            },
            {
                title: "Premium",
                value: "Yes",
            },
        ],
    }

    const profileImg = "img/profile.jpg";
    const [profilePic, setProfilePic] = useState(profileImg);
    const [userData, setUserData] = useState(data.userDetails);

    const editProfile = () => {
        const editProfilePopup = document.getElementById("editProfilePopup");
        editProfilePopup.classList.remove("d-none");
    }

    const updateUserDetails = () => {
        const newUserDetails = [];
        const fields = document.querySelectorAll(".userFormField");
        fields.forEach(field => {
            const fieldData = {
                title: field.id,
                value: field.querySelector("input").value,
            }
            newUserDetails.push(fieldData);
        })
        setUserData(newUserDetails);
        console.log(newUserDetails);
    }

    // handle image upload and remove 
    function uploadPorfilePic(event) {
        const file = event.target.files[0]
        if (file) {
            // first api 
            const url = 'https://web-image-storage.p.rapidapi.com/upload';
            const data = new FormData();
            data.append('deleteCode', '1234');
            data.append('image', file);
            const options = {
                method: 'POST',
                headers: {
                    'X-RapidAPI-Key': 'c75f2731afmshb6e5112398ffcadp1662a4jsnc6c1270a3f5a',
                    'X-RapidAPI-Host': 'web-image-storage.p.rapidapi.com'
                },
                body: data,
            };
            fetch(url, options)
            .then(resp => resp.json())
            .then((data) => {
                const dataUrl = data.url;
                setProfilePic(dataUrl)
            }) .catch((error) => {
                console.log("Error uploading file: ", error);
            })

        }

    }

  return (
    <div className='xCenter gap-2'>

        <div className="imgName xCenter gap-2">
            <div className="imgWrapper overflow-hidden rounded-3" style={{width: "70px", height: "70px", maxWidth: "70px", maxHeight: "70px"}}>
                <img src={profilePic} alt="profile" className='w-100 h-100 ' style={{objectFit: "cover"}}/>
            </div>
            <div className="yCenter justify-content-between">
                <h4 className='m-0'>{userData[0].value + " " + userData[1].value}</h4>
                <div className="editIcons yCenter gap-2 me-5">
                    <label htmlFor="editPhoto" className="iconWrapper cursorPointer" style={{fontSize: "16px"}}><BsFillCameraFill /></label>
                    <input type="file" name="editPhoto" id="editPhoto" className='d-none' onChange={uploadPorfilePic} />
                    <span className="iconWrapper cursorPointer" style={{fontSize: "14px"}} onClick={editProfile}><BsPencilFill /></span>
                </div>
            </div>
        </div>

        <div className="detailsContainer xCenter gap-2">

            <div className="userDetails xCenter gap-2">
                {userData.map((userDetail, index) => {
                    return(
                        <div key={"userDetail"+index} className={`row ${userDetail.title === "Password" || userDetail.title === "First Name" || userDetail.title === "Last Name" ? "d-none" : ""}`}>
                            <div className="col-4 lighterText">
                                <p className="m-0 title">{userDetail.title}</p>
                            </div>
                            <div className="col-8">
                                <p className={`m-0 value ${userDetail.title === "Email" || userDetail.title === "Phone Number" ? "text-primary" : ""}`}>{userDetail.value}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
            
        </div>

        <div id="editProfilePopup" className='d-none'>
            <EditProfilePopup onSubmitForm={updateUserDetails} />
        </div>
      
    </div>
  )
}

export default UserProfile
