import React, { useEffect, useState } from 'react'
import { fetchParticipants } from '../../functions';

function Leaderboard({test_id,totalMarks,participantsRef}) {

    const [participants,setParticipants ] = useState([]);
    const [topPerformer,setTopPerformer ] = useState();

    useEffect(() => {
        const getData = async ()=>{
            const people = await fetchParticipants(test_id,"marks","DESC");
            console.log("people is   ",people);
            setParticipants(people);
            const topPerformer = people[0];
            setTopPerformer(topPerformer);
        }
        getData();

    }, [participantsRef])
    

  return (
    <div className='leaderboard'>
        <h6 className="text-muted">Leaderboard</h6>
        <div className="leader mt-3 mb-2">
            <div className="card justify-content-between">
                <div className="left d-flex align-items-center">
                    <div className="icon"><img className='h-100 w-100' src="https://dizzi.link/assets/icons/first.png" alt="" /></div>
                    <h6 className="name text-center m-0">{topPerformer ? topPerformer.name : "Top Performer"}</h6>
                </div>
                <div className="right d-flex align-items-center">
                    <div className="marks">
                        <span className="scored">{topPerformer ? topPerformer.marks : 0}</span>
                        <span className="total text-muted"> /{totalMarks}</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="followingContainer">
            <div className="following">
                {
                    participants.map((participant,index)=>{
                        return (
                            <div key={"participant1"+index} className="card justify-content-between">
                                <div className="left d-flex align-items-center">
                                    <div className="icon"><img className='h-100 w-100' src="https://dizzi.link/assets/icons/ribbon-badge.png" alt="" /></div>
                                    <h6 className="name text-center m-0">{participant.name}</h6>
                                </div>
                                <div className="right d-flex align-items-center">
                                    <div className="marks">
                                        <span className="scored">{participant.marks}</span>
                                        <span className="total text-muted"> /{totalMarks}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            {/* <div className="following">
                {
                    participants.map((participant,index)=>{
                        return (
                            <div key={"participant2"+index} className="card">
                                 <div className="left d-flex align-items-center">
                                    <div className="icon"><img className='h-100 w-100' src="https://dizzi.link/assets/icons/ribbon-badge.png" alt="" /></div>
                                    <h6 className="name text-center m-0">{participant.name}</h6>
                                </div>
                                <div className="right d-flex align-items-center">
                                    <div className="marks">
                                        <span className="scored">{participant.marks}</span>
                                        <span className="total text-muted"> /{totalMarks}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className="following">
                {
                    participants.map((participant,index)=>{
                        return (
                            <div key={"participant2"+index} className="card">
                                 <div className="left d-flex align-items-center">
                                    <div className="icon"><img className='h-100 w-100' src="https://dizzi.link/assets/icons/ribbon-badge.png" alt="" /></div>
                                    <h6 className="name text-center m-0">{participant.name}</h6>
                                </div>
                                <div className="right d-flex align-items-center">
                                    <div className="marks">
                                        <span className="scored">{participant.marks}</span>
                                        <span className="total text-muted"> /{totalMarks}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className="following">
                {
                    participants.map((participant,index)=>{
                        return (
                            <div key={"participant2"+index} className="card">
                                 <div className="left d-flex align-items-center">
                                    <div className="icon"><img className='h-100 w-100' src="https://dizzi.link/assets/icons/ribbon-badge.png" alt="" /></div>
                                    <h6 className="name text-center m-0">{participant.name}</h6>
                                </div>
                                <div className="right d-flex align-items-center">
                                    <div className="marks">
                                        <span className="scored">{participant.marks}</span>
                                        <span className="total text-muted"> /{totalMarks}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className="following">
                {
                    participants.map((participant,index)=>{
                        return (
                            <div key={"participant3"+index} className="card">
                                 <div className="left d-flex align-items-center">
                                    <div className="icon"><img className='h-100 w-100' src="https://dizzi.link/assets/icons/ribbon-badge.png" alt="" /></div>
                                    <h6 className="name text-center m-0">{participant.name}</h6>
                                </div>
                                <div className="right d-flex align-items-center">
                                    <div className="marks">
                                        <span className="scored">{participant.marks}</span>
                                        <span className="total text-muted"> /{totalMarks}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className="following">
                {
                    participants.map((participant,index)=>{
                        return (
                            <div key={"participant4"+index} className="card">
                                 <div className="left d-flex align-items-center">
                                    <div className="icon"><img className='h-100 w-100' src="https://dizzi.link/assets/icons/ribbon-badge.png" alt="" /></div>
                                    <h6 className="name text-center m-0">{participant.name}</h6>
                                </div>
                                <div className="right d-flex align-items-center">
                                    <div className="marks">
                                        <span className="scored">{participant.marks}</span>
                                        <span className="total text-muted"> /{totalMarks}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div> */}
        </div>
    </div>
  )
}

export default Leaderboard