import React, { useState } from 'react'
import { parseCsv, secondsToMinutes, validateQuestions } from '../../functions';

function UploadFile({ handleQuestionsUploaded }) {
    const allOptions = ["A", "B", "C", "D", "E"];
    const [questions, setQuestions] = useState([]);
    const [fileUploadView, setFileUploadView] = useState("form-control questionFileInput");
    const [fileUploadValidationMessage, setFileUploadValidationMessage] = useState();
    const [fileUploaded, setFileUploaded] = useState(false);
    const [questionCsv, setQuestionCsv] = useState();

    const [file,setFile] = useState();

    const doUpload = async ()=>{
        const url = 'https://dizzi.link/api/uploadCsvFile'; 
        const data = new FormData(); 
        data.append('deleteCode', '1234'); 
        data.append('file', file);
        const options = { 
            method: 'POST', 
            headers: { 
                'x-api-key': '89bc723f-0e24-4feb-8981-f96960ce807d', 
            },  
            body: data,
        };

        try{
            const response = await fetch(url, options);
            const data = await response.json();
        //   modifyCompanyAttributes('logo',data.url);
            setFileUploaded(true);
            handleQuestionsUploaded(data.url)
            setQuestionCsv(data.url)
            console.log(data.url);
        }catch(error){
            console.log("Error uploading file: ", error);
        }
    }

    const handleFileUpload = async (event) => {
        const file = event.target.files[0]
        if (file) {
            const fileName = file.name;
            const fileExtension = fileName.split('.').pop();
            if (fileExtension !== "csv") {
                setFileUploadValidationMessage("Only CSV Format is Allowed")
                setFileUploadView("form-control questionFileInput is-invalid");
                return;
            } else {
                setFileUploadValidationMessage();
                setFileUploadView("form-control questionFileInput is-valid");
            }
            // validate CSV
            const questions = await parseCsv(file);
            console.log(questions);
            const validation = validateQuestions(questions);
            if (validation !== true) {
                setFileUploadValidationMessage(validation);
                return;
            } else {
                setFileUploadValidationMessage("Format Validation Success");
            }
            setQuestions(questions);
            setFile(file);

            // validateQuestions(questions);


            // const url = 'https://dizzi.link/api/uploadCsvFile'; 
            // const data = new FormData(); 
            // data.append('deleteCode', '1234'); 
            // data.append('file', file);
            // const options = { 
            //     method: 'POST', 
            //     headers: { 
            //         'x-api-key': '89bc723f-0e24-4feb-8981-f96960ce807d', 
            //     },  
            //     body: data,
            // };

            // try{
            //   const response = await fetch(url, options);
            //   const data = await response.json();
            // //   modifyCompanyAttributes('logo',data.url);
            //   setFileUploaded(true);
            //   handleQuestionsUploaded(data.url)
            //   setQuestionCsv(data.url)
            //   console.log(data.url);
            // }catch(error){
            //   console.log("Error uploading file: ", error);
            // }
        } else {
            console.log("No File Selected");
        }
    }
    return (
        <>
            <div className="wrapper p-2 border-1 rounded-2 lighterBg yCenter gap-2 justify-content-between mb-1">
                <div className="inputWrapper w-100">
                    {
                        (fileUploaded) ?
                            <>
                                <input value={questionCsv} type="text" name="questionCsv" id="questionCsv" className="form-control disabled bg-transparent border-0" readOnly disabled />
                            </>
                            :
                            <>
                                {
                                    (fileUploadValidationMessage) ? <p className='text-danger mb-1'>{fileUploadValidationMessage}</p> : <></>
                                }
                                <input onChange={(e) => { handleFileUpload(e) }} type="file" name="questionCsv" id="questionCsv" className={fileUploadView} />
                            </>
                    }
                </div>
            </div>
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Questions Preview</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">

                            <div className="container-fluid">
                                <div className="details">
                                    <h4>Total Questions : {questions.length}</h4>
                                </div>
                                {
                                    questions.map((question, index) => {
                                        const timeConverted = secondsToMinutes(question.time);
                                        return (
                                            <div className="row  border border-1 py-3 mb-3">
                                                <div className="col-12 d-flex justify-content-between align-items-center">
                                                    <p className='queNumber mb-1 fs-6'>Question {question._id}</p>
                                                    <div className="queInfo mb-1 d-flex justify-content-center gap-2 align-items-center fs-6">
                                                        <p className='queMarks m-0 fs-6'>{question.marks} Point</p>
                                                        <div className='queTime'>
                                                            <div className='card flex-row gap-1 p-2 fw-bold timeTicker'>
                                                                <span id='tickerMinutes' className="text-dark">{timeConverted.min}</span>
                                                                <span className="fw-bold mx-1 divider text-muted">:</span>
                                                                <span id='tickerSeconds' className="text-dark">{timeConverted.sec}</span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <h4 className='question fs-6'>{question.question}</h4>
                                                    <div className="options mt-1 gap-1">
                                                        {
                                                            allOptions.map((optionName, index) => {
                                                                if (question[optionName] !== "") {
                                                                    return (
                                                                        <div key={"option" + index + question._id} className={`col-12 option fs-6 py-1 ${question.correct === optionName ? "correct" : ""}`}>
                                                                            <span className="optionNumber">{optionName}. </span>
                                                                            <p className="m-0 optionValue">{question[optionName]}</p>
                                                                        </div>
                                                                    )
                                                                } else {
                                                                    return <></>
                                                                }
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button onClick={doUpload} type="button"  data-bs-dismiss="modal" className="btn btn-primary">Upload</button>
                        </div>
                    </div>
                </div>
            </div>
            {
                questions.length>0 && !fileUploaded ? <button type="button" className="btn btn-primary float-end mt-2" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Preview And Continue</button> : <></>
            }
            
        </>
    )
}

export default UploadFile