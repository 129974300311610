import React from 'react'
import SignupForm from '../components/signupForm/SignupForm'
import AuthInformation from '../components/authInformation/AuthInformation'

function Signup() {
  return (
    <div className='container-fluid'>
      <div className="row">
        <div className="col-6">
          <div className="sticky-top px-md-3">
              <div className="p-2 py-3" style={{width: "140px"}}>
                <img src="https://s.dizzi.link/img/logo.png" alt="logo" className='w-100 h-100' />
              </div>
          </div>
        </div>
        <div className="col-6 lightestBg"></div>
      </div>
      <div className="row">
        <div className="col-md-6 gx-0"><SignupForm /></div>
        <div className="col-md-6 gx-0 lightestBg pb-4"><AuthInformation /></div>
      </div>
    </div>
  )
}

export default Signup
