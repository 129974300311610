import { useEffect, useState } from "react";
import Banner from "../components/banners/banner/Banner"
import Features from "../components/features/Features"
import Hero from "../components/hero/Hero"
import Services from "../components/services/Services"
import { fetchPage, getLinkHistory } from "../functions";
import Faq from "../components/faq/Faq";
import RouteHistory from "../components/routeHistory/RouteHistory";
import AlertCta from "../components/popups/AlertCta";

function Home() {

  const [refreshHistory,setRefreshHistory] = useState(0);

  const handleHistory = ()=>{
    setRefreshHistory(refreshHistory+1);
  }

  const [isAlertVisible,setIsAlertVisible] =  useState(false);
  const handleAlertVisible = ()=>{
    const temp = !isAlertVisible;
    setIsAlertVisible(temp);
  }
  const data = {
    logo : "https://s.dizzi.link/img/logo.png",
    content : `<p class="m-0 fw-bold">Claim Free Custom Url 🥳🎉 Write You Brand Name Against Your Link & Impress Your Audience 🕛 Three Days Left</p>`,
    cta : {
      link :"/auth/signup",
      text : "Get Offer",
    }
  }
  
  // const data = 
  // {
  //   hero :{
  //     top: "Let's make simply with one click.👈",
  //     para: "On a single platform, you'll find all the tools you need to connect audiences worldwide, manage links and QR Codes, and create brand relationships.",
  //     shortnerText: "Just fill and short your link 🚀"
  //   },
  //   services : {
  //     title: "Our <span class='titleFont'>Services</span> Area",
  //     subTitle: "What We Do",
  //     serviceCards: [
  //       {
  //         title: "Branding",
  //         text: "Nor again is there anyone who loves or pursues or desires to obtain pain of itself because it is pain",
  //         linkText: "Explore More",
  //       },
  //       {
  //         title: "Branding",
  //         text: "Nor again is there anyone who loves or pursues or desires to obtain pain of itself because it is pain",
  //         linkText: "Explore More",
  //       },
  //       {
  //         title: "Branding",
  //         text: "Nor again is there anyone who loves or pursues or desires to obtain pain of itself because it is pain",
  //         linkText: "Explore More",
  //       },
  //       {
  //         title: "Branding",
  //         text: "Nor again is there anyone who loves or pursues or desires to obtain pain of itself because it is pain",
  //         linkText: "Explore More",
  //       },
  //       {
  //         title: "Branding",
  //         text: "Nor again is there anyone who loves or pursues or desires to obtain pain of itself because it is pain",
  //         linkText: "Explore More",
  //       },
  //       {
  //         title: "Branding",
  //         text: "Nor again is there anyone who loves or pursues or desires to obtain pain of itself because it is pain",
  //         linkText: "Explore More",
  //       },
  //     ]
  //   },
  //   banner : {
  //     heading: "It's easy to get started",
  //     subHeading: "And it's free — two things everyone loves.",
  //     leftVector: "img/vector.webp",
  //     rightVector: "img/vector1Png.png",
  //     btnText: "GET STARTED",
  //   },
  //   features: {
  //     heading: "The Shortie Connections Platform",
  //     subHeading: "All the prochrts you need to build tyand connections. manage links and OR Codes. and connect with audiences evetywnere. in a sinøe unified platform.",
  //     cards: [
  //       {
  //         cardHeading: "Link Management",
  //         cardVector: "https://docrdsfx76ssb.cloudfront.net/static/1691530168/pages/wp-content/uploads/2022/06/link-mngt-3.svg",
  //         para: "A comprehensive solution to help make every point of connection between your content and your audience more powerful.",
  //         subHeading: "Popular Link Management Features",
  //         features: [
  //           {
  //               text: "URL shortening at scale",
  //           },
  //           {
  //               text: "Custom links with your brand",
  //           },
  //           {
  //               text: "URL redirects",
  //           },
  //           {
  //               text: "Advanced analytics & tracking",
  //           },
  //         ]
  //       },
  //       {
  //         cardHeading: "Link Management",
  //         cardVector: "https://docrdsfx76ssb.cloudfront.net/static/1691530168/pages/wp-content/uploads/2022/06/qr-code.svg",
  //         para: "A comprehensive solution to help make every point of connection between your content and your audience more powerful.",
  //         subHeading: "Popular Link Management Features",
  //         features: [
  //           {
  //               text: "URL shortening at scale",
  //           },
  //           {
  //               text: "Custom links with your brand",
  //           },
  //           {
  //               text: "URL redirects",
  //           },
  //           {
  //               text: "Advanced analytics & tracking",
  //           },
  //         ]
  //       },
  //       {
  //         cardHeading: "Link Management",
  //         cardVector: "https://docrdsfx76ssb.cloudfront.net/static/1691530168/pages/wp-content/uploads/2022/06/link-in-bio-1.svg",
  //         para: "A comprehensive solution to help make every point of connection between your content and your audience more powerful.",
  //         subHeading: "Popular Link Management Features",
  //         features: [
  //           {
  //               text: "URL shortening at scale",
  //           },
  //           {
  //               text: "Custom links with your brand",
  //           },
  //           {
  //               text: "URL redirects",
  //           },
  //           {
  //               text: "Advanced analytics & tracking",
  //           },
  //         ]
  //       },
  //     ]
  //   }
  // };
  const tempData = {
    features: {
      heading: null,
      subHeading: null,
      cards: [
        null,null,null
      ]
    },
    hero :{
      top: "Let's make simply with one click ",
      para: "On a single platform, you'll find all the tools you need to connect audiences worldwide, manage links and QR Codes, and create brand relationships.",
      shortnerText: "Just fill and short your link 🚀"
    },
    services : {
      title: null,
      subTitle: null,
      serviceCards: [
        null,null,null
      ]
    },
    banner : null
  }
  const [homeData,setHomeData] = useState(tempData);
  useEffect(() => {
    const getData =  async ()=>{
      const data = await fetchPage('urlShortnerHome');
      setHomeData(data);
      const history = await getLinkHistory();
      setIsAlertVisible(history.length>0)
    }
    getData()
    
  }, [])
  
  return (
    <>
      <Hero data={homeData.hero} handleHistory={handleHistory}/>
      <AlertCta data={data} isVisible={isAlertVisible} handleVisible={handleAlertVisible}  className="my-3"/>
      <RouteHistory refreshHistory={refreshHistory}/>
      <Services data={homeData.services}/>
      <Features data={homeData.features}/>
      <Banner data={homeData.banner}/>
      <Faq />
    </>
  )
}

export default Home