import "./QuestionShimmer.css"
function QuestionShimmer() {
    return (
        <div className="shimmer">
            <div className="shimmerWrapper">
                <div className="stroke animate questionShimmer"></div>
                <div className="stroke animate descriptionShimmer"></div>
                <div className="options">
                    <div className="col-12 option">
                        <div className="stroke animate optionShimmer"></div>
                    </div>
                    <div className="col-12 option">
                        <div className="stroke animate optionShimmer"></div>
                    </div>
                    <div className="col-12 option">
                        <div className="stroke animate optionShimmer"></div>
                    </div>
                    <div className="col-12 option">
                        <div className="stroke animate optionShimmer"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuestionShimmer