import React from 'react'
import "./Footer.css"
// import { faInfinity, faStar } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { FcBrokenLink } from 'react-icons/fc';

function Footer() {
    const data = {
        logo: "https://s.dizzi.link/img/footerLogo.png",
        // icon: "https://demo-egenslab.b-cdn.net/html/techgen/preview/assets/images/icons/title-vector.svg",
        text: "We are engineers of your traffic ! Making Your Life Easier With Dizzi",
        sections: [
            {
                title: "Our Services",
                s1: "Website Design",
                s2: "Content Writing",
                s3: "SEO Marketing",
                s4: "Video Editing",
                s5: "Copy Writing"
            },
            {
                title: "Office Address",
                // mail1: "learninssessions040@gmail.com",
                mail2: "support@dizzibooster.com",
                address: "Ferozepur Rd, opp. Hotel Nagpal Regency, above Goyal Hyundai, Gurdev Nagar, Ludhiana, Punjab 141003",
                btnText: "Call Now",
            },
            // {
            //     title: "More Info",
            //     l1: "Contact Us",
            //     l2: "About Us",
            //     l3: "Our Team",
            //     l4: "Terms & Conditions"
            // }
        ],
        slideTitle: "Achievments:",
        slides: [
            {
                img: "https://demo-egenslab.b-cdn.net/html/techgen/preview/assets/images/company/foot-award3.png",
                title: "Best Quality Service"
            },
            {
                img: "https://demo-egenslab.b-cdn.net/html/techgen/preview/assets/images/company/foot-award4.png",
                title: "Galaxy Award-18"
            },
            {
                img: "https://demo-egenslab.b-cdn.net/html/techgen/preview/assets/images/company/foot-award2.png",
                title: "International Award",
            },
            {
                img: "https://demo-egenslab.b-cdn.net/html/techgen/preview/assets/images/company/foot-award1.png",
                title: "Google Partner 2021"
            }
        ],
        footerText: "©Copyright 2023 | Powered by <span class='titleFont'>Learning Sessions</span>",
    }
    return (
        <div id='Contact' className='container-fluid footer text-white'>
            <div className="row p-4 py-5">
                <div className="col-lg-4 py-3 pb-5 allCenter">
                    <div className="company allCenter gap-3 p-4 border border-secondary rounded-circle">
                        <div className="logo"><img src={data.logo} alt="logo" className='w-75' /></div>
                        <div className="style yCenter gap-3">
                            <span className="line"></span>
                            <FcBrokenLink className='fs-4' />
                            <span className='line'></span>
                        </div>
                        <p className='fw-semibold'>{data.text}</p>
                        <div className='icons yCenter gap-2 secondColor'>
                            {/* <span className='allCenter rounded-circle p-1'><FontAwesomeIcon icon={faStar} /></span> */}
                            {/* <span className='allCenter rounded-circle p-1'><FontAwesomeIcon icon={faStar} /></span> */}
                            {/* <span className='allCenter rounded-circle p-1'><FontAwesomeIcon icon={faStar} /></span> */}
                            {/* <span className='allCenter rounded-circle p-1'><FontAwesomeIcon icon={faStar} /></span> */}
                        </div>
                    </div>
                </div>
                <div className="col-lg-8 xCenter">
                    <div className="row">
                        <div className="col-sm-6 gx-0 py-3 py-sm-2 px-2 ps-3 text-center text-sm-start gap-3">
                            <h2 className='h3 mb-2 fw-bold'>{data.sections[0].title}</h2>
                            <p className='m-0 mb-2'>{data.sections[0].s1}</p>
                            <p className='m-0 mb-2'>{data.sections[0].s2}</p>
                            <p className='m-0 mb-2'>{data.sections[0].s3}</p>
                            <p className='m-0 mb-2'>{data.sections[0].s4}</p>
                        </div>
                        <div className="col-sm-6 gx-0 py-3 py-sm-2 px-2 ps-3 text-center text-sm-start gap-4">
                            <h2 className='h3 mb-1 fw-bold'>{data.sections[1].title}</h2>
                            <div>
                                <div className="icons"></div>
                                <div className="link">
                                    <p className='m-0 mb-2'>{data.sections[1].mail1}</p>
                                    <p className='m-0 mb-2'>{data.sections[1].mail2}</p>
                                </div>
                            </div>
                            <div>
                                <div className="icons"></div>
                                <p className='m-0 address'>{data.sections[1].address}</p>
                            </div>
                                <a href="tel:+91 76467 00028" className='m-0 text-decoration-none text-white'>
                                    <button className='border py-2 px-3 mt-3 h5 transition btn btn-primary rounded-0 border-0'>
                                        {data.sections[1].btnText}
                                    </button>
                                </a>
                            </div>
                        {/* <div className="col-sm-6 col-lg-4 gx-0 py-3 py-sm-2 px-2 ps-3 text-center text-sm-start gap-3">
                            <h2 className='h3 mb-2 fw-bold'>{data.sections[2].title}</h2>
                            <p className='m-0 mb-2'>{data.sections[2].l1}</p>
                            <p className='m-0 mb-2'>{data.sections[2].l2}</p>
                            <p className='m-0 mb-2'>{data.sections[2].l3}</p>
                            <p className='m-0 mb-2'>{data.sections[2].l4}</p>
                        </div> */}
                    </div>
                    <div className="row yCenter pt-4">
                        <div className="col-xl-3 text-center fs-4 m-0 fw-bold"><p>{data.slideTitle}</p></div>
                        <div className="col-xl-9 pt-3 pt-lg-0 yCenter justify-content-sm-evenly justify-content-lg-start gap-5">
                            <Splide options={{
                                perPage: 4,
                                perMove: 1,
                                // focus: "center",
                                // gap: 1,
                                lazyLoad: true,
                                keyboard: true,
                                direction: "ltr",
                                speed: 500,
                                type: "loop",
                                drag: true,
                                snap: true,
                                autoplay: true,
                                interval: 1500,
                                arrows: false,
                                pagination: false,
                                breakpoints: {
                                    640: {
                                        perPage: 3,
                                    },
                                    360: {
                                        perPage: 1,
                                    },
                                }
                            }} aria-label='Achievments'>
                                {data.slides.map((slide,index) => {
                                    return (
                                        <SplideSlide key={"slide"+index} >
                                            <div className="slide allCenter w-100">
                                                <div className="imgHolder"><img src={slide.img} alt="achievment" className='w-100 h-100' /></div>
                                                {/* Title needed to remove comment when image has not title  */}
                                                {/* <p className='m-0 title'>{data.slides[1].title}</p> */}
                                            </div>
                                        </SplideSlide>
                                    )
                                })}
                            </Splide>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row px-5 allCenter">
                <div className="col-12 p-4 border-top border-secondary">
                    <p className='m-0 fw-light text-white' dangerouslySetInnerHTML={{ __html: data.footerText }} />
                </div>
            </div>
        </div>
    )
}

export default Footer