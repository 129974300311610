import React, { useState } from 'react'
import "./Navbar.css"
import { AiOutlineClose } from 'react-icons/ai'
import { Link } from 'react-router-dom';
import { useAuth } from '../../pages/AuthContext';

function Navbar({isAuthenticated}) {

    const { logout } = useAuth();

    const data = {
        logo: "https://s.dizzi.link/img/logo.png",
        logoAddress: "",
        navLinks: [
            {
                linkText: "Short URL",
                linkAddress: "#Hero",
            },
            {
                linkText: "Features",
                linkAddress: "#Features",
            },
            {
                linkText: "Services",
                linkAddress: "#Services",
            },
            {
                linkText: "FAQs",
                linkAddress: "#FAQs",
            },
            {
                linkText: "Contact",
                linkAddress: "#Contact",
            },
        ],
        mailIcon: "",
        mailText: "Email Now",
        mailAddress: "lsdm@gmail.com",
        callText: "Call Now",
        callAddress: "+91 369-9999999"
    }

    function toggle() {
        var elem = document.getElementsByClassName("navCollapse")[0];
        elem.classList.toggle("notVisible")
    }

    // Navbar Effect 
    const [color, setColor] = useState(false);
    const changeColor = () => {
        if(!isAuthenticated) {
            setColor(!!window.scrollY >= 80);
        }
    }
    window.addEventListener("scroll", changeColor);

    // const dr = document.querySelectorAll(".dropdown-menu");
    // dr.forEach((d) => {
    //     console.log(d.offsetPosition)
    // })

    // function toggleDropdown() {
    //     let menus = document.getElementsByClassName("dropdownMenu")[0];
    //     menus.classList.toggle("invisible");
    // }

    return (

        <nav className={`navbar navbar-expand-lg sticky-top coloredNav border-bottom ${color ? "scrollNav" : ""}`}>
            <div className="container py-md-2">
                <img src={data.logo} alt="logo" width={"150px"}     className='navbar-brand' />
                <button className="navbar-toggler " type="button" 
                // data-bs-toggle="collapse" 
                // data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" 
                // aria-expanded="false" aria-label="Toggle navigation"
                // commented to disable default bootstrap navlinks workings
                    onClick={toggle}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    {
                        isAuthenticated ? <></> : (
                            <ul className="navbar-nav m-auto gap-3">
                                {data.navLinks.map((navLink,index) => {
                                    return (
                                        <li key={"nav"+index} className="nav-item px-2 rounded-2">
                                            <a href={navLink.linkAddress} className='nav-link yCenter text-black' key={"navLink"+index}>
                                                {navLink.linkText}
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                        )
                    }
                    {
                        isAuthenticated ? 
                            <div className="contact pt-3 xCenter gap-3 ms-auto">
                                <div className="nav-item dropdown">
                                    <div className="nav-link dropdown-toggle d-flex align-items-center gap-1" id="navbarDropdown"  role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <div className="userImg" style={{height:"30px",width:"30px"}}>
                                            <img src="https://dizzi.link/assets/icons/user.png" className="h-100 w-100 rounded-circle" alt="userProfile" />
                                        </div>
                                    </div>
                                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                                        <li>
                                            <Link to={"/profile"} className="dropdown-item">
                                                Profile
                                            </Link>
                                        </li>
                                        <li><hr className="dropdown-divider" /></li>
                                        <li>
                                            <div className="dropdown-item" onClick={logout}>
                                                Logout
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div> 
                            : (
                            <div className="yCenter gap-2">
                                <Link className='btn border border-primary' to="/auth" >
                                    Login
                                </Link>
                                <Link className='btn border border-primary btn-primary' to="/auth/signup" >
                                    Signup
                                </Link>
                            </div>
                        )
                    }
                </div>
                <div className="navCollapse scrollBarHidden p-4 position-fixed start-0 transition notVisible">
                    <div className="logo yCenter justify-content-between" style={{zIndex: "9999 !important"}}>
                        <img src={data.logo} alt="logo" width={"150px"} className='navbar-brand' />
                        <AiOutlineClose className='fs-3 fw-light navCross cursorPointer' onClick={toggle} />
                    </div>
                    {
                        (isAuthenticated) ? <></> : (
                            <div className="linkSection xCenter py-4">
                                <ul className="navbar-nav mb-2 mb-lg-0 gap-3 gap-lg-4 d-flex flex-column">
                                    {data.navLinks.map((navLink,index) => {
                                        return (
                                            <li key={"mobNav"+index} className="nav-item dropdown">
                                                <Link className='nav-link yCenter text-black' key={"mobNavLink"+index} to="/auth" >
                                                    {navLink.linkText}
                                                </Link>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        )
                    }
                    {
                        (isAuthenticated) ? 
                            <div className="contact pt-3 xCenter gap-3 ms-auto">
                                <div className="yCenter gap-2">
                                    <div className='btn border border-primary' onClick={logout} >
                                        Logout
                                    </div>
                                </div>
                            </div>
                        : (
                            <div className="contact pt-3 xCenter gap-3">
                                <div className="yCenter gap-2">
                                    <Link className='btn border border-primary' to="/auth" >
                                        Login
                                    </Link>
                                    <Link className='btn border border-primary btn-primary' to="/auth" >
                                        Signup
                                    </Link>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </nav>
    )
}

export default Navbar
