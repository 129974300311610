import React, { useState } from 'react'
import { Link, Navigate } from 'react-router-dom'
import "./SignupForm.css"
import { Field, Form, Formik } from 'formik'
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google'
import { googleAuth, registerUser } from '../../functions'

function SignupForm() {

  const [status,setStatus] = useState(false);
  const [passwordReady,setPasswordReady] = useState(false);
  const [displayMessage,setDisplayMessage] = useState();

  const data = {
    title: "Sign up for free",
    subTitle: "Already have an account?",
    checkBoxTitle: "I want to receive Infobio news, trends, offers, or blogs by email.",
    orTitle: "OR CONTINUE WITH",
  }
  const checkInputs = ()=>{
    const name = document.getElementById('name');
    const email = document.getElementById('email');
    const mobile = document.getElementById('mobile');
    const password = document.getElementById('password');
    if(email.value ==="" || mobile.value === "" || name.value === ""){
        setDisplayMessage("All Fields Are Mandatory")
    }else{
        setDisplayMessage();
        name.parentElement.classList.add('d-none')
        email.parentElement.classList.add('d-none')
        mobile.parentElement.classList.add('d-none')
        password.parentElement.classList.remove('d-none');
        setPasswordReady(true)
    }
  }
  const validatePassword = ()=>{
    const password = document.getElementById('password');
    const uppercaseLetterRegex = new RegExp(/[A-Z]/);
    const lowercaseLetterRegex = new RegExp(/[a-z]/);
    const specialCharacterRegex = new RegExp(/[!@#$%^&*()_+{}\[\]:;<>,.?~\\|\s-]/);
    const numericDigitRegex = new RegExp(/\d/);
    switch (true) {
      case password.value === "":
        setDisplayMessage("Choose a Password");
        return false;
      case !uppercaseLetterRegex.test(password.value):
        setDisplayMessage("Include Atleast One Uppercase Letter");
        return false;
      case !lowercaseLetterRegex.test(password.value):
        setDisplayMessage("Include Atleast One Lower Case Letter");
        return false;
      case !specialCharacterRegex.test(password.value):
        setDisplayMessage("Include Atleast One Special Character");
        return false;
      case !numericDigitRegex.test(password.value):
        setDisplayMessage("Include Atleast One Numeric Digit");
        return false;
      default:
        if(password.value.length < 8){
          setDisplayMessage("Minimum Length Requierd is 8 Characters");
          return false;
        }
        setDisplayMessage("Congratulations! You have Choosen Strong Password");
        return true;
    }
  }
  if(status){
    return <Navigate to={"/dashboard"}/>
  }else{
    return (
    <GoogleOAuthProvider clientId="1069036692510-72otes5tp0pdk9jcd8cfsifdlq3smdmo.apps.googleusercontent.com">
        <div className='signupForm px-md-5 py-md-2'>
            <div className='py-4 py-md-3 px-3 px-md-5 xCenter gap-3 gap-md-4'>
                <div className="xCenter gap-3">
                    <h3 className="m-0">{data.title}</h3>
                    <p className="m-0 textSecondary">{data.subTitle} <Link to="/auth" className='fw500'>Login Here</Link></p>
                </div>
                {
                    (displayMessage) ? <h5 className="displayMessage text-danger">{displayMessage}</h5> : <></>
                }
                <div className="fields xCenter gap-3 gap-md-4" style={{color: "#545452"}}>
                    <Formik
                        initialValues={{
                            name: '',
                            email: '',
                            mobile: '',
                            password: '',
                        }}
                        onSubmit={ async(values) => {
                              const isSucceed = await registerUser(values);
                              if(isSucceed){
                                  setStatus(true);
                              }else{
                                  setDisplayMessage("Invalid Credentials / User Not Found");
                            }
                        }}
                        >
                            <Form>
                                <div className="field xCenter gap-1 mb-2">
                                    <label htmlFor="email">Name</label>
                                    <Field 
                                        id="name" 
                                        name="name" 
                                        type="text" 
                                        className="p-2 rounded-0" 
                                        placeholder="Enter Your Name"
                                        required
                                    />
                                </div>
                                <div className="field xCenter gap-1 mb-2">
                                    <label htmlFor="email">Email</label>
                                    <Field 
                                        id="email" 
                                        name="email" 
                                        type="email" 
                                        className="p-2 rounded-0" 
                                        placeholder="Enter Your E-Mail"
                                        required
                                    />
                                </div>
                                <div className="field xCenter gap-1 mb-2">
                                    <label htmlFor="mobile">Mobile</label>
                                    <Field 
                                        id="mobile" 
                                        name="mobile" 
                                        type="text" 
                                        className="p-2 rounded-0" 
                                        placeholder="Enter Your Mobile" 
                                        required
                                        maxLength="10"
                                        minLength="10"
                                    />
                                </div>
                                <div className="field xCenter gap-1 mb-2 d-none">
                                    <label htmlFor="password">Password</label>
                                    <Field 
                                        id="password" 
                                        name="password" 
                                        type="password" 
                                        className="p-2 rounded-0" 
                                        placeholder="Enter Your password" 
                                        required
                                        minLength="8"
                                        onInput={validatePassword}
                                    />
                                </div>
                                {
                                    (passwordReady) ?
                                      <button className='btn btn-success rounded-0 w-100 mt-3' type="submit">Proceed Securely</button>
                                    :
                                      <button onClick={checkInputs} className='btn btn-primary rounded-0 w-100 mt-3' type="button">Continue</button>
                                }
                            </Form>
                    </Formik>
                </div>

                <p className="m-0">By continue, you confirm that you've read and accepted our <Link to="">Terms and Conditions</Link> and our <Link to="">Privacy Notice</Link>.</p>

                <div className="line my-1 yCenter gap-3">
                    <hr className="m-0 w-50 border-1" />
                    <span className="textSecondary text-uppercase inlineText" style={{fontSize: "0.857rem", fontWeight: "600"}}>{data.orTitle}</span>
                    <hr className="m-0 w-50 border-1" />
                </div>
                
                <GoogleLogin
                    onSuccess={async (credentialResponse) => {
                        const isSucceed = await googleAuth(credentialResponse.credential);
                        if(isSucceed){
                            setStatus(true);
                        }
                    }}
                    onError={() => {
                        setDisplayMessage("Opps! Something Went Wromg Try Again")
                    }}
                    useOneTap
                />
            </div>
        </div>
    </GoogleOAuthProvider>
    )
  }
}

export default SignupForm
