import React, { useEffect } from 'react'
import { Link, Outlet } from 'react-router-dom'
// import LatestLinks from '../latestLinks/LatestLinks'

function LinkTabs() {

    const data = {
        tabs: [
            {
                name: "Latest Links",
                count: "",
                path: "/latest",
            },
            {
                name: "Main URLs",
                count: "5",
                path: "/main",
            },
            {
                name: "Overview",
                count: "3",
                path: "/overview",
            },
        ],
    }

    useEffect(() => {
        const linkTabBtns = document.querySelectorAll(".linkTabBtn");
        linkTabBtns.forEach(linkTabBtn => {
            linkTabBtn.addEventListener("click", () => {
                const activeTabBtn = document.querySelector(".activeTab");
                if(activeTabBtn) {
                    activeTabBtn.classList.remove("activeTab")
                }
                linkTabBtn.classList.add("activeTab");
            })
        })
    }, [])

  return (
    <div className='linksTabs xCenter gap-4'>

        <div className="tabs yCenter gap-3 flex-wrap">
            {data.tabs.map((tab, index) => {
                return(
                    <Link key={"linkTab"+index} to={`/profile${tab.path}`} className={`px-2 py-1 rounded-1 text-decoration-none text-black yCenter gap-2 linkTabBtn ${tab.name === "Latest Links" ? "activeTab" : ""}`} style={{fontSize: "15px"}} >
                        <p className="m-0">{tab.name}</p>
                        {tab.count !== "" ? <p className="m-0 fw-light lighterText">{tab.count}</p> : ""}
                    </Link>
                )
            })}
        </div>

        <div className="tabResult">
            <Outlet />
        </div>

    </div>
  )
}

export default LinkTabs
