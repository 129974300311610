import React, { useEffect } from "react";
import { BiSolidCalendarHeart, BiSolidGraduation, BiUser } from "react-icons/bi";
import { HiOutlineMail } from "react-icons/hi"
import { GrWorkshop } from "react-icons/gr"
import { BsFillCalendarDateFill, BsFillTelephoneFill, BsGenderMale, BsPersonWorkspace, BsPlus } from "react-icons/bs"
import { AiFillDelete } from "react-icons/ai";
import { FaChessKing } from "react-icons/fa";
import PostShimmer from "../shimmer/PostShimmer";

function ModifyForm({formAttributes,modifyFormAttributes}) {
  const data = {
    title: "Choose Fields",
    subTitle: "Add fields that you want your candidates to fill when applying",
    defaultFields: [
      {
        name: "Full Name",
        key: "name",
        icon:"BiUser",
        type: "text",
      },
      {
        name: "Email",
        key: "email",
        icon:"BiUser",
        type: "text",
      },
      {
        name: "Mobile No.",
        key: "mobile",
        icon:"BiUser",
        type: "text",
      }
    ],
    additionalFields: [
      {
        name: "Message",
        key: "message",
        type: "textarea",
      },
      {
        name: "Gender",
        key: "gender",
        type: "option",
      },
      {
        name: "Age",
        key: "age",
        type: "number",
      },
      {
        name: "Date of Birth",
        key: "dob",
        type: "date",
      },
      {
        name: "Qualification",
        key: "qualification",
        type: "text",
      },
      {
        name: "Job Title",
        key: "jobTitle",
        type: "text",
      }
    ]
  };

  const handleAddInput = (key,checkbox)=>{
    const attributes = formAttributes[key];
    attributes.added = checkbox.checked;
    attributes.required = false;
    modifyFormAttributes(key,attributes);
  }
  const handleOptionalInput = (key,checkbox)=>{
    const attributes = formAttributes[key];
    if(attributes.added === true){
      checkbox.checked = checkbox.checked;
      attributes.required = checkbox.checked;
      modifyFormAttributes(key,attributes);
    }else{
      checkbox.checked = false;
    }
  }
  
    
  if(formAttributes){

    return (
      <div id="formFields" className="fields xCenter" >
  
        <div className="xCenter gap-1">
          <p className='fs-5 m-0'>{data.title}</p>
          <p className="m-0 lighterText">{data.subTitle}</p>
        </div>
        <hr />
        <div className="defaultFields lighterBg rounded-4 p-3 bgShadow border">
          <div className="sectionHead d-flex justify-content-between align-items-center">
            <p className="m-0 fw-semibold mb-2">Default Fields</p>
            <div className="leftTitle d-flex gap-4">
              <span>Added</span>
              <span>required</span>
            </div>
          </div>
          <div id="companyDetails" className="companyFields xCenter gap-2">
            {data.defaultFields.map((field, index) => {
              const currentKey = field.key;
              // console.log(formAttributes[currentKey]);
              return (
                <div key={`defaultField${index}`} className="formField yCenter justify-content-between border rounded-2 companyField bg-white">
                    <div className="yCenter gap-1">
                      <span className="iconWrapper allCenter p-2 text-primary">
                        {currentKey === "name" ? <BiUser /> : ""}
                        {currentKey === "email" ? <HiOutlineMail /> : ""}
                        {currentKey === "mobile" ? <BsFillTelephoneFill style={{fontSize: "14px"}} /> : ""}
                      </span>
                      <p className="m-0">{field.name}</p>
                    </div>
  
                    <div className="addInputWrapper d-flex align-items-center gap-5">
                      <div className="inputWrapper me-3">
                        <input onChange={(e)=>{handleAddInput(currentKey,e.target)}} defaultChecked={(formAttributes[currentKey].added === true) ? true : false} type="checkbox" name="addFieldBtn" className="addFieldBtn" />
                      </div>
                      <div className="inputWrapper me-3">
                        <input onClick={(e)=>{ handleOptionalInput(currentKey,e.target)}} defaultChecked={(formAttributes[currentKey].added === true && formAttributes[currentKey].required === true) ? true : false} type="checkbox" name="required" className="requiredBtn" />
                      </div>
                    </div>
                </div>
              );
            })}
          </div>
        </div>
  
        <hr />
  
        <div className="dltIconWrapper d-none">
          <AiFillDelete />
        </div>
  
        <div className="xCenter lighterBg rounded-4 p-3 bgShadow border">
          <div className="sectionHead d-flex justify-content-between align-items-center">
            <p className="m-0 fw-semibold my-2">Additional Fields</p>
            <div className="leftTitle d-flex gap-4">
              <span>Added</span>
              <span>required</span>
            </div>
          </div>
          <div id="formFieldsContainer" className="formFields xCenter gap-2">
  
            {
              data.additionalFields.map((field,index)=>{
                const currentKey = field.key;
                return (
                  <div className="formField yCenter gap-3 justify-content-between border bg-white rounded-2">
                    <div className="yCenter gap-1">
                      <span className="iconWrapper allCenter p-2 text-primary">
                        {field.key === "message" ? <HiOutlineMail /> : ""}
                        {field.key === "gender" ? <BsGenderMale /> : ""}
                        {field.key === "age" ? <BiSolidCalendarHeart style={{fontSize: "14px"}} /> : ""}
                        {field.key === "dob" ? <BsFillCalendarDateFill /> : ""}
                        {field.key === "qualification" ? <BiSolidGraduation /> : ""}
                        {field.key === "jobTitle" ? <BsPersonWorkspace /> : ""}
                      </span>
                      <div className="inputWrapper">
                        <p className="m-0">{field.name}</p>
                      </div>
                    </div>
                    <div className="yCenter gap-1">
                      <div className="addInputWrapper d-flex align-items-center gap-5">
                        <div className="inputWrapper me-3">
                          <input onChange={(e)=>{handleAddInput(currentKey,e.target)}} defaultChecked={(formAttributes[currentKey].added === true) ? true : false} type="checkbox" name="addFieldBtn" className="addFieldBtn" />
                        </div>
                        <div className="inputWrapper me-3">
                          <input onClick={(e)=>{ handleOptionalInput(currentKey,e.target)}} defaultChecked={(formAttributes[currentKey].added === true && formAttributes[currentKey].required === true) ? true : false} type="checkbox" name="required" className="requiredBtn" />
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
  
          </div>
        </div>
      </div>
    );
  }else{
    return <PostShimmer/>
  }
}

export default ModifyForm;
