import React from 'react'
import { Link } from 'react-router-dom'
import "./popups.css"

function AlertCta({data,isVisible,handleVisible,className}) {
  
  if(isVisible){
    return (
      <div id='alertCtaCover' className={"container-fluid "+className}>
          <div className="row">
              <div className="col-11 m-auto">
                  <div className="alertCta d-flex justify-content-between align-items-center gap-3 gap-lg-0 flex-wrap flex-lg-nowrap">
                    <div className="logo">
                      <img className='h-100 w-100' src={data.logo} alt="comapny logo" />
                    </div>
                    <div className="content mx-3" dangerouslySetInnerHTML={{__html:data.content}} />
                    <div className="ctaLink ms-auto">
                      <Link to={data.cta.link} className="btn btn-primary">
                        {data.cta.text}
                      </Link>
                      <div onClick={handleVisible} className="ms-3 btn-close"></div>
                    </div>
                  </div>
              </div>
          </div>
      </div>
    )
  }
}

export default AlertCta