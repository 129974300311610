import React from "react";
import { Chart } from "react-google-charts";

const options = {
  region: [], // Africa
  colorAxis: { colors: ["#77adff", "#2063c9", "#0041a3"] },
  backgroundColor: "#fff",
  datalessRegionColor: "#c2daff",
  defaultColor: "#f5f5f5",
};

function MapChart({chartData}) {
  return (
    <Chart
      chartType="GeoChart"
      // width="100%"
      // height="100%"
      data={chartData}
      options={options}
    />
  );
}

export default MapChart