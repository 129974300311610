import React from 'react'
import { FiLink2 } from "react-icons/fi";
import { IoIosCopy } from 'react-icons/io';
import "./Result.css"

function Result() {
    const data = {
        qrHeading: "QR CODE",
        downloadBtnText: "Download QR",
        qrImg: "https://s.dizzi.link/img/dizzibooster.png",
        linkAlertText: "Your Shorten Link ",
        heading2: "CUSTOM YOUR LINK",
        copyBtnText: "Copy Link",
        defaultPreview: "https://s.dizzi.link/",
        defaultPreviewText: "dizzi.link/xxxxx",
        defaultLink: "https://dizzibooster.com/",
        copiedAlertText: "Copied to Clipboard!",
        qrSavedAlertText: "QR Code Downloaded!",
    }

    const saveQR = () => {
        const canvas = document.getElementById("QR");
        if (canvas) {
            const pngUrl = canvas
                .toDataURL("image/png")
                .replace("image/png", "image/octet-stream");
            downloadQR(pngUrl)
        } else {
            downloadQR(data.qrImg)
        }
    };

    const downloadQR = (qrUrl) => {
        let downloadLink = document.createElement("a");
        downloadLink.href = qrUrl;
        downloadLink.download = `dizzi_qr.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        showHideAlert(document.querySelector(".qrSavedAlert"));
    }

    // copy link  
    function copyLink() {
        const link = document.querySelector(".shortedLink").getAttribute("href");
        const copiedText = document.createElement("textarea")
        copiedText.value = link;
        document.body.appendChild(copiedText);

        copiedText.select();
        document.execCommand('copy');
        document.body.removeChild(copiedText);
        showHideAlert(document.querySelector(".copyAlert"))
    }

    function showHideAlert(element) {
        element.classList.remove("d-none");
        element.classList.add("showAnimation");
        setTimeout(() => {
            element.classList.remove("showAnimation");
            element.classList.add("exitAnimation");
            setTimeout(() => {
                element.classList.add("d-none");
                element.classList.remove("exitAnimation");
            }, 350)
        }, 1500);
    }

    return (
        <div>
            <div className="wrapper">
                <div className="qrWithDate xCenter gap-3">
                    <div className='p-3 border rounded-3 xCenter gap-3'>
                        <div className="heading yCenter justify-content-between position-relative">
                            <p className='m-0'>{data.qrHeading}</p>
                            <button className='btn btn-primary allCenter fw-medium fsSm saveQrBtn hoverEffect' onClick={saveQR}>{data.downloadBtnText}</button>
                            <span className="alertBox qrSavedAlert position-absolute bg-white rounded-3 text-success d-none" style={{ width: "160px", right: "-8%", bottom: "-90%", fontSize: "14px", fontWeight: "600",}}>
                                <p className='m-0'>{data.qrSavedAlertText}</p>
                            </span>
                        </div>
                        <div className='yCenter justify-content-center justify-content-sm-between justify-content-md-center justify-content-lg-between flex-wrap flex-sm-nowrap flex-md-wrap flex-lg-nowrap gap-3 gap-md-3'>
                            <div id='qrWrapper' className="imgWrapper" style={{ width: "120px" }}>
                                <img src={data.qrImg} alt="QR Code" className='w-100 h-100 img-fluid qrCode' />
                            </div>
                            <div className="linkDetails w-100 allCenter">
                                <div className="date yCenter justify-content-center gap-1">
                                    <p className='m-0 urlInfo'>{data.linkAlertText}</p>
                                </div>
                                <div className="link yCenter justify-content-center gap-1">
                                    <span className='text-primary allCenter h5 m-0 me-1'><FiLink2 /></span>
                                    <a className='m-0 h5 shortedLink cursorPointer text-decoration-none' href={data.defaultPreview} rel="noreferrer" target="_blank">
                                        {data.defaultPreviewText}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bottom xCenter gap-2 p-3 border rounded-3 position-relative">
                        <div className="copyIconWrapper position-absolute" style={{ top: "-15%", right: "5%" }}>
                            <span className="copyIcon hoverEffect btn btn-primary text-white allCenter rounded-circle fs-5 copyLinkBtn" style={{ width: "55px", height: "55px" }} onClick={copyLink}><IoIosCopy /></span>
                            <span className="copyAlert alertBox1 position-absolute bg-white rounded-3 text-success text-center d-none" style={{ width: "155px", bottom: "-50%", left: "-102%", fontSize: "14px", fontWeight: "600",}}>
                                <p className='m-0'>{data.copiedAlertText}</p>
                            </span>
                        </div>
                        <div className="heading yCenter justify-content-between">
                            <p className='m-0'>{data.heading2}</p>
                            {/* <button className='btn btn-primary allCenter fw-medium'>{data.copyBtnText}</button> */}
                        </div>
                        <div className="iframeWrapper p-3 lighterBg rounded-3" style={{ height: "200px" }}>
                            <iframe title='preview' src={data.defaultPreview} frameBorder="1" className='w-100 h-100 scrollBarHidden' id="urlReview"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Result