import React, { useEffect, useState } from 'react'
import Shortner from '../../shortner/Shortner'
import { AiTwotoneEdit } from 'react-icons/ai'
import { Link, useOutletContext } from 'react-router-dom'
import CustomUrlInput from '../../customUrlInput/CustomUrlInput'
import "./EditFormBtn.css"
import Head from '../../head/Head'
import { getHead } from '../../../functions'

function PromptWithCustom() {
    const handleHistory = useOutletContext();

    const data = {
        title: "Restrict visits with Prompt URLs",

    }
    
    const [heroProtectedData, setHeroProtectedData] = useState();
  
    useEffect(() => {
        const getHeadData = async () => {
        const headData = await getHead("heroProtected");
        setHeroProtectedData(headData);
        }

        getHeadData();
    }, [])

    const [customRoute,setCustomRoute] = useState();
    const [isGenerated,setIsGenerated] = useState(false);
    const [route,setRoute] = useState(""); 

    const handleCustomRoute = (route)=>{
        setCustomRoute(route)
    }

    const handleGenerated = (route,bool)=>{
        setRoute(route);
        setIsGenerated(bool)
    }


  return (
    <div className="xCenter gap-3 gap-md-5 promptWithCustomUrl">
        <Head data={heroProtectedData} />        
        <div className="pe-0 pe-lg-5">
            <div className="heading pe-0 pe-lg-5 me-0 me-lg-5">
                <h1 className='m-0 extraLargeFont fw-semibold lh-sm'>{data.title}</h1>
            </div>
        </div>
        <div className='xCenter gap-3'>
            <CustomUrlInput isGenerated={isGenerated} handleCustomRoute={handleCustomRoute}  />
            <div className="shortnerWrapper xCenter gap-2">
                <div className="shortner sameWidthResponsive" style={{width: "650px"}}>
                    <Shortner handleHistory={handleHistory} type={"customPrompt"} route={customRoute} handleGenerated={handleGenerated} />
                </div>
            </div>
            <Link to={"/editform/"+route} className={(isGenerated) ? "yCenter text-decoration-none justify-content-center gap-2 cursorPointer transition sameWidthResponsive rounded-2 p-2 editFormBtn border border-primary text-white bg-primary" : "yCenter text-decoration-none justify-content-center gap-2 cursorPointer transition sameWidthResponsive rounded-2 p-2 editFormBtn"}  style={{width: "650px",pointerEvents:(!isGenerated) ? "none" : ""}}>
                <p className="m-0">Customize Prompt Form</p>
                <span className="iconWrapper"><AiTwotoneEdit /></span>
            </Link>
        </div>
    </div>
  )
}

export default PromptWithCustom
