import React, { useEffect } from 'react'
import { FaUser, FaEnvelope, FaLocationArrow, FaPhone, FaLock, FaCalendarDay } from "react-icons/fa";

function EditProfilePopup({onSubmitForm}) {

    // const data = {
    //     profilePic: "img/profile.jpg",
    // }

    const closePopup = () => {
        const editProfilePopup = document.getElementById("editProfilePopup");
        editProfilePopup.classList.add("d-none");
    }

    useEffect(() => {
        const submitBtn = document.getElementById("submitBtn");
        submitBtn.addEventListener("click", () => {
            console.log(submitBtn);
            onSubmitForm();
            closePopup();
        })
    }, [onSubmitForm])

  return (
    <>
        <div id='popupMask' className='container-fluid w-100 h-100 position-fixed bg-black top-0 start-0 end-0 bottom-0 bg-opacity-75' style={{zIndex: "9999", backdropFilter: "blur(3px)"}}></div>

        <div id='editFormPopup' className="editForm bg-white rounded-3 position-fixed top-50 start-50 p-3 p-sm-4 xCenter gap-3" style={{width: "500px", maxWidth: "100%", transform: "translate(-50%, -50%)", zIndex: "9999"}}>
    
            <div className="title yCenter justify-content-between">
                <h5 className='m-0'>Edit Profile</h5>
                {/* <div className="imgWrapper overflow-hidden rounded-circle position-relative" style={{width: "60px"}}>
                    <img src={data.profilePic} alt="profile" className='w-100 h-100' />
                    <span className="iconWrapper"></span>
                </div> */}
            </div>
    
            <div className="form xCenter gap-2">
                {/* <form className='xCenter gap-2'> */}
    
                    <div className="row" style={{rowGap: "1rem"}}>
                        <div className="col-6 userFormField" id="First Name">
                            <div className="xCenter gap-0">
                                <p className='m-0'>First Name</p>
                                <span className="inputWrapper inputField">
                                    <FaUser className='icon' />
                                    <input type="text" className='w-100 h-100' placeholder="Mohit" />
                                </span>
                            </div>
                        </div>
                        <div className="col-6 userFormField" id="Last Name">
                            <div className="xCenter gap-0">
                                <p className='m-0'>Last Name</p>
                                <span className="inputWrapper inputField">
                                    <FaUser className='icon' />
                                    <input type="text" className='w-100 h-100' placeholder="Singh" />
                                </span>
                            </div>
                        </div>
                    </div>
    
                    <div className="row" style={{rowGap: "1rem"}}>
                        <div className="col-12 userFormField" id="Email">
                            <div className="xCenter gap-0">
                                <p className='m-0'>Email</p>
                                <span className="inputWrapper inputField yCenter gap-1">
                                    <FaEnvelope className='icon' />
                                    <input type="text" className='w-100 h-100' placeholder="lsdm.mohit@gmail.com" />
                                </span>
                            </div>
                        </div>
                    </div>
    
                    <div className="row" style={{rowGap: "1rem"}}>
                        <div className="col-12 userFormField" id="Phone Number">
                            <div className="xCenter gap-0">
                                <p className='m-0'>Phone Number</p>
                                <span className="inputWrapper inputField">
                                    <FaPhone className='icon' />
                                    <input type="text" className='w-100 h-100' placeholder="+91 92723 32993" />
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{rowGap: "1rem"}}>
                        <div className="col-12 userFormField" id="Date of Birth">
                            <div className="xCenter gap-0">
                                <p className='m-0'>Date of Birth</p>
                                <span className="inputWrapper inputField">
                                    <FaCalendarDay className='icon' />
                                    <input type="date" className='w-100 h-100' placeholder="22-9-2003" />
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{rowGap: "1rem"}}>
                        <div className="col-12 userFormField" id="Home Address">
                            <div className="xCenter gap-0">
                                <p className='m-0'>Address</p>
                                <span className="inputWrapper inputField">
                                    <FaLocationArrow className='icon' />
                                    <input type="text" className='w-100 h-100' placeholder="Firojpur Road, Sector 23 A, Street main" />
                                </span>
                            </div>
                        </div>
                    </div>
    
                    {/* <div className="row" style={{rowGap: "1rem"}}>
                        <div className="col-6 userFormField" id="City">
                            <div className="xCenter gap-0">
                                <p className='m-0'>City</p>
                                <span className="inputWrapper inputField">
                                    <FaCity className='icon' />
                                    <input type="text" className='w-100 h-100' placeholder="Ludhiana"  inputField/> */}
                                    {/* <select name="city" id="citySelect" placeholder='Ludhiana' className='border-0' >
                                        <option>Ludhiana</option>
                                    </select> */}
                                {/* </span>
                            </div>
                        </div>
                        <div className="col-6 userFormField" id="State">
                            <div className="xCenter gap-0">
                                <p className='m-0'>State</p>
                                <span className="inputWrapper inputField">
                                    <FaGlobe className='icon' />
                                    <input type="text" className='w-100 h-100' placeholder="Punjab" /> */}
                                    {/* <select name="city" id="citySelect" placeholder='Ludhiana' className='border-0' >
                                        <option>Punjab</option>
                                    </select> */}
                                {/* </span>
                            </div>
                        </div>
                    </div> */}

                    {/* these 2 fields are d-none, they are read-only */}
                    <div className="row" style={{rowGap: "1rem"}}>
                        <div className="col-12 userFormField" id="Password">
                            <div className="xCenter gap-0">
                                <p className='m-0'>Password</p>
                                <span className="inputWrapper inputField">
                                    <FaLock className='icon' />
                                    <input type="text" className='w-100 h-100' placeholder="Char&#369" />
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row d-none" style={{rowGap: "1rem"}}>
                        <div className="col-12 userFormField" id="Premium">
                            <div className="xCenter gap-0">
                                <p className='m-0'>Premium</p>
                                <span className="inputWrapper inputField">
                                    <FaLock className='icon' />
                                    <input type="text" className='w-100 h-100' placeholder="Yes" value="Yes" />
                                </span>
                            </div>
                        </div>
                    </div>
                                
                    <div className="yCenter justify-content-end gap-2 mt-2">
                        <button className="btn py-1 border-primary rounded-1" style={{width: "100px",}} onClick={closePopup}>Cancel</button>
                        <button className="btn py-1 btn-primary rounded-1" style={{width: "100px",}} id="submitBtn">Save</button>
                    </div>
                                
                {/* </form> */}
            </div>
                                
        </div>
    </>
  )
}

export default EditProfilePopup
