import React, { useEffect, useState } from 'react'
import Shortner from '../../shortner/Shortner'
import Head from '../../head/Head'
import { generateQr, generateUniqueTestId, getHead, getShortUrl, removeHttp } from '../../../functions';
import { useOutletContext } from 'react-router-dom';
import UploadFile from '../../uploadFile/UploadFile';
import { BsArrowRight } from 'react-icons/bs';
import CustomUrlInput from '../../customUrlInput/CustomUrlInput';
import { defaultPromptForm } from '../../../constants';

function OnlineQuiz() {

    const handleHistory = useOutletContext();
    const [youtubeVideo,setYoutubeVideo] = useState("");
    // const [hostPassword,setHostPassword] = useState("");
    const [hostPassword,setHostPassword] = useState("");
    const [isQuestionsUploaded,setIsQuestionsUploaded] = useState(false);
    const [isGenerated,setIsGenerated] = useState(false);
    const [customRoute,setCustomRoute] = useState();

    const handleCustomRoute = (route)=>{
        setCustomRoute(route)
    }
    const handleGenerated = (bool)=>{
        setIsGenerated(bool)
    }

    const handleQuestionsUploaded = (bool)=>{
        setIsQuestionsUploaded(bool)
    }

    const hostTest = async ()=>{
        const shortedUrlWrapper = document.querySelector(".shortedLink");
        let videoId = "";
        // const split = youtubeVideo.split("=")
        let videoIdMatch_v = youtubeVideo.match(/[?&]v=([^&]+)/);
        let videoIdMatch_l = youtubeVideo.match(/\/live\/([^\?]+)/);
        if (videoIdMatch_v) {
            videoId = videoIdMatch_v[1];
        }else if(videoIdMatch_l){
            videoId = videoIdMatch_l[1];
        }

        const payload = {
            url: generateUniqueTestId(),
            type : "quiz",
            route :(customRoute) ? customRoute : "",
            file : isQuestionsUploaded,
            youtubeVideo: videoId,
            hostPassword : hostPassword,
            attributes : defaultPromptForm
        }
        const shortendUrl = await getShortUrl(payload);
        console.log(shortendUrl);
        shortedUrlWrapper.setAttribute("href", shortendUrl);
        const cleanedLink = removeHttp(shortendUrl);
        shortedUrlWrapper.innerHTML = cleanedLink;
        generateQr(shortendUrl);
        handleHistory();
        handleGenerated(true)
    }


    const data = {
        title: "Take Quiz In Realtime Envionment",
    }

    const [heroProtectedData, setHeroProtectedData] = useState();
  
    useEffect(() => {
        
        const getHeadData = async () => {
            const headData = await getHead("heroProtected");
            setHeroProtectedData(headData);
        }
        getHeadData();

    }, [])    

  return (
    <div className="xCenter gap-3 gap-md-5">
        <Head data={heroProtectedData} />
        <div className="pe-0 pe-lg-5">
            {/* <div className="heading pe-0 pe-lg-5 me-0 me-lg-5"> */}
            <div className="heading">
                <h1 className='m-0 extraLargeFont fw-semibold lh-sm'>{data.title}</h1>
            </div>
        </div>
        <div className="shortnerWrapper xCenter gap-2">
            <div className="shortner sameWidthResponsive" style={{width: "650px"}}>
                {
                    (!isGenerated) ?
                    <>
                        <div className="sampleFile wrapper p-2 border-1 rounded-2 lighterBg yCenter gap-2 justify-content-between mb-1">
                            <p className="text m-0">Download Questions Format</p>
                            <button className="btn btn-primary py-1 my-0">Download</button>
                        </div>
                        <UploadFile handleQuestionsUploaded={handleQuestionsUploaded}/>
                    </> :<></>
                }
                {
                    (isQuestionsUploaded && !isGenerated) ?
                    <>
                        <CustomUrlInput isGenerated={isGenerated} handleCustomRoute={handleCustomRoute}  />

                        <div className="choosePassword wrapper p-2 border-1 rounded-2 lighterBg yCenter gap-2 justify-content-between my-1">
                            <input value={youtubeVideo} onChange={(e)=>setYoutubeVideo(e.target.value)} type="text" className="form-control" placeholder="Enter Youtube Video Link" />
                        </div>
                        <div className="choosePassword wrapper p-2 border-1 rounded-2 lighterBg yCenter gap-2 justify-content-between my-1">
                            <input value={hostPassword} onChange={(e)=>setHostPassword(e.target.value)} type="text" className="form-control" placeholder="Choose Host Password" />
                            <button onClick={hostTest} className='btn btn-primary yCenter justify-content-center gap-2 fw-medium shortUrlBtn' style={{ width: "35%" }}>
                                <p className='m-0'>Host Test</p>
                                <span className="arrow"><BsArrowRight /></span>
                            </button>
                        </div>
                    </>
                    : <></>
                }
            </div>
            <p className='m-0' style={{"paddingLeft": "12px"}}>{data.shortnerText}</p>
        </div>
    </div>
  )
}

export default OnlineQuiz