import React, { useEffect, useState } from 'react'
import Shortner from '../../shortner/Shortner'
import CustomUrlInput from '../../customUrlInput/CustomUrlInput'
import Head from '../../head/Head'
import { getHead } from '../../../functions'
import { useOutletContext } from 'react-router-dom'

function CustomUrl() {

    const data = {
        title: "Impress audience with Customized URLs",
    }
    const handleHistory = useOutletContext();

    const [customRoute,setCustomRoute] = useState();
    const [isGenerated,setIsGenerated] = useState(false);

    const handleCustomRoute = (route)=>{
        setCustomRoute(route)
    }

    const handleGenerated = (bool)=>{
        setIsGenerated(bool)
    }

    const [heroProtectedData, setHeroProtectedData] = useState();
  
    useEffect(() => {
        const getHeadData = async () => {
            const headData = await getHead("heroProtected");
            setHeroProtectedData(headData);
        }
        getHeadData();
    }, [])

  return (
    <div className="xCenter gap-3 gap-md-5">
        <Head data={heroProtectedData} />
        <div className="pe-0 pe-lg-5">
            <div className="heading pe-0 pe-lg-5 me-0 me-lg-5">
                <h1 className='m-0 extraLargeFont fw-semibold lh-sm'>{data.title}</h1>
            </div>
        </div>
        <div className=''>
            <CustomUrlInput isGenerated={isGenerated} handleCustomRoute={handleCustomRoute}  />
            <div className="shortnerWrapper xCenter gap-2 pt-2 pt-md-3">
                <div className="shortner" style={{width: "650px"}}>
                    <Shortner handleHistory={handleHistory} type={"custom"} route={customRoute} handleGenerated={handleGenerated} />
                </div>
            </div>
        </div>
    </div>
  )
}

export default CustomUrl
