import React, { useEffect, useRef, useState } from 'react'
import { secondsToMinutes } from '../../functions';

function TimeTicker({startTicker,time,timeout,handleRemainingTime}) {

    const [minClass,setMinClass] = useState("text-dark");
    const [secClass,setSecClass] = useState("text-dark");
    const [min,setMin] = useState("00");
    const [sec,setSec] = useState("00");

    useEffect(() => {

        let ticker;

        const timeSettings = (time)=>{
            handleRemainingTime(time);
            const timeConverted = secondsToMinutes(time);
            setMin(timeConverted.min);
            setSec(timeConverted.sec);
            if(Number(timeConverted.sec) <= 30){
                setSecClass("text-danger");
            }else{
                setSecClass('text-dark');
            }
            if(Number(timeConverted.min) < 1){
                setMinClass("text-danger");
            }else{
                setMinClass('text-dark');
            }
        }
        timeSettings(time);
        if(startTicker){
            ticker = setInterval(() => {
                if(startTicker){
                    timeSettings(time-1);
                    time = time-1;
                    if(time === 0){
                        clearInterval(ticker);
                        timeout(time); //passed Remaining Time
                        setSecClass('text-warning');
                        setMinClass('text-warning');
                    }
                }else{
                    clearInterval(ticker);
                    setSecClass('text-warning');
                    setMinClass('text-warning');
                }
            }, 1000);
        }

        return ()=>{
            clearInterval(ticker);
        }

        
    }, [time,startTicker])

  return (
    <div className='card flex-row gap-1 p-2 fw-bold timeTicker'>
        <span id='tickerMinutes' className={minClass}>{min}</span>
        <span className="fw-bold mx-1 divider text-muted">:</span>
        <span id='tickerSeconds' className={secClass}>{sec}</span>
    </div>
  )
}

export default TimeTicker