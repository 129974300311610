import React, { useEffect, useRef, useState } from 'react'
import "./QuestionPreview.css"
import TimeTicker from './TimeTicker';
function QuestionPreview({refreshMe,question,prevQuestion,nextQuestion,showDesc,isAdminSide,checkQuestionActive,checkAnswerReveal,checkOptionsReveal,revealAnswer,revealOptions,submitAnswer,checkAnswerGiven}) {
    const timeRemaining = useRef(question.time);
    const [timer,setTimer] = useState(true);
    const [questionTime,setQuestionTime] = useState(timeRemaining.current);
    const [choosenOption,setChoosenOption] = useState();
    const [questionDisabled,setQuestionDisabled] = useState(false);
    const [optionsRevealed,setOptionsRevealed] = useState(false);
    const [ansRevealed,setAnsRevealed] = useState(false);
    const [optionClass,setOptionClass] = useState(["col-12 option","col-12 option","col-12 option","col-12 option","col-12 option","col-12 option"]);
    const allOptions = ["A","B","C","D","E"];

    const handleQuestionDisabled = ()=>{
        setQuestionDisabled(true);
        setOptionClass(["col-12 option bg-warning","col-12 option bg-warning","col-12 option bg-warning","col-12 option bg-warning","col-12 option bg-warning","col-12 option bg-warning"]);
    }
    const handleChoosenAns = (choosen)=>{
        console.log(choosen);
        if(ansRevealed || questionDisabled || !checkQuestionActive(question._id)) {return;}
        if(isAdminSide && choosen !== (question.correct).charCodeAt(0)){return;}
        setChoosenOption(choosen);
        setOptionClass(optionClass.map((option, index) => {
            return (index + 65 === choosen) ? "col-12 option active" : "col-12 option";
        }));
    }
    const handleRemainingTime = (time)=>{
        timeRemaining.current = time;
    }

    const handleAnswerSubmition = ()=>{

        submitAnswer(question._id,String.fromCharCode(choosenOption),timeRemaining.current);
        setQuestionDisabled(true);
        setTimer(false);
        setQuestionTime(timeRemaining.current);
        const optionAns = optionClass.map(() => {
            return "col-12 option";
        });
        optionAns[choosenOption-65] = "col-12 option bg-warning";
        setOptionClass(optionAns);
    }

    const revealAns = ()=>{
        revealAnswer();
        setAnsRevealed(true);
        const optionAns = optionClass.map(() => {
            return "col-12 option";
        });
        optionAns[(question.correct).charCodeAt(0) - 65] = "col-12 option correct";
        if(choosenOption !== (question.correct).charCodeAt(0)){
            optionAns[choosenOption-65] = "col-12 option incorrect";
        }
        setOptionClass(optionAns);
    }
    // const showAnswer = ()=>{
    //     setAnsRevealed(true);
    //     let optionAns;
    //     if(choosenOption || isAdminSide === true){
    //         optionAns = optionClass.map(() => {
    //             return "col-12 option";
    //         });
    //     }else{
    //         optionAns = optionClass.map(() => {
    //             return "col-12 option bg-warning";
    //         });
    //     }
    //     optionAns[(question.correct).charCodeAt(0) - 65] = "col-12 option correct";
    //     console.log(choosenOption);
    //     if(choosenOption !== (question.correct).charCodeAt(0)){
    //         optionAns[choosenOption-65] = "col-12 option incorrect";
    //     }
    //     setOptionClass(optionAns);
    // }
    useEffect(() => {
        const controlpanel = document.querySelector('.controlpanel');
        controlpanel.scrollTo(0, 0);
        setOptionsRevealed(checkOptionsReveal(question._id));
        setQuestionTime(question.time);
        if(!isAdminSide){
            const choosen = checkAnswerGiven(question._id).charCodeAt(0);
            if(!isNaN(choosen)){
                setChoosenOption(choosen);
            }
        }
        
      return () => {
          setAnsRevealed(false);
          setOptionsRevealed(false);
          setQuestionDisabled(false);
          setTimer(true);
          setChoosenOption();
          setOptionClass(["col-12 option","col-12 option","col-12 option","col-12 option","col-12 option","col-12 option"]);
      }
    }, [checkAnswerGiven,checkOptionsReveal,question,refreshMe,isAdminSide])

    useEffect(() => {
        const showAnswer = ()=>{
            setAnsRevealed(true);
            let optionAns;
            if(choosenOption || isAdminSide === true){
                optionAns = optionClass.map(() => {
                    return "col-12 option";
                });
            }else{
                optionAns = optionClass.map(() => {
                    return "col-12 option bg-warning";
                });
            }
            optionAns[(question.correct).charCodeAt(0) - 65] = "col-12 option correct";
            console.log(choosenOption);
            if(choosenOption !== (question.correct).charCodeAt(0)){
                optionAns[choosenOption-65] = "col-12 option incorrect";
            }
            setOptionClass(optionAns);
        }
        const isNotReveal = checkAnswerReveal(question._id);
        if(!isNotReveal){
            showAnswer();
        }
    }, [isAdminSide,question.correct,question._id,checkAnswerReveal,choosenOption,refreshMe])
    
    
  return (
    
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 d-flex justify-content-between align-items-center">
                    <p className='queNumber mb-2'>Question {question._id}</p>
                    <div className="queInfo mb-2 d-flex justify-content-center gap-2 align-items-center">
                        <p className='queMarks m-0'>{question.marks} Point</p>
                        <div className='queTime'>
                            {
                                optionsRevealed && checkAnswerReveal(question._id) 
                                ? 
                                    <TimeTicker 
                                        startTicker={timer} 
                                        timeout={handleQuestionDisabled} 
                                        handleRemainingTime={handleRemainingTime} 
                                        time={questionTime}
                                    /> : <></>
                            }
                            
                        </div>

                    </div>
                </div>
                <div className="col-12">
                    <h4 className='question'>{question.question}</h4>
                </div>
                <div className="options">
                    {
                        (optionsRevealed) ?
                        allOptions.map((optionName,index)=>{
                            if(question[optionName] !== ""){
                                return (
                                    <div onClick={()=>handleChoosenAns(optionName.charCodeAt(0))} key={"option"+index} className={optionClass[optionName.charCodeAt(0)- 65]}>
                                        <span className="optionNumber">{optionName}. </span>
                                        <p className="m-0 optionValue">{question[optionName]}</p>
                                    </div>
                                )
                            }else{
                                return <></>
                            }
                        })
                        : 
                        <div className="revalOption">
                            <div className="timeAlloted">
                                <TimeTicker 
                                    startTicker={false} 
                                    timeout={handleQuestionDisabled} 
                                    handleRemainingTime={handleRemainingTime} 
                                    time={question.time}
                                />
                            </div>
                            {
                                (isAdminSide) ? <button onClick={()=>{setOptionsRevealed(true); revealOptions()}} className="btn btn-primary mt-4 w-100">Start Ticker</button> : <></>
                            }
                        </div>
                    }
                </div>
                {
                    (isAdminSide) ? 
                        <div className="actionsBtns mt-4 d-flex justify-content-between ">
                            <div className="questionSwitch">
                                {prevQuestion() ? <button onClick={()=>prevQuestion(true)} className="btn btn-success rounded-0 mx-1">Prev</button>: ""} 
                                {nextQuestion() ? <button onClick={()=>nextQuestion(true)} className="btn btn-success rounded-0 mx-1">Next</button>: ""}
                            </div>
                            {
                                checkAnswerReveal(question._id) ?
                                <button onClick={revealAns} className="btn btn-success rounded-0">Reveal Answer</button>
                                :
                                <button onClick={showDesc} className="btn btn-secondary rounded-0">See Description</button>
                            }
                        </div>
                    :<></>
                }
                {
                    (!isAdminSide) ?
                        <div className="submitBtn mt-4">
                            {choosenOption && timer && checkAnswerReveal(question._id) ?
                            <button onClick={handleAnswerSubmition} className="btn btn-success float-end rounded-0">Submit</button>
                            :
                            <button onClick={showDesc} className="btn btn-secondary rounded-0 float-end">See Description</button>
                            }
                        </div>
                    : <></>
                }
            </div>
        </div>
  )
}

export default QuestionPreview