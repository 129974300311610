import { FcApproval } from 'react-icons/fc'
function Feature({card,index}) {
  return (
    <div key={"featureCard"+index} className='card border-5 border-dark xCenter mx-2 mx-md-0 p-3 gap-3'>
        <div className="rounded- xCenter gap-2">
            <div className="yCenter gap-2">
                <span className="imgWrapper">
                    <img src={card.cardVector} alt="vector" className='w-100 h-100'/>
                </span>
                <h5 className='m-0'>{card.cardHeading}</h5>
            </div>
            <p className='m-0 textSecondary'>{card.para}</p>
        </div>
        <hr className='m-0'/>
        <div className='xCenter gap-2'>
            <p className='m-0 fw-semibold'>{card.subHeading}</p>
            <div className="features xCenter gap-2">
                {card.features.map((feature,index) => {
                    return(
                        <div key={"feature"+index}  className="yCenter gap-2">
                            <span className='allCenter'><FcApproval/></span>
                            <p className='m-0'>{feature.text}</p>
                        </div>
                    )
                })}
            </div>
            <div className='allCenter gap-2 mt-3 mt-md-5'>
                <button className='btn btn-primary'>Get Started for Free</button>
                <p className='m-0 text-priamary cursorPointer'>Learn More</p>
            </div>
        </div>
    </div>
  )
}

export default Feature