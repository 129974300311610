import React, { useEffect, useState } from 'react'
import { BsArrowRight } from "react-icons/bs";
import { extractCodeFromURL, generateQr, getShortUrl, removeHttp, validateUrl } from '../../functions';
import { defaultPromptForm } from '../../constants';


function Shortner({type,handleGenerated,handleHistory,route,attributes}) {
  const [short, setShort] = useState(false);
  const [processSleep,setProcessSleep] = useState(false);
  // const [shortInProgress, setShortInProgress] = useState(false);
  
  // const [responseData, setResponseData] = useState(null);
  // const [error, setError] = useState(null);
  let shortInProgress = false;

  const visibleShortLoader = (btn) => {
    const icon = btn.parentElement.querySelector(".arrow").innerHTML;
    if(shortInProgress) {
      btn.innerHTML = `
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
      `;
    } else {
      btn.innerHTML = `
      <p class='m-0'>Short Link</p>
      <span class="arrow">${icon}</span>
      `;
    }
  }

  const data = {
    text: "Short Link",
  }

  async function shortUrl(shortAllow) {
    if(processSleep){return;}
    const shortedUrlWrapper = document.querySelector(".shortedLink")
    const urlAlert = document.querySelector(".urlInfo")
    const urlReview = document.getElementById("urlReview");
    const urlInput = document.getElementById("urlInput");
    let shortUrlBtn = document.querySelector(".shortUrlBtn");
    if (shortAllow) {
      if (urlInput.value !== "") {
        shortInProgress = true;
        visibleShortLoader(shortUrlBtn);

        urlAlert.innerHTML = "Your Shorten Link";
        const payload = {
          url: urlInput.value,
          type : (type) ? type : "direct",
          route : (route) ? route : "",
          attributes : (attributes) ? attributes : defaultPromptForm,
        }
        const shortendUrl = await getShortUrl(payload);
        setProcessSleep(true);
        
        console.log(shortendUrl);

        if(shortendUrl) {
          const route = shortendUrl.split('/').at(-1);
          handleGenerated(route,true)
          handleHistory()
          shortInProgress = false;
          visibleShortLoader(shortUrlBtn);
        }

        shortedUrlWrapper.setAttribute("href", shortendUrl);
        const cleanedLink = removeHttp(shortedUrlWrapper.getAttribute("href"));
        shortedUrlWrapper.innerHTML = cleanedLink;
        generateQr(shortendUrl);
        
        const lastShortenCode = extractCodeFromURL(shortendUrl);
        if(urlReview) {
          urlReview.setAttribute("src", `https://dizzi.link/shortcut/urlFrame/${lastShortenCode}`);
        }
        // console.log(qrCode);
        setTimeout(() => {
          setProcessSleep(false);
        }, 1000);
      } else {
        urlAlert.innerHTML = "Enter URL to Short";
      }
    } else {
      urlAlert.innerHTML = "Enter Valid Url";
    }
  }

  function validate(input) {
    if (validateUrl(input.value)) {
      setShort(true);
      input.parentElement.classList.add("border", "border-2", "border-primary")
      input.parentElement.classList.remove("border", "border-2", "border-danger")
    } else {
      setShort(false);
      input.parentElement.classList.remove("border", "border-2", "border-primary")
      input.parentElement.classList.add("border", "border-2", "border-danger")
    }
  }

  return (
      <div className="wrapper p-2 border-1 rounded-2 lighterBg yCenter gap-2 justify-content-between">
        <input id='urlInput' type="url" className='bg-transparent' placeholder="https://site.com/dizzilink" style={{ "marginLeft": "5px", width: "65%" }} onKeyUp={(e) => validate(e.currentTarget)} />
        <span className="arrow d-none"><BsArrowRight /></span>
        <button className='btn btn-primary yCenter justify-content-center gap-2 fw-medium shortUrlBtn' style={{ width: "35%" }} onClick={() => shortUrl(short)}>
          {
            (processSleep === true) ? <p className='m-0'>Generated</p> :  <p className='m-0'>{data.text}</p>
          }
          <span className="arrow"><BsArrowRight /></span>
        </button>
      </div>
  )
}

export default Shortner