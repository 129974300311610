import React, { useEffect, useState } from 'react'
import { fetchParticipants } from '../../functions';

function QuestionStats({test_id,rightAnswered, wrongAnswered }) {

    const [totalParticipants,setTotalParticipants] = useState(0);
    const [notAnswered,setNotAnswered] = useState(0);
    const [wrongPercentage,setWrongPercentage] = useState();
    const [notPercentage,setNotPercentage] = useState();

    useEffect(() => {
        const getData = async ()=>{
            const people = await fetchParticipants(test_id,"marks");
            setTotalParticipants(people.length);
        }
        getData();

    }, [])

    useEffect(() => {
      const wrongP = (totalParticipants > 0 && wrongAnswered > 0) ? (wrongAnswered / (totalParticipants)) * 100 : 0;
      setWrongPercentage(wrongP);
      const notP = (totalParticipants >0 && (rightAnswered+wrongAnswered)>0 ) ? wrongP + (notAnswered / totalParticipants) * 100 : wrongP;
      setNotPercentage(notP);
      setNotAnswered(totalParticipants - (rightAnswered+wrongAnswered))
    }, [totalParticipants,rightAnswered,wrongAnswered])
    

    return (
        <div className="row overallQuestionStats">
            <div className="col-3">
                <div className="statNumbers d-flex justify-content-center gap-4 align-items-center">
                    <div className="attemptStats passed">
                        {
                            ((wrongAnswered + rightAnswered) ===0)
                            ? <p className="stroke animate lineShimmer"></p>
                            : <p className="m-0 number">{((rightAnswered / (totalParticipants)) * 100).toFixed(0)}%</p>
                        }
                        <span className="info text-muted">Pass</span>
                    </div>
                </div>
            </div>
            <div className="col-3">
                <div className="statNumbers d-flex justify-content-center gap-4 align-items-center">
                    <div className="attemptStats failed">
                        <p className="m-0 number">
                            {(((totalParticipants - rightAnswered) / totalParticipants) * 100).toFixed(0)}%
                        </p>
                        <span className="info text-muted">Wrong & NA</span>
                    </div>
                </div>
            </div>
            <div className="col">
                <div className="row">
                    <div className="col-2">
                        <div className="progressStats">
                            <div style={{ "background": `conic-gradient(#f97a83 0% ${wrongPercentage}%,#d6e0f5 ${wrongPercentage}% ${notPercentage}%,#4ac493 ${notPercentage}% 100%)` }} id="progress-percent" className="progress-circle">
                                <div className="progress-circle-fill"></div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="statValues">
                            <div className="value">
                                <span style={{ background: "#4ac493" }} className="colorCode"></span>
                                <span className="colorValue text-muted">{rightAnswered} Right</span>
                            </div>
                            <div className="value">
                                <span style={{ background: "#f97a83" }} className="colorCode"></span>
                                <span className="colorValue text-muted">{wrongAnswered} Wrong</span>
                            </div>
                            <div className="value">
                                <span style={{ background: "#d6e0f5" }} className="colorCode"></span>
                                <span className="colorValue text-muted">{notAnswered} NA</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default QuestionStats