import React, { useState } from 'react'
import { validateUrl } from '../../functions';

function UrlEditPopup({handleSave,saveParams}) {
    const [destinationUrl,setDestinationUrl] = useState();
    const [isValidUrl,setIsValidUrl] = useState(false);
    const [inputAppearance,setInputAppearance] = useState("form-control");
    const handleDestinationUrl = (url)=>{
        const validate = validateUrl(url);
        setDestinationUrl(url)
        if(validate){
            setIsValidUrl(true);
            setInputAppearance('form-control is-valid');
        }else{
            setIsValidUrl(false);
            setInputAppearance('form-control is-invalid');
        }

    }
  return (
    <>
        {/* <a href="#myModal" className="trigger-btn" data-toggle="modal">Click to Open Confirm Modal</a> */}
        {/* need to add href and toggle attributes to button want to add popup */}
        <div id="urlEditPopup" className="modal fade" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="urlEditPopupLabel" aria-hidden="true">
            <div className="modal-dialog modal-confirm">
                <div className="modal-content">
                    <div className="modal-header flex-column">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
                        <div className="icon-box" style={{width:"100px"}}>
                            <img src="https://dizzi.link/assets/icons/url.png" alt="" className="h-100 w-100" />
                        </div>
                        <h4 className="modal-title w-100 text-center mt-3">Change Destination Url</h4>
                    </div>
                    <div className="modal-body">
                        <input id='destinationUrl' value={destinationUrl} onInput={(e)=>handleDestinationUrl(e.target.value)} type="text" className={inputAppearance} placeholder='Enter Destination Url'/>
                    </div>
                    <div className="modal-footer justify-content-center">
                        <button onClick={()=>{setDestinationUrl("");setInputAppearance("form-control")}} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        {
                            (isValidUrl) ? <button onClick={()=>{handleSave(saveParams,destinationUrl);setDestinationUrl("");setInputAppearance("form-control")}} type="button" className="btn btn-primary"  data-bs-dismiss="modal" >Save Changes</button>
                            : <button className="btn btn-secondary disabled">Save Changes</button>
                        }
                        
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default UrlEditPopup