import React, { useEffect, useRef, useState } from 'react'
import {BsSendCheck} from "react-icons/bs"
import { formatRelativeTime } from '../../functions';
function LiveChat({candidate,allMessagesRef,setAllMessagesRef,message,broadcastMessage,isAdminSide}) {
    const colors = [
        "#DDA0DD", // Light purple
        "#90EE90", // Light green
        "#87CEFA", // Light sky blue
        "#FFB6C1", // Light pink
        "#98FB98", // Pale green
        "#FFE4B5", // Moccasin
        "#AFEEEE", // Pale turquoise
        "#F0E68C", // Khaki
        "#B0E0E6", // Powder blue
        "#FFD700"  // Gold
    ];
    const [disableChat,setDisableChat] = useState();
    const [time,setTime] = useState(0);
    const [newMessage,setNewMessage] = useState("");
    const [allMessages,setAllMessages] = useState(allMessagesRef);
    
    useEffect(() => {
        const chatbox = document.querySelector('.chatbox');
        chatbox.scrollTo(0, chatbox.scrollHeight);
    }, [allMessages])
    

    useEffect(() => {
        if(message){
            setAllMessages([...allMessages,message]);
            setAllMessagesRef([...allMessages,message]);
        }
    }, [message])
    
    useEffect(() => {
        if(time>0){
            setTimeout(() => {
                const newTime = time - 1;
                if(newTime === 0){
                    setDisableChat(false);
                }else{
                    setTime(newTime);
                }
            }, 1000);
        }

    }, [time]);
    
    
    const addMessage = ()=>{
        if(newMessage.length > 2){
            const messageTime = new Date();
            if(isAdminSide){
                // setAllMessages([...allMessages,{sender : "Invigilator",message : newMessage,time:messageTime}]);
                // setAllMessagesRef([...allMessages,{sender : "Invigilator",message : newMessage,time:messageTime}]);
                broadcastMessage({sender : "Invigilator",message : newMessage,time:messageTime})
            }else{
                // setAllMessages([...allMessages,{sender : candidate.name,message : newMessage.substring(0,120),time:messageTime}]);
                // setAllMessagesRef([...allMessages,{sender : candidate.name,message : newMessage.substring(0,120),time:messageTime}]);
                broadcastMessage({sender : candidate.name,message : newMessage.substring(0,120),time:messageTime})
                setDisableChat(true);
                setTime(10);
            }
            setNewMessage("")
        }
    }
    
  return (
    <div className='livechat'>
        <h6 className="text-muted">Live Chat</h6>
        <div className="card mt-3 mb-2 p-2 chatbox">
            {
                allMessages.map((message,index)=>{
                    return (
                        <div key={"liveChatMessage"+index} className="message">
                            <div className="icon" style={{background:`${message.sender === "Invigilator" ? "#FFA500" : colors[Math.floor(Math.random() * 10)]}`}}>{(message.sender).charAt(0)}</div>
                            <div className="details w-100" style={message.sender === "Invigilator" ? {background :"#FFA500"} : {}}>
                                <div className="info d-flex justify-content-between align-items-center">
                                    <span className={`m-0 name fw-bold `}>{message.sender}</span>
                                    <span className="m-0 time">{formatRelativeTime(message.time)}</span>
                                </div>
                                <p className={`text ${message.sender === "Invigilator" ? "fw-bold" : ""}`}>{message.message}</p>
                            </div>
                        </div>
                    )
                })
            }
        </div>
        <div className="addMessage input-group mb-3">
            <input value={newMessage} onInput={(e)=>setNewMessage(e.target.value)} type="text" className="form-control" placeholder="Write Message Here"/>
                {
                    disableChat ? <div className="input-group-text timer text-muted">{time}s</div> 
                    : 
                    <button onClick={addMessage} className="input-group-text"><BsSendCheck/></button>
                }
                
        </div>
        
    </div>
  )
}

export default LiveChat