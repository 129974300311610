import React from 'react'

function Checkout() {
  return (
    <div className='container-lg'>
      <div className="row mt-2 mb-4">
        <div className="col-sm-6 m-auto">
          <div className="message">
            <h1>A great big thank you!</h1>
            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nobis, aut reiciendis possimus sit sapiente beatae.</p>
          </div>
        </div>
      </div>
      {/* https://dribbble.com/shots/3364476-Thank-You */}
    </div>
  )
}

export default Checkout