import React from 'react'
import "./HeroProtected.css"
import ResultProtected from '../resultProtected/ResultProtected'
import { Outlet } from 'react-router-dom'

function HeroProtected({handleHistory}) {

  return (
    <div id='Hero' className='container py-5 hero'>
        <div className="row row-gap-3">
            <div className="col-md-8 px-lg-2">
                <div className="wrapper xCenter gap-3 gap-md-4">
                  <Outlet context={handleHistory} />
                </div>
            </div>
            <div className="col-md-4 pt-5 pt-md-0">
                <ResultProtected/>
            </div>
        </div>
    </div>
  )
}

export default HeroProtected
