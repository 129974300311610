import React from 'react'

function ConfirmPopup({handleDelete,deleteParams}) {
  return (
    <>
        {/* <a href="#myModal" className="trigger-btn" data-toggle="modal">Click to Open Confirm Modal</a> */}
        {/* need to add href and toggle attributes to button want to add popup */}
        <div id="confirmPopup" className="modal fade" tabIndex="-1" aria-labelledby="confirmPopupLabel" aria-hidden="true">
            <div className="modal-dialog modal-confirm">
                <div className="modal-content">
                    <div className="modal-header flex-column">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
                        <div className="icon-box" style={{width:"100px"}}>
                            <img src="https://dizzi.link/assets/icons/danger.png" alt="" className="h-100 w-100" />
                        </div>
                        <h4 className="modal-title w-100 text-center mt-3">Are you sure?</h4>
                    </div>
                    <div className="modal-body">
                        <p>Do you really want to delete these records? This process cannot be undone.</p>
                    </div>
                    <div className="modal-footer justify-content-center">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button onClick={()=>handleDelete(deleteParams)} type="button" className="btn btn-danger"  data-bs-dismiss="modal" >Delete</button>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default ConfirmPopup