import React, { useState } from 'react'
import { BsPencilFill } from 'react-icons/bs'
import { focusEnd } from '../../functions'
import "./SocialLinks.css"

function SocialLinks() {

    const data = {
        socialLinks: [
            {
                name: "Instagram",
                icon: "img/icons/insta.png",
                username: "@mohit_singh",
            },
            {
                name: "Facebook",
                icon: "img/icons/facebook.png",
                username: "@mohit_singh",
            },
            {
                name: "Twitter",
                icon: "img/icons/twitter.png",
                username: "@mohit_singh",
            },
            {
                name: "YouTube",
                icon: "img/icons/youtube.png",
                username: "@mohit_singh",
            },
        ],
    }

    const [socialData] = useState(data.socialLinks)

    const saveSocialData = () => {
        console.log(socialData);
    }

    const editSocialLink = (editIcon) => {
        editIcon.classList.add("d-none")
        const editElement = editIcon.previousElementSibling;
        const activeLink = editElement.getAttribute("data-social-name");
        editElement.setAttribute('contentEditable', true);
        focusEnd(editElement);
        
        editElement.addEventListener("keydown", function (event) {
            if (event.keyCode === 13 || event.keyCode === 27 || event.keyCode === 9) {
                event.preventDefault();
                editIcon.classList.remove("d-none")
                editElement.removeAttribute('contentEditable');

                socialData.forEach(socialLink => {
                    if(socialLink.name === activeLink) {
                        socialLink.username = editElement.textContent;
                    }
                })

                saveSocialData();
                
                if(editElement.innerHTML === "") {
                    editElement.innerHTML = "Edit URL";
                }
            }

        })
        editElement.addEventListener("blur", function (event) {
            event.preventDefault();
            editIcon.classList.remove("d-none")
            editElement.removeAttribute('contentEditable');

            socialData.forEach(socialLink => {
                if(socialLink.name === activeLink) {
                    socialLink.username = editElement.textContent;
                }
            })

            saveSocialData();
        
            if(editElement.innerHTML === "") {
                editElement.innerHTML = "Edit URL";
            }
        });

    }

  return (
      <div className="socialLinksContainer xCenter gap-3">
        <div className="titleWrapper yCenter gap-3">
            <p className="m-0 h6">Social Connections</p>
            {/* <span className="iconWrapper text-primary cursorPointer" style={{fontSize: "14px"}}></span> */}
        </div>
        <div className="socialLinks xCenter gap-2">
            
            {socialData.map((socialLink, index) => {
                return(
                    <div key={"socialLink "+index} className="socialLink yCenter gap-3 rounded-3 cursorPointer w-100">
                        <div className="imgWrapper" style={{width: "20px"}}>
                            <img src={socialLink.icon} alt="instagram-new" className="w-100 h-100"/>
                        </div>
                        <div className="yCenter justify-content-between gap-2 w-100 overflow-hidden inlineText" onClick={(e) => editSocialLink(e.currentTarget.querySelector(".iconWrapper"))}>
                            <p data-social-name={socialLink.name} className="m-0 sociallLinkValue border-bottom border-secondary">{socialLink.username}</p>
                            <span className="iconWrapper" style={{fontSize: "14px"}} onClick={(e) => editSocialLink(e.currentTarget)}><BsPencilFill /></span>
                        </div>
                    </div>
                )
            })}

        </div>
    </div>
  )
}

export default SocialLinks