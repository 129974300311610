import React from 'react'
import './Loader.css'

function Loader() {
  return (
    <div className='position-relative'>
      <div id="loader" className='position-absolute top-50 start-50' style={{ transform: "translate(-50%, 50%)" }}>
        <div className="positon-relative w-100 h-100" style={{fontFamily: "Pacifico"}}>
          <span className='m-0 loaderText'>D</span>
        </div>
      </div>
    </div>
  )
}

export default Loader
