import { FcBrokenLink } from "react-icons/fc";

function Service({serviceCard,index}) {
  return (
    <>
        <div className="title yCenter gap-2">
          <span style={{"width": "30px"}}><img src="img/services.png" alt="icon" className="w-100 h-100" /></span>
          <span style={{"width": "30px"}}><img src="img/line.png" alt="" className="w-100 h-100"/></span>
          <p className="h4 m-0 fw-medium">{serviceCard.title}</p>
          </div>
          <div className="content ps-5">
          <p className="mb-2 text-secondary">{serviceCard.text}</p>
          <p className="blackLink yCenter m-0 gap-2 fw-medium cursorPointer">
              <FcBrokenLink size={30}/>
              <span className="m-0 h6">{serviceCard.linkText}</span>
          </p>
        </div>
    </>
  )
}

export default Service