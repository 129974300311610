import React from "react";
import "./WaveLoader.css";

function WaveLoader({lineColor}) {
  return (
    <div id="waveLoaderContainer">
      <div>
        <span style={{backgroundColor: lineColor}}></span>
        <span style={{backgroundColor: lineColor}}></span>
        <span style={{backgroundColor: lineColor}}></span>
        <span style={{backgroundColor: lineColor}}></span>
        <span style={{backgroundColor: lineColor}}></span>
        <span style={{backgroundColor: lineColor}}></span>
        <span style={{backgroundColor: lineColor}}></span>
        <span style={{backgroundColor: lineColor}}></span>
        <span style={{backgroundColor: lineColor}}></span>
        <span style={{backgroundColor: lineColor}}></span>
      </div>
    </div>
  );
}

export default WaveLoader;
