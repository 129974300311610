import React from 'react'

function DetailBox({title,icon,value}) {
  return (
    <div className="detailBox">
        <div className="icon">
            <img className='h-100 w-100' src={icon} alt="" />
        </div>
        <div className="numbers">
            <h4 className='m-0'>{(value) ? value : "Fetching"}</h4>
            <p className='m-0 text-muted'>{title}</p>
        </div>
    </div>
  )
}

export default DetailBox