function TopPerformer({first,second,third}) {
  return (
    <div className="topPerformer">
      {
        (first.name) ?
        <div className="performer">
            <div className="icon mx-auto mb-1"><img className='h-100 w-100' src="https://dizzi.link/assets/icons/first.png" alt="" /></div>
            <p className="text-center m-0 fw-bold fs-5 my-2 name">{first.name}</p>
            <p className="text-center m-0 timeTaken">{first.timeTaken} Seconds</p>
        </div>
        : 
          <div className="noOneAnswered">
            <h2>No One Participated</h2>
          </div>
      }
      {
        (second.name) ? 
          <div className="performer">
              <div className="icon mx-auto mb-1"><img className='h-100 w-100' src="https://dizzi.link/assets/icons/second.png" alt="" /></div>
              <p className="text-center m-0 fw-bold fs-5 my-2 name">{second.name}</p>
              <p className="text-center m-0 timeTaken">{second.timeTaken} Seconds</p>
          </div>
        : <></>
      }
      {
        (third.name) ? 
          <div className="performer">
              <div className="icon mx-auto mb-1"><img className='h-100 w-100' src="https://dizzi.link/assets/icons/third.png" alt="" /></div>
              <p className="text-center m-0 fw-bold fs-5 my-2 name">{third.name}</p>
              <p className="text-center m-0 timeTaken">{third.timeTaken} Seconds</p>
          </div>
        : <></>
      }
    </div>
  )
}

export default TopPerformer