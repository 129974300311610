import { Helmet } from 'react-helmet';

function Head({data}) {
    if(data){
        return (
              <Helmet>
                  <title>{data.title}</title>
                  <meta name = "keywords" content={data.keywords} />
                  <meta name="description" content={data.description} />
              </Helmet>
        )
    }
}

export default Head