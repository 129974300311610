import React, { useEffect, useState } from 'react'
import Shortner from '../../shortner/Shortner'
import Head from '../../head/Head'
import { getHead } from '../../../functions';
import { useOutletContext } from 'react-router-dom';

function Direct() {

    const handleHistory = useOutletContext();
    // console.log(handleHistory);
    const [isGenerated,setIsGenerated] = useState(false);
    const handleGenerated = (bool)=>{
        setIsGenerated(bool)
    }


    const data = {
        title: "Unlock traffic with dizzi's link tool",
    }

    const [heroProtectedData, setHeroProtectedData] = useState();
  
    useEffect(() => {
        
        const getHeadData = async () => {
            const headData = await getHead("heroProtected");
            setHeroProtectedData(headData);
        }
        getHeadData();

    }, [])    

  return (
    <div className="xCenter gap-3 gap-md-5">
        <Head data={heroProtectedData} />
        <div className="pe-0 pe-lg-5">
            <div className="heading pe-0 pe-lg-5 me-0 me-lg-5">
                <h1 className='m-0 extraLargeFont fw-semibold lh-sm'>{data.title}</h1>
            </div>
        </div>
        <div className="shortnerWrapper xCenter gap-2">
            <div className="shortner sameWidthResponsive" style={{width: "650px"}}>
                <Shortner handleGenerated={handleGenerated} handleHistory={handleHistory} />
            </div>
            <p className='m-0' style={{"paddingLeft": "12px"}}>{data.shortnerText}</p>
        </div>
    </div>
  )
}

export default Direct
