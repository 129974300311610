import React from 'react'
import { FaQuestionCircle } from "react-icons/fa";
import { PiCaretDownBold } from "react-icons/pi";
import "./Faq.css"

function Faq() {

    const data = {
        title: "Frequently Asked Questions",
        questionsCols: [
            [
                {
                    question: "What is a URL Shortner ?",
                    answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi accusamus illo molestiae pariatur. Qui, maxime rem consequuntur atque consectetur ex fuga sequi cum provident ea adipisci autem, repudiandae perspiciatis ratione?",
                },
                {
                    question: "What is a URL Shortner ?",
                    answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi accusamus illo molestiae pariatur. Qui, maxime rem consequuntur atque consectetur ex fuga sequi cum provident ea adipisci autem, repudiandae perspiciatis ratione?",
                },
                {
                    question: "What is a URL Shortner ?",
                    answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi accusamus illo molestiae pariatur. Qui, maxime rem consequuntur atque consectetur ex fuga sequi cum provident ea adipisci autem, repudiandae perspiciatis ratione?",
                },
                {
                    question: "What is a URL Shortner ?",
                    answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi accusamus illo molestiae pariatur. Qui, maxime rem consequuntur atque consectetur ex fuga sequi cum provident ea adipisci autem, repudiandae perspiciatis ratione?",
                },
                {
                    question: "What is a URL Shortner ?",
                    answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi accusamus illo molestiae pariatur. Qui, maxime rem consequuntur atque consectetur ex fuga sequi cum provident ea adipisci autem, repudiandae perspiciatis ratione?",
                },
                {
                    question: "What is a URL Shortner ?",
                    answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi accusamus illo molestiae pariatur. Qui, maxime rem consequuntur atque consectetur ex fuga sequi cum provident ea adipisci autem, repudiandae perspiciatis ratione?",
                },
            ],
            [
                {
                    question: "What is a URL Shortner ?",
                    answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi accusamus illo molestiae pariatur. Qui, maxime rem consequuntur atque consectetur ex fuga sequi cum provident ea adipisci autem, repudiandae perspiciatis ratione?",
                },
                {
                    question: "What is a URL Shortner ?",
                    answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi accusamus illo molestiae pariatur. Qui, maxime rem consequuntur atque consectetur ex fuga sequi cum provident ea adipisci autem, repudiandae perspiciatis ratione?",
                },
                {
                    question: "What is a URL Shortner ?",
                    answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi accusamus illo molestiae pariatur. Qui, maxime rem consequuntur atque consectetur ex fuga sequi cum provident ea adipisci autem, repudiandae perspiciatis ratione?",
                },
                {
                    question: "What is a URL Shortner ?",
                    answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi accusamus illo molestiae pariatur. Qui, maxime rem consequuntur atque consectetur ex fuga sequi cum provident ea adipisci autem, repudiandae perspiciatis ratione?",
                },
                {
                    question: "What is a URL Shortner ?",
                    answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi accusamus illo molestiae pariatur. Qui, maxime rem consequuntur atque consectetur ex fuga sequi cum provident ea adipisci autem, repudiandae perspiciatis ratione?",
                },
                {
                    question: "What is a URL Shortner ?",
                    answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi accusamus illo molestiae pariatur. Qui, maxime rem consequuntur atque consectetur ex fuga sequi cum provident ea adipisci autem, repudiandae perspiciatis ratione?",
                },
            ],
        ],
        doubtTitle: "Still have a Question?",
        doubtText: "If you cannot find answer to your question in our FAQ you can always contact us. We will answer to you shortly!",
        contactNo: "+91 76467 00028",
        contactBtnText: "Contact Now",
    }

    const faqs = document.querySelectorAll(".faq");
    faqs.forEach(faq => {
        faq.addEventListener("click", () => {
            if(faq.classList.contains("activeFaq")) {
                faq.classList.remove("activeFaq");
                faq.querySelector(".answer").style.transitionDelay = "-700ms";
            } else {
                faq.querySelector(".answer").style.transitionDelay = "0ms";
                faq.classList.add("activeFaq");
            }
        })
    })

    return (
        <div id="FAQs" className='container-fluid py-5 bg-primary'>
            <div className="container text-white">
                <div className="title yCenter justify-content-between">
                    <h2 className='h1 m-0 fw-semibold'>{data.title}</h2>
                    <span className="iconWrapper extraLargeFont"><FaQuestionCircle /></span>
                </div>

                <div className="faqs pt-3 pt-md-4">
                    <div className="row">

                        {data.questionsCols.map((questionsColumn, index) => {
                            return (
                                <div key={"question "+index} className="col-md-6 gap-3 px-4">

                                    {questionsColumn.map((question,index) => {
                                        return (
                                            <div  key={"questionCol "+index} className="faq cursorPointer xCenter border-bottom px-1 px-sm-3" style={{padding: "1rem 0"}}>
                                                <div className="question yCenter justify-content-between gap-2 gap-md-3">
                                                    <p className='m-0 fs-5' style={{fontWeight: "500"}}>{question.question}</p>
                                                    <span className="iconWrapper caret"><PiCaretDownBold /></span>
                                                </div>
                                                <div className="answer">
                                                    <p className='m-0 py-1'>
                                                        {question.answer}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                            )
                        })}

                    </div>

                    <div className="yCenter justify-content-evenly justify-content-md-between flex-wrap pt-3 mt-4 mt-md-5">
                        <div className="doubt xCenter gap-2 gap-md-3" style={{width: "60%"}}>
                            <h2 className='m-0'>{data.doubtTitle}</h2>
                            <p className='m-0'>{data.doubtText}</p>
                        </div>
                        <div className="contactBtn allCenter p-3">
                            <a href={`tel:${data.contactNo}`} className='m-0 text-decoration-none text-white'>
                                <button className='py-2 px-3 mt-3 h5 transition rounded-0 btn bg-white text-primary hoverWhiteBtn border border-primary'>
                                    {data.contactBtnText}
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Faq
