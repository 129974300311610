import React, { useEffect, useState } from 'react'
import ReactDOMServer from 'react-dom/server';
// import * as html2pdf from "html2pdf.js/dist/html2pdf.bundle.js"
import { FaGlobe, FaMarker, FaQuestion } from 'react-icons/fa'
import TileShimmer from '../components/shimmer/TileShimmer'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Field, Form, Formik } from 'formik';
import { _getCookie, fetchQuestions, fetchResult } from '../functions';
import { Link, useParams } from 'react-router-dom';
import FinalReport from '../components/quiz/FinalReport';

function SplashResult() {
    

    const data = {
        logo: "https://learningsessions.in/wp-content/uploads/2020/10/logo_black_retina-300x99.png",
        testDetails: {
            title: "Indian Economy and Indian Financial System",
            language: "English",
            marks: "83/100"
        },
        rightPanelData: {
            formData: {
                title: "Book Your Free Class Now",
                subTitle: "Join 100 + MN Registered IIBF Users",
                submitBtnText: "Schedule your Class",
            }
        }
    }

    const courses = {
        title: "Explore Courses",
        courses: [
            {
                poster: "https://courses-assets-v2.classplus.co/_next/image?url=/api/proxyimage?url=https%3A%2F%2Fali-cdn-wl-assets.classplus.co%2Fproduction%2Fsingle%2Fgegkt%2F0556887a-5164-4a34-a98c-6922c577604e.jpg&w=384&q=75",
                target: "https://gegkt.courses.store/402633?utm_source%3Dother%26utm_medium%3Dtutor-course-referral%26utm_campaign%3Dcourse-overview-webapp",
                btnText: "Explore Course"
            },
            {
                poster: "https://courses-assets-v2.classplus.co/_next/image?url=/api/proxyimage?url=https%3A%2F%2Fali-cdn-wl-assets.classplus.co%2Fproduction%2Fsingle%2Fgegkt%2Ff80fcb0d-f02a-4ca2-9b7a-098c39189382.jpg&w=384&q=75",
                target: "https://gegkt.courses.store/280553?utm_source%3Dother%26utm_medium%3Dtutor-course-referral%26utm_campaign%3Dcourse-overview-webapp",
                btnText: "Explore Course"
            },
            {
                poster: "https://courses-assets-v2.classplus.co/_next/image?url=/api/proxyimage?url=https%3A%2F%2Fali-cdn-wl-assets.classplus.co%2Fproduction%2Fsingle%2Fgegkt%2F92f01908-1771-49cc-ba6b-1a4851767b54.png&w=256&q=75",
                target: "https://gegkt.courses.store/277182?utm_source%3Dother%26utm_medium%3Dtutor-course-referral%26utm_campaign%3Dcourse-overview-webapp",
                btnText: "Explore Course"
            },
            {
                poster: "https://courses-assets-v2.classplus.co/_next/image?url=/api/proxyimage?url=https%3A%2F%2Fali-cdn-wl-assets.classplus.co%2Fproduction%2Fsingle%2Fgegkt%2F211b55e2-297b-405a-92e6-2239afc061b6.jpg&w=256&q=75",
                target: "https://gegkt.courses.store/402633?utm_source%3Dother%26utm_medium%3Dtutor-course-referral%26utm_campaign%3Dcourse-overview-webapp",
                btnText: "Explore Course"
            },
            {
                poster: "https://courses-assets-v2.classplus.co/_next/image?url=/api/proxyimage?url=https%3A%2F%2Fali-cdn-wl-assets.classplus.co%2Fproduction%2Fsingle%2Fgegkt%2Fe0ffc2c2-1cbe-47b0-ae43-0967ba718a97.png&w=256&q=75",
                target: "https://gegkt.courses.store/280550?utm_source%3Dother%26utm_medium%3Dtutor-course-referral%26utm_campaign%3Dcourse-overview-webapp",
                btnText: "Explore Course"
            },
        ],
    };

    const {test_id,mobile} = useParams();
    const [self,setSelf] = useState();
    const [rank,setRank] = useState();
    const [totalMarks,setTotalMarks] = useState();
    const [selfDetails,setSelfDeatils] = useState();
    const [topper,setTopper] = useState();
    const [questions, setQuestions] = useState([]);
    console.log(mobile);
    useEffect(() => {
        const getData = async ()=>{
            setQuestions([]);
            if(mobile){
                let ques = await fetchQuestions(test_id);
                const result = await fetchResult(test_id,mobile);
                setRank(result.rank);
                setSelf(result.self);
                setSelfDeatils(JSON.parse(result.self.analytics));
                setTopper(result.topper);
                setQuestions([...ques]);
                console.log(JSON.parse(result.self.analytics));
                setTotalMarks(ques.reduce((sum, question) => {
                        const marks = parseFloat(question.marks);
                        return !isNaN(marks) ?  sum + marks : sum;
                    }, 0)
                )
            }
        }
        getData();
    }, [test_id,mobile])

    // const convertToPDF = () => {
    //     const pdfOptions = {
    //         margin: 10,
    //         filename: 'html-to-pdf.pdf',
    //         image: { type: 'jpeg' },
    //         html2canvas: { scale: 1 },
    //         jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    //     };
      
    //     // Render the React component to HTML string
    //     const componentHTML = ReactDOMServer.renderToStaticMarkup(<FinalReport logo={data.logo} testName={questions && questions.length > 0 ? questions[0].test : ""} questions={questions} self={self} selfDetails={selfDetails} />);
    //     console.log(componentHTML);
      
    //     // Create a new div element to hold the rendered HTML
    //     const content = document.createElement('div');
    //     content.innerHTML = componentHTML;
      
    //     // Use the rendered HTML in the PDF conversion
    //     html2pdf().set(pdfOptions).from(content).save();
        
    //   };
      

    

  return (
    <div id='enterTest' className='container-lg pb-5 pt-2 pt-md-5'>
      <div className="row align-items-end" style={{rowGap: "3rem"}}>
        <div className="col-12">
            <nav className="yCenter justify-content-between gap-2 flex-wrap">
                <div className="testLogo" style={{ width: "280px" }}>
                    {data.logo ? <img src={data.logo} alt="Logo-Loading-Error" className='w-100 h-100' /> : <TileShimmer />}
                </div>
                {data.testDetails ?
                    <div className='testMarksLanguage mt-2 mt-md-0 d-flex flex-sm-column gap-3 flex-grow-1 flex-wrap align-items-end justify-content-between h5' style={{rowGap: "0"}}>
                        <div className="testMarks yCenter gap-2">
                            <span style={{ fontSize: "0.9rem" }}><FaMarker /></span>
                            <p className="m-0">
                                Marks: {(questions && questions.length>0) ? questions.reduce((sum, question) => {
                                            const marks = parseFloat(question.marks);
                                            return !isNaN(marks) ? sum + marks : sum;
                                        }, 0): ""}
                            </p>
                        </div>
                        <div className="testLanguage yCenter gap-2">
                            <span style={{ fontSize: "0.9rem" }}><FaGlobe /></span>
                            <p className="m-0">Language: English</p>
                        </div>
                    </div>
                    :
                    <div style={{ width: "280px" }}><TileShimmer /></div>
                }
            </nav>
        </div>
        <div className="col-lg-9 pe-md-5">

            <h2 className="m-0 my-3 text-center text-sm-start">Test: {questions && questions.length > 0 ? questions[0].test : ""}</h2>

            <div id='result' className="row mt-4 mt-sm-5" style={{rowGap: "3rem"}}>
                <div className="col-sm-8">
                    <div className="shadow">
                        <div className="p-3 bg-light border-bottom">
                            <h5 className="m-0" style={{color: "#cc0000"}}>Your Performance</h5>
                        </div>
                        <div className="row">
                            <div className="col-lg-8 questionDetails">
                                <div className="p-3 border-bottom border-end row m-0" style={{fontSize: "1.1rem"}}>
                                    <div className="col-8 px-0 m-0">Right : </div>
                                    <div className="col-4 px-0 m-0 text-muted">
                                            <span className="stats" style={{color: "#cc0000"}}>
                                            {
                                                (selfDetails) ? selfDetails.reduce((count, question) => {
                                                    return question.correct === question.choosen ? count+1 : count;
                                                }, 0): ""
                                            } 
                                            </span>
                                        /{questions.length}
                                    </div>
                                </div>
                                <div className="p-3 border-bottom border-end row m-0" style={{fontSize: "1.1rem"}}>
                                    <div className="col-8 px-0 m-0">Wrong: </div>
                                    <div className="col-4 px-0 m-0 text-muted">
                                            <span className="stats" style={{color: "#cc0000"}}>
                                            {
                                                (selfDetails) ? selfDetails.reduce((count, question) => {
                                                    return question.choosen !== "" && question.correct !== question.choosen ? count+1 : count;
                                                }, 0): ""
                                            }
                                            </span>
                                        /{questions.length}
                                    </div>
                                </div>
                                <div className="p-3 border-bottom border-end row m-0" style={{fontSize: "1.1rem"}}>
                                    <div className="col-8 px-0 m-0">Un Attempted : </div>
                                    <div className="col-4 px-0 m-0 text-muted">
                                            <span className="stats" style={{color: "#cc0000"}}>
                                            {
                                                (selfDetails) ? selfDetails.reduce((count, question) => {
                                                    return question.choosen === "" ? count+1 : count;
                                                }, 0): ""
                                            }
                                            </span>
                                        /{questions.length}
                                    </div>
                                </div>
                                <div className="p-3 border-bottom border-end row m-0" style={{fontSize: "1.1rem"}}>
                                    <div className="col-8 px-0 m-0">Total Marks: </div>
                                    <div className="col-4 px-0 m-0 text-muted">
                                            <span className="stats" style={{color: "#cc0000"}}>
                                            {
                                                self ? self.marks : ""
                                            }
                                            </span>
                                        /{totalMarks}
                                    </div>
                                </div>
                                <div className="p-3 border-bottom border-end row m-0" style={{fontSize: "1.1rem"}}>
                                    <div className="col-8 px-0 m-0">Average Time: </div>
                                    <div className="col-4 px-0 m-0 text-muted">
                                        <span className="stats" style={{color: "#cc0000"}}>
                                        {self ? self.avgTime : ""} seconds
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="h-100 allCenter py-3 gap-3">
                                <div className="marksPercentage rounded-circle p-4" style={{background: `conic-gradient(#198754 0% ${self ? (self.marks/totalMarks)*100 : 0}%,#d6e0f5 ${self ? (self.marks/totalMarks)*100 : 0}% ${100}%,#4ac493 ${100}% 100%)`,}}>
                                        <div className='bg-white h-100 w-100 allCenter rounded-circle p-3' style={{minWidth: "70px", minHeight: "70px"}}>
                                            <p className="m-0 marksPercent h5">{self ? ((self.marks/totalMarks)*100).toFixed(0) : 0}%</p>
                                            {/* <p className="m-0 lighterText" style={{fontSize: "12px"}}>Marks Achieved!</p> */}
                                        </div>
                                    </div>
                                    <div className="rank">
                                        <p className="m-0 lighterText">Congrats!</p>
                                        <b className="m-0">You rank is {rank}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <button onClick={convertToPDF}>Download</button> */}
                </div>
                <div className="col-sm-4">
                    <div className="allCenter gap-2">
                        <h5 className="m-0">Top Performer</h5>
                        <div className="trophyImg" style={{width: "200px"}}><img src="http://s.dizzi.link/img/trophy.png" alt="Trophy-Loading-Error" className='w-100 h-100' /></div>
                        <div className='yCenter justify-content-center gap-2'>
                            <div className="profilePic m-0 rounded-circle allCenter fw500" style={{width: "40px", height: "40px", maxWidth: "40px", maxHeight: "40px", backgroundColor: "#ffc1c1"}}>{topper ? (topper.name).slice(0,1).toUpperCase() : ""}</div>
                            <div className="toppperNameMarks d-flex flex-column align-items-start justify-content-between fw500">
                                <p className="m-0">{topper ? topper.name : ""}</p>
                                <p className="m-0"><span className="toppersMarks" style={{color: "#cc0000"}}>{topper ? topper.marks : ""}</span> Marks</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <div className="col-lg-3">
            <div className="xCenter gap-4">

                {data.rightPanelData.formData ? 
                    <div className="rightPanelForm rounded-4 border p-4 p-sm-5 p-md-3 p-lg-4">
                        <div className="xCenter text-center gap-3">
                            <h5 className="m-0" style={{color: "#6c757d"}}>{data.rightPanelData.formData.subTitle}</h5>
                            <h5 className="m-0">{data.rightPanelData.formData.title}</h5>
                            <div className="mt-2">
                                <Formik initialValues={{
                                    name: "",
                                    number: "",
                                    course: "",
                                    bank: "",
                                    submit: data.rightPanelData.formData.submitBtnText
                                }}>
                                    <Form>
                                        <div className="xCenter gap-3">
                                            <Field type="text" name="name" placeholder="Enter your name" className="form-control" />
                                            <Field type="number" name="number" placeholder="Enter your number" className="form-control" />
                                            <Field component="select" name="course" className="form-control">
                                                <option value="course">Course</option>
                                                <option value="JAIIB">JAIIB</option>
                                                <option value="CAIIB">CAIIB</option>
                                                <option value="PPC">Promotions</option>
                                                <option value="SSM">Certification</option>
                                            </Field>
                                            <div>
                                                <Field type="text" name="bank" list="banks" placeholder="Enter your Bank" className="form-control" />
                                                <datalist id="banks">
                                                    <option value="Bank of Badoda" />
                                                    <option value="Punjab National Bank" />
                                                    <option value="HDFC Bank" />
                                                    <option value="ICICI Bank" />
                                                    <option value="State Bank of India" />
                                                </datalist>
                                            </div>
                                            <Field type="submit" name="submit" className="form-control text-white fw500 mt-2" style={{ backgroundColor: "#cc0000" }} />
                                        </div>
                                    </Form>
                                </Formik>
                            </div>
                        </div>
                        
                    </div> : <TileShimmer />
                }

                {/* Courses for Mobile  */}
                <div className="postersSlider rightPanelCourses d-md-none">
                    <Splide options={{
                        type: "loop",
                        autoplay: true,
                        interval: 2500,
                        arrows: false,
                    }} aria-label='Banners'>
                        {courses.courses ? (
                            <>
                                {courses.courses.map((course, index) => {
                                    if(course) {
                                        return(
                                            <SplideSlide key={"course"+index}>
                                                <Link to={course.target} target='_blank'>
                                                    <div className="border coursePoster position-relative w-100">
                                                        <div className="posterImg"><img src={course.poster} alt="Poster-Loading-Error" className='w-100 h-100' /></div>
                                                        <div className="position-absolute" style={{bottom: "5%", left: "5%"}}>
                                                            <button className="exploreBtn rounded-3 text-white text-nowrap transition">
                                                                {course.btnText}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </SplideSlide>
                                        )
                                    } else { return <TileShimmer />}
                                })}
                            </>
                        ) : <TileShimmer />}
                    </Splide>
                </div>
            </div>
        </div>
      </div>

      {/* Courses for Desktop  */}
      <div className='rightPanelCourses d-none d-md-block mt-5'>
        <h2 className="m-0 mb-4 h3">{courses.title}</h2>
        <div className="row" style={{rowGap: "1.5rem"}}>
            {courses.courses ? (
                <>
                    {courses.courses.map((course, index) => {
                        if(course) {
                            return(
                                <Link key={"course"+index} to={course.target} target='_blank' className="col-md-6 col-lg-3">
                                    <div className="shadow coursePoster position-relative w-100">
                                        <div className="posterImg"><img src={course.poster} alt="Poster-Loading-Error" className='w-100 h-100' /></div>
                                        <div className="position-absolute" style={{bottom: "5%", left: "5%"}}>
                                            <button className="exploreBtn rounded-3 text-white text-nowrap transition">
                                                {course.btnText}
                                            </button>
                                        </div>
                                    </div>
                                </Link>
                            )
                        } else { return <TileShimmer />}
                    })}
                </>
            ) : <TileShimmer />}
        </div>
      </div>
    </div>
  )
}

export default SplashResult
