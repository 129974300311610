import React, { useEffect, useState } from 'react'
import { _getCookie, fetchParticipant } from '../../functions';

function EnterTestForm({ data,test_id,handleDetails,setCandidateRef}) {
    let [isTermsChecked, setTermsChecked] = useState(true);
    const cookieStored =  _getCookie("dizziLinkTestDeatils");
    const user = cookieStored ? JSON.parse(cookieStored) : {};
    const formFields = Object.keys(data.formData.fields);
    const [details,setDetails] = useState(() => {
        const initialState = {course: "JAIIB"};
        formFields.forEach(key => {
            let value = "";
            if (Object.hasOwnProperty.call(user, key)) {
                value = user[key];
            }
            initialState[key] = value;
        });
        return initialState;
    });

    useEffect(() => {
        const getData = async ()=>{
            if(details.mobile){
                const candidate = await fetchParticipant(test_id,details.mobile);
                if(candidate){
                    setCandidateRef(candidate);
                }
            }
        }
        getData();
    }, [details])

    const handleInput = (key,value)=>{
        const newDetails =  details;
        newDetails[key] = value;
        setDetails({...newDetails});
    }
    return (
        <form onSubmit={() => {handleDetails(details)}}>
            <div className="row" style={{ rowGap: "1rem" }}>
                {Object.keys(data.formData.fields).map((field, index) => {
                    return (
                        <div key={"field" + index} className="col-12">
                            <input value={details[field]} type="text" name={field} id={field} placeholder={data.formData.fields[field].name} className="form-control" required={data.formData.fields[field].required} onChange={(e) => handleInput(field,e.target.value)} />
                        </div>
                    )
                })}
                <div className="col-12">
                    <select component="select" name="course" id="course" className="form-control" onChange={(e) => { details.course = e.target.value }}>
                        <option value="JAIIB">JAIIB</option>
                        <option value="CAIIB">CAIIB</option>
                    </select>
                </div>
                <div className="col-12">
                    <div className="d-flex gap-2">
                        <input type="checkbox" name="terms" id="terms" checked={isTermsChecked} className="mt-2" style={{ width: "fit-content", height: "fit-content" }} onChange={(e) => { setTermsChecked(!isTermsChecked) }} />
                        <label htmlFor='terms' className="m-0" dangerouslySetInnerHTML={{ __html: data.formData.termsConditionsText }} />
                    </div>
                </div>
                <div className="col-md-4 col-lg-4">
                    <input type="submit" name="submit" id="submit" value={data.formData.submitBtnText} className="btn rounded-1 text-white fw500 form-control" style={{ backgroundColor: "#cc0000" }} disabled={isTermsChecked ? false : true} />
                </div>
            </div>
        </form>
    )
}

export default EnterTestForm
