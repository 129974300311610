import React from 'react'
import UserProfile from '../components/userProfile/UserProfile'
import SocialLinks from '../components/socialLinks/SocialLinks'
import LinkTabs from '../components/Tabs Data/LinkTabs'

function Profile() {
  return (
    <div className='container my-4'>
      <div className="row py-4" style={{rowGap: "3rem"}}>

        <div className="col-md-5">
          <div className="xCenter gap-3 pe-0 pe-md-4">
            <UserProfile />
          </div>
        </div>

        <div className="col-md-7">
          <div className="xCenter gap-3">
            <LinkTabs />
            <SocialLinks />
          </div>
        </div>

      </div>

      <div className="row linksTable">

      </div>
    </div>
  )
}

export default Profile
