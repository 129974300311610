import React, { useEffect, useMemo, useRef, useState } from 'react'
import { _getCookie, addResponse, fetchQuestions, fetchTest, handleParticipantAction, parseCsv, stopTest, userInfoUpdate } from '../functions';
import { Navigate, useParams } from 'react-router-dom';
import QuestionPalette from '../components/quiz/QuestionPalette';
import "./Test.css"
import QuestionPreview from '../components/quiz/QuestionPreview';
import QuestionShimmer from '../components/quiz/QuestionShimmer';
import QuestionDescription from '../components/quiz/QuestionDescription';
import { io } from 'socket.io-client';
import CandidateWatingRoom from '../components/quiz/CandidateWatingRoom';
import LiveChat from '../components/quiz/LiveChat';

function TakeTest() {
    const { test_id } = useParams();
    const [isLive, setIsLive] = useState(false);
    const [showResult,setShowResult] = useState(false);
    const [test, setTest] = useState();
    const candidateRef = useRef();
    const [readyToFire,setReadyToFire] = useState();

    // live chat messages
    const allMessagesRef  = useRef([]);
    const [newMessage,setNewMessage] = useState();


    // questions
    const activeQuestionsRef = useRef();
    const [questions, setQuestions] = useState([]);
    const [previewRefresh, setPreviewRefresh] = useState(0);
    const [currentPreview, setCurrentPreview] = useState();
    const [description,setDescription] = useState(false);

    // socket 
    const socketRef = useRef(null);

   const setCandidateRef = (current)=>{
        candidateRef.current = current;
   }
   
    const handleEnterRoom = (candidate)=>{
        if(!candidateRef.current){
            candidateRef.current = {name: candidate.name,mobile:candidate.mobile,"socket_id" : socketRef.current.id,"marks" : 0,"avgTime" : 0,"analytics": JSON.stringify(activeQuestionsRef.current.map((que)=>{
                return {
                    _id: que._id,
                    correct : que.correct,
                    marks : que.marks,
                    time : que.time,
                    choosen : "",
                    timeTaken : "",
                    result : "",
                    rank : "",
                }
            }))};
        }
        setReadyToFire(true);
        socketRef.current.emit('takeLiveTest',test_id);
        // add candidate response data to reponses againt route
        addResponse(test.route,candidate);
    }


    const handleLiveTestStarted = ()=>{
        if(candidateRef.current){
            setIsLive(true);
            socketRef.current.emit('joinedTest',{
                test_id,candidateName :candidateRef.current.name, marks: candidateRef.current.marks
            });
           
            userInfoUpdate(test_id,candidateRef.current)
            
        }
    }

    const handleStopTest = async ()=>{
        setIsLive(false);
        setShowResult(true);
    }

    // messages 
    const setAllMessagesRef = (current)=>{
        allMessagesRef.current = current;
    }

    const broadcastMessage = (message)=>{
        socketRef.current.emit("broadcastMessage",message);
    }


    const previewQuestion = (id) => {
        if(currentPreview){
            // console.log(currentPreview);
            if(checkAnswerReveal(currentPreview._id)){return }
        }
        setDescription();
        setCurrentPreview();
        if(checkQuestionActive(id)){
            const que = activeQuestionsRef.current.filter(question=> question._id === id);
            setCurrentPreview(...que);
        }
        
    }
    const showDescription = ()=>{
        if(!checkAnswerReveal(currentPreview._id)){
            setPreviewRefresh("show Description");
            setDescription(!description)
        }
    }

    const checkQuestionActive = (id) => {
        const que = activeQuestionsRef.current.filter((que) => { return que._id === id });
        return que[0].active;
    }
    const makeQuestionActive = (id) => {
        if(activeQuestionsRef.current){
            const ques = activeQuestionsRef.current.map(que => que._id === id ? { ...que, active: true } : que);
            return activeQuestionsRef.current = ques;
        }
    }
    const setAnswerReveal = (id)=>{
        // Save Revealed Question Answers
        const ques = activeQuestionsRef.current.map(que=> que._id === id ? {...que,ansReveal:true} : que);
        activeQuestionsRef.current = ques;
        setPreviewRefresh(id+"answer reveal");
        return true;
    }
    const checkAnswerReveal =(id)=>{
        const que = activeQuestionsRef.current.filter((que)=>{return que._id === id});
        return que[0].active && !que[0].ansReveal;
    }

    const setOptionsReveal = (id)=>{
        // Save Revealed Question Answers
        const ques = activeQuestionsRef.current.map(que=> que._id === id ? {...que,optionsReveal:true} : que);
        activeQuestionsRef.current = ques;
        setPreviewRefresh(id+"options reveal");
        return true;
    }
    const checkOptionsReveal =(id)=>{
        const que = activeQuestionsRef.current.filter((que)=>{return que._id === id});
        return que[0].optionsReveal;
    }

    const submitAnswer = async(qid,choosen,timeRemaining)=>{
        // send analytics to socket 
        socketRef.current.emit('sendAnswerAnalytics',{
            socket_id : socketRef.current.id,qid,name:candidateRef.current.name,choosen,timeRemaining
        });
        // Save Revealed Question Answers
        // -> Use Ref For Current Session
        let analytics = JSON.parse(candidateRef.current.analytics);
        analytics = analytics.map(que=> que._id === qid ? {...que,choosen : choosen,timeTaken: currentPreview.time - timeRemaining} : que);
        const averageTime = (parseFloat(candidateRef.current.avgTime)+ parseFloat(currentPreview.time - timeRemaining))/2;
        candidateRef.current = {...candidateRef.current, socket_id:socketRef.current.id, avgTime:averageTime.toFixed(2),analytics : JSON.stringify(analytics),marks : choosen === currentPreview.correct ? parseFloat(candidateRef.current.marks) + parseFloat(currentPreview.marks) : candidateRef.current.marks};
        console.log(candidateRef.current);
        // -> Api Call
        await handleParticipantAction(test_id,candidateRef.current);
    }

    const checkAnswerGiven = (id)=>{
        const  analytics = JSON.parse(candidateRef.current.analytics);
        console.log("analytic is ",analytics);
        const choosenOption = analytics.filter(que=> que._id === id);
        console.log("ans array is  ",choosenOption);
        return choosenOption[0].choosen
    }

    const handleAnswerAnalytics = (data)=>{
        if(data.socket_id !== socketRef.current.id){
            const now = new Date();
            setNewMessage({sender : data.name,message : `Answered Option ${data.choosen} for Q.${data.qid}`,time:now})
        }
    }
    const handleMessageReceived = (message)=>{
        setNewMessage(message);
    }



    // Get File and Details of Quiz
    useEffect(() => {
        const getData = async () => {
            
            const data = await fetchTest(test_id);
            setTest(data);
            let ques = await fetchQuestions(test_id);
            if(ques.length === 0){
                ques = await parseCsv(data.file);
            }
            setQuestions(ques);
            activeQuestionsRef.current = ques;



            // if(!candidateRef.current && test_id === _getCookie("userJoinedTestId")){
            //     console.log(document.cookie);
            //     candidateRef.current.name = _getCookie("userJoinedName");
            //     setUserName(_getCookie("userJoinedName"));
            //     handleEnterRoom();
            //     if(data.isLive === '1'){
            //         const activeQues = ques.filter(que =>que.active == true);
            //         // setIsLive(true);
            //         const activeQue = activeQues[activeQues.length -1];
            //         console.log(activeQue);
            //         makeQuestionActive(activeQue._id);
            //         previewQuestion(activeQue._id);
            //         socketRef.current.emit('takeLiveTest',test_id);
            //     }
            // }
        }
        if (!socketRef.current) {
            socketRef.current = io("https://graceful-florina-learningsessions.koyeb.app",{query: {testId: test_id}});
            socketRef.current.on('connect', () => {
                console.log('Connected to the server');
                socketRef.current.emit('takeLiveTest',test_id)
            });
            
            socketRef.current.on('joinLiveTest', (data) => {
                console.log('Test statred from admin:', data);
                socketRef.current.emit('takeLiveTest',test_id);
            });
            socketRef.current.on('liveTestStarted', (data) => {
                console.log("test started");
                handleLiveTestStarted();
            });
            socketRef.current.on('checkActiveQuestion', (id) => {
                console.log('active by admin:', id);
                makeQuestionActive(id);
                previewQuestion(id);
            });
            socketRef.current.on('checkOptionReveal', (id) => {
                console.log('options by admin:', id);
                setOptionsReveal(id)
            });
            socketRef.current.on('checkAnswerReveal', (id) => {
                setAnswerReveal(id);
            });
            socketRef.current.on('fetchAnswerAnalytics', (data) => {
                handleAnswerAnalytics(data);
            });
            socketRef.current.on('receiveMessage', async (message) => {
                console.log("message received form ",message);
                handleMessageReceived(message)
            });
            socketRef.current.on('stopLiveTest', async (data) => {
                console.log("live test Stoped ",data);
                handleStopTest();
            });
            socketRef.current.on('liveTestNotStarted', (data) => {
                console.log('Test not statred:', data);
                setIsLive(false)
            });
        }
        getData();
       

    }, [isLive,readyToFire]);

    // use Memo Question Preview
    const questionPreviewComponent = useMemo(() => {
        return (
            <QuestionPreview 
                refreshMe={previewRefresh}
                showDesc={showDescription} 
                checkQuestionActive={checkQuestionActive} 
                checkAnswerReveal={checkAnswerReveal} 
                checkOptionsReveal={checkOptionsReveal} 
                question={{...currentPreview}} 
                isAdminSide={false}  
                submitAnswer={submitAnswer}
                checkAnswerGiven={checkAnswerGiven}
            />
        );
    }, [previewRefresh,currentPreview])

    
    if(!isLive){
        if(showResult){
            return  <Navigate to={`/liveTestResult/${test_id}/${candidateRef.current.mobile}`}/>
        }
        return (
            <CandidateWatingRoom
                test_id={test_id} 
                setCandidateRef={setCandidateRef} 
                isLive={isLive} testName={(questions.length>0) ? questions[0].test : "Test Here"} 
                totalMarks={
                    (questions && questions.length>0) ? questions.reduce((sum, question) => {
                        const marks = parseFloat(question.marks);
                        return !isNaN(marks) ? sum + marks : sum;
                    }, 0): ""
                } 
                readyToFire={readyToFire} 
                handleEnterRoom={handleEnterRoom}/>
        )
    }
    return (
        <div className="container-lg">
            <div className="row row-gap-5 questionDetails detailsTile bg-white sticky-top">
                <div className="col-12 sticky-top">
                        <div className="testInformation gap-2 gap-sm-3 d-flex justify-content-between flex-wrap">
                            <div className="testLogo">
                                <a className="mb-1 portalLogo d-block" style={{width: "140px"}}><img className="w-100 h-100" src="https://learningsessions.in/wp-content/uploads/2020/10/logo_black_retina-300x99.png" alt="logo"/></a>
                            </div>
                            <h3 className='m-0 fw-bold d-none d-sm-block'>{(questions.length>0) ? questions[0].test : "Test Name Will Refect Here" }</h3>
                            <h4 className='mb-1 mobileCourseTitle fw-bold mx-auto d-block d-sm-none'>{(questions.length>0) ? questions[0].test : "Test Name Will Refect Here" }</h4>
                            <p className="totalMarks m-0 fw-bold fs-5 d-none d-sm-block">
                                {(questions) ? questions.reduce((sum, question) => {const marks = parseFloat(question.marks);return !isNaN(marks) ? sum + marks : sum;}, 0): ""} Marks
                            </p>
                        </div>
                </div>
                <hr />
                <div className="col">
                    <QuestionPalette refreshMe={previewRefresh} questions={questions} previewQuestion={previewQuestion} currentPreview={currentPreview} checkQuestionActive={checkQuestionActive} checkAnswerReveal={checkAnswerReveal} />
                </div>
            </div>
            <div className="row my-3 gap-4 gap-md-0">
                <div className="col-md-5 col-lg-6 order-md-1">
                    <div className="liveVideo widget p-0 p-sm-3">
                        <h6 className="text-muted d-none d-sm-block">Live Video</h6>
                        <div className="liveVideoControl">
                            <iframe className='liveVideo' width="550" height="275" src={`https://www.youtube.com/embed/${(test && test.youtubeVideo) ? test.youtubeVideo : "u9VFgLKpTUI"}?autoplay=1`} title="Live Video" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" playing="true" autoPlay={true} allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
                <div className="col-md-7 col-lg-6 order-md-0">
                    <div className="widget controlpanel">
                        <img id='questionWatermark' src="https://learningsessions.in/wp-content/uploads/2020/10/logo_black_retina-300x99.png" alt="" srcSet="" />
                        <div className="topHeading mb-3">
                            <h6 className="text-muted">Question Panel</h6>
                        </div>
                        {
                            (currentPreview) ?
                                questionPreviewComponent
                                :
                                <>
                                    <h5 className="text-muted mt-3">Question Will Be Visible Here</h5>
                                    <QuestionShimmer />
                                </>
                        }
                    </div>
                </div>
                
            </div>
            {
                (description) ?
                    <div className="row">
                        <div className="col">
                            <div className="questionDescription widget mb-3">
                                <QuestionDescription description={currentPreview.description} />
                            </div>
                        </div>
                    </div>
                    : <></>
            }
            <div className="row ">
                <div className="col">
                    <div className="widget">
                        <LiveChat candidate={candidateRef.current} allMessagesRef={allMessagesRef.current} setAllMessagesRef={setAllMessagesRef} message={newMessage} broadcastMessage={broadcastMessage} isAdminSide={false}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TakeTest