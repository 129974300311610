import React, { useEffect, useState } from 'react'
// import { DataTable } from 'simple-datatables';
import 'simple-datatables/dist/style.css';
import { copyOnClick, generateQr, getLinkHistory, handleDeleteRoute, handleEditRoute } from '../../functions';
import { AiFillCheckCircle, AiFillEye, AiOutlineDelete } from 'react-icons/ai';
import { BiEditAlt } from 'react-icons/bi';
import { MdQuiz } from 'react-icons/md';
import { DiGoogleAnalytics } from 'react-icons/di';
import { Link } from 'react-router-dom';
import { HiOutlineFolderDownload } from 'react-icons/hi';
import ConfirmPopup from '../popups/ConfirmPopup';
import UrlEditPopup from '../popups/UrlEditPopup';
import { FaRegCopy, FaWpforms } from 'react-icons/fa';

function RouteHistory({refreshHistory}) {

    const [linkHistory,setLinkHistory] = useState([]);
    const [copyAlert,setCopyAlert] = useState([]);
    // const [viewRoute,setViewRoute] = useState();
    const [editRoute,setEditRoute] = useState();
    const [deleteRoute,setDeleteRoute] = useState();
    const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
    };
    const handleCopyAlert = (index)=>{
        setCopyAlert(copyAlert.map(alert=> alert.index === index ? {...alert,isCopied : true}:alert))
        setTimeout(()=>{
            setCopyAlert(copyAlert.map(alert=> alert.index === index ? {...alert,isCopied : false}:alert))
        },500);

    }
    const handleDelRoute = async (route)=>{
        setLinkHistory(linkHistory.filter(link=> link.route !== route));
        await handleDeleteRoute(route);
    }
    const handleRouteEdit = async (route,url)=>{
        setLinkHistory(linkHistory.map(link=> link.route === route ? {...link,url:url} : link));
        await handleEditRoute(route,url);
    }


    const handleQrDownload = (route)=>{
        generateQr("https://dizzi.link/"+route);
        const canvas = document.getElementById("qrWrapper").querySelector('canvas');
        if (canvas) {
            const url = canvas
                .toDataURL("image/png")
                .replace("image/png", "image/octet-stream");
            let downloadLink = document.createElement("a");
            downloadLink.href = url;
            downloadLink.download = `dizzi_qr.png`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
    }
    useEffect(()=>{
        const getData =  async ()=>{
            const history = await getLinkHistory();
            setLinkHistory(history)
            // console.log(history);
        }
        getData();
    },[refreshHistory])
  return (
    <div className='container-lg'>
        <ConfirmPopup handleDelete={handleDelRoute} deleteParams={deleteRoute} />
        <UrlEditPopup handleSave={handleRouteEdit} saveParams={editRoute} />
        <h2 className='titleFont'>History</h2>
        <div className='d-none' id="qrWrapper"></div>
        <div className="row">
            <div className="col">
                <div className="table-responsive">
                    <table id="routeHistoryTable" className='table table-bordered table-striped'>
                        <thead>
                            <tr>
                                <th width="" >Sr No</th>
                                <th width="50%" >Link</th>
                                <th width="" >Shorten Url</th>
                                <th width="20%" >Actions</th>
                                {/* <th width="" >When?</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                linkHistory.map((link,index)=>{
                                    const copyAlerts = copyAlert;
                                    copyAlerts.push({
                                        index,
                                        isCopied : false
                                    });
                                    const dateObj = new Date(link.createdAt);
                                    return (
                                        <tr key={"historyRow"+index}>
                                            <td>{index+1}</td>
                                            <td style={{wordBreak:"break-all"}}>
                                                <div className="d-flex justify-content-between align-items-center gap-2">
                                                    <p className="m-0">{(link.url).substr(0,50)+"..."}</p>
                                                    <button onClick={()=>{handleCopyAlert(index);copyOnClick(link.url)}} className='btn text-primary p-0'>
                                                        {
                                                            (copyAlert[index].isCopied === true) ? <AiFillCheckCircle size={20} /> : <FaRegCopy size={20} />
                                                        }
                                                    </button>
                                                </div>
                                                <span>{dateObj.toLocaleString("en-US", options)}</span>
                                            </td>
                                            <td>
                                                <div className="d-flex justify-content-between align-items-center gap-2">
                                                    <a  rel="noreferrer" href={"https://dizzi.link/"+link.route} target="_blank">{"https://dizzi.link/"+link.route}</a>
                                                    <div className="routeAction d-flex">
                                                        <button onClick={()=>{handleCopyAlert(index);copyOnClick(`https://dizzi.link/${link.route}`)}} className='btn text-primary p-0 m-1 fs-3'>
                                                            {
                                                                (copyAlert[index].isCopied === true) ? <AiFillCheckCircle size={20} /> : <FaRegCopy size={20} />
                                                            }
                                                        </button>
                                                        <button onClick={()=>handleQrDownload(`${link.route}`)} className='btn text-primary p-0 m-1 fs-3'><HiOutlineFolderDownload size={20}/></button>
                                                        <Link to={`/analytics/${link.route}`} className='btn text-primary p-0 m-1 fs-3'><DiGoogleAnalytics size={20} /></Link> 
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                {
                                                    (link.uid === "1" || link.uid === "0" ||  link.type === "quiz") ? <></> : <button onClick={()=>setEditRoute(`${link.route}`)} data-bs-target="#urlEditPopup" data-bs-toggle="modal" className='btn text-primary p-0 m-1 fs-3'><BiEditAlt size={25} /></button>
                                                }
                                                {
                                                    (link.type === "prompt" || link.type === "customPrompt" || link.type === "quiz") ? 
                                                    <>
                                                        <Link to={`/responses/${link.route}`} className='btn text-primary p-0 m-1 fs-3'><AiFillEye size={25} /></Link> 
                                                        <Link to={`/editForm/${link.route}`} className='btn text-primary p-0 m-1 fs-3'><FaWpforms size={25} /></Link> 
                                                    </>
                                                    : <></>
                                                }
                                                {
                                                    (link.type === "quiz") ? 
                                                    <>
                                                        <Link to={`/hostTest/${link.url}?Route=${link.route}`} className='btn text-primary p-0 m-1 fs-3'><MdQuiz size={25} /></Link> 
                                                    </>
                                                    : <></>
                                                }
                                                <button onClick={()=>setDeleteRoute(`${link.route}`)} data-bs-target="#confirmPopup" data-bs-toggle="modal" className='btn text-primary p-0 m-1 fs-3'><AiOutlineDelete color="#cc0000" size={25}/></button>
                                            </td>
                                            {/* <td>
                                                {dateObj.toLocaleString("en-US", options)}
                                            </td> */}
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
  )
}

export default RouteHistory