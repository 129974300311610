import axios from "axios";
const verifyTemplate = "DIZZILINKLIVETEST DO NOT COPY, FORWARD OR SHARE THIS MESSAGE ";
const Api = "https://live-server-106478.wati.io/";
const accessKey = "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI0ZWI5YzA3OS0zZWI5LTQzNDMtYWM4Ni0wODFkNWExNzM5NWYiLCJ1bmlxdWVfbmFtZSI6ImxzZG0uY2hldHJhbUBnbWFpbC5jb20iLCJuYW1laWQiOiJsc2RtLmNoZXRyYW1AZ21haWwuY29tIiwiZW1haWwiOiJsc2RtLmNoZXRyYW1AZ21haWwuY29tIiwiYXV0aF90aW1lIjoiMTAvMjYvMjAyMyAxMDo1Njo0NiIsImRiX25hbWUiOiIxMDY0NzgiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJERVZFTE9QRVIiLCJleHAiOjI1MzQwMjMwMDgwMCwiaXNzIjoiQ2xhcmVfQUkiLCJhdWQiOiJDbGFyZV9BSSJ9.csmSfU-iuF4V9U_ySngNOG0q0XRyMbmiToT2ajMiLvY";


const verifyNumber = async (number, code) => {

    const messages = await getWatiMessages(number);
    for (let i = 0; i < messages.length; i++) {
        if(checkDateForToday(messages[i].created)){
            if(messages[i].text && verifyCode(messages[i].text, code)){
                return true;
            }
        }else{
            return false;
        }
    }
}


const getWatiMessages = async (number) => {
    try {
        const response = await axios.get(`${Api}api/v1/getMessages/${number}`, {
            headers: { Authorization: accessKey }
        })
        if (response.data.result === "success") {
            return response.data.messages.items;
        }
        return [];
    } catch (error) {
        console.error("Error fetching data:", error);
    }
}

const verifyCode = (message, code) => {
    console.log(message);
    console.log(message.replace(verifyTemplate, ""));
    return message.replace(verifyTemplate, "") === code;
}

const checkDateForToday = (timestampISO) => {
    const timestamp = new Date(timestampISO);
    const indianTimeZone = 'Asia/Kolkata';
    const formatter = new Intl.DateTimeFormat('en-US', {
        timeZone: indianTimeZone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });
    const indianDate = formatter.format(timestamp);
    const currentDate = formatter.format(new Date());
    return indianDate === currentDate;
}

export { verifyNumber }