import React from 'react'
import Feature from './Feature'
import PostShimmer from '../shimmer/PostShimmer'
import TileShimmer from '../shimmer/TileShimmer'

function Features({data}) {
  return (
    <div id='Services' className='container-fluid py-5'>
      <div className="wrapper xCenter gap-2 gap-md-4">
        <div className="top allCenter gap-2 gap-md-3 px-2 px-md-5 mx-2 mx-md-5">
            {(data.heading === null) ? 
                <TileShimmer/>
            : 
                <>
                    <h2 className='h1 m-0 fw-semibold' dangerouslySetInnerHTML={{__html: data.heading}}/>
                    <p className='textSecondary fs-5'>{data.subHeading}</p>
                </>
            }
        </div>
        <div className="bottom d-flex flex-wrap flex-md-nowrap justify-content-evenly gap-3 gap-md-5 px-2 px-md-5 mx-2 mx-md-5">
            {data.cards.map((card,index)=> {
                return(
                    (card === null) ?
                        <PostShimmer key={"featureCard"+index} /> 
                    :
                        <Feature key={"featureCard"+index} card={card} index={index} />
                )
            })}
        </div>
      </div>
    </div>
  )
}

export default Features
