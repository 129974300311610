import React from 'react'
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

function PrivateRoute({element,navigateTo,isTrue}) {
    const { isAuthenticated } = useAuth();

    const isLogedIn = localStorage.getItem('token');

    if(isTrue){
        return (
          (isLogedIn || isAuthenticated) ? (<>{element}</>) : (<Navigate to={navigateTo}/>)
        )
    }else{
        return (
          (!isLogedIn || !isAuthenticated) ? (<>{element}</>) : (<Navigate to={navigateTo}/>)
        )
    }
}

export default PrivateRoute