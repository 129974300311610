const defaultPromptForm = {
    "companyAttributes": {
        "name": "",
        "description": "",
        "logo": "",
        "address": ""
    },
    "formAttributes": {
        "name": {
            "name": "Full Name",
            "added": true,
            "required": true
        },
        "email": {
            "name": "Email",
            "added": false,
            "required": false
        },
        "mobile": {
            "name": "Mobile",
            "added": true,
            "required": true
        },
        "message": {
            "name": "Message",
            "added": true,
            "required": false
        },
        "gender": {
            "name": "Gender",
            "added": false,
            "required": false
        },
        "age": {
            "name": "Age",
            "added": false,
            "required": false
        },
        "dob": {
            "name": "Date of Birth",
            "added": false,
            "required": false
        },
        "qualification": {
            "name": "Qualification",
            "added": false,
            "required": false
        },
        "jobTitle": {
            "name": "Job Title",
            "added": false,
            "required": false
        }
    },
    "uiAttributes": {
        "isSolidActive": false,
        "solidColor1": "#cd1a1a",
        "solidColor2": "#ffa200",
        "textColor": "#ffffff",
        "inputColor": "#ffefd4",
        "inputIconColor": "#cd1a1a"
    }
}


export {defaultPromptForm}