import React from 'react'
import { BiDetail } from 'react-icons/bi'

function QuestionDescription({description}) {
  return (
    <div className="queDescription">
        <p className="fs-4 fw-bold mb-1"><BiDetail className='me-2'/>Description</p>
        <p className="">{description}</p>
    </div>
  )
}

export default QuestionDescription