import React, { useEffect, useState } from 'react'
import { checkAvailableRoute } from '../../functions'

function CustomUrlInput({isGenerated,handleCustomRoute}) {

  const [isAvailable,setIsAvailable] = useState();
  const checkRouteAvailbility = async ()=>{
    setIsAvailable("Fetching...")
    const route = document.getElementById('customUrlInput').value;
    if(route.length<3 || route.length>50){setIsAvailable("Not Allowed"); return;}
    // const pattern = /[^A-Za-z0-9\-._~:/?#[\]@!$&'()*+,;=%]/g;
    // if(!pattern.test(route)){setIsAvailable("Not Allowed"); return;}
    const available = await checkAvailableRoute(route);
    if(available === true){
      setIsAvailable("Available");
      handleCustomRoute(route);
    }else{
      setIsAvailable("Not Available")
    }
  }
  useEffect(() => {
    const removeEnteredRoute = ()=>{
      const input = document.getElementById('customUrlInput');
      input.value = "";
    }
    removeEnteredRoute();
  }, [isGenerated])
  
  return (
    <div className="para yCenter p-2 lighterBg justify-content-between rounded-2 sameWidthResponsive" style={{width: "650px"}}>
        <div className="wrapper text-primary rounded-2 p-2 yCenter justify-content-between" style={{ width: "98px" }} >
            <input type="text" className='bg-transparent fw500 text-primary' placeholder="Customize URL" value="dizzi.link/" disabled />
        </div>
        <div className="wrapper border rounded-2 p-2 yCenter bg-white justify-content-between smResponsiveAlias" style={{width: "80%"}}>
            <input id='customUrlInput' onInput={checkRouteAvailbility} type="text" className='bg-transparent' placeholder="Alias"/>
        </div>
        {
          (isAvailable && !isGenerated) ? <span className='ms-2 blinking-text fw-bold'>{isAvailable}</span> : <></>
        }
    </div>
  )
}

export default CustomUrlInput
