import React from 'react'
import { AiFillCheckCircle } from "react-icons/ai";
import "./AuthInformation.css"

function AuthInformation() {

  const data = {
    title: "Step into the future of communication. Join Infobip today.",
    points: [
      {
        title: "Try SMS, Email, WhatsApp, Viber, and more for free.",
        text: "Engage with your customers through the most popular communication channels in just a few clicks."
      },
      {
        title: "Get a free trial of Infobip's products",
        text: "Drive customer engagement, streamline your call centers, and build chatbots. No rush. No 7 day limit."
      },
      {
        title: "Explore Infobip APIs or a no-code web interface",
        text: "Discover the flexibility to integrate and manage communication channels effortlessly."
      },
    ],
    companiesData: {
      title: "You are at Good Place",
      companies: [
        {
          img: "https://s.dizzi.link/img/logo.png",
        },
        {
          img: "https://s.dizzi.link/img/logo.png",
        },
        {
          img: "https://s.dizzi.link/img/logo.png",
        },
        {
          img: "https://s.dizzi.link/img/logo.png",
        },
        {
          img: "https://s.dizzi.link/img/logo.png",
        },
        {
          img: "https://s.dizzi.link/img/logo.png",
        },
      ]
    }
  }

  if(data !== null) {
    return (
      <div className='SignupExtraContent px-md-5 py-md-5'>
        <div className="py-md-4 px-md-5 xCenter gap-5">

          <div className='xCenter gap-4 mt-3'>
            <h3 className="title" style={{fontSize: "1.6rem"}} dangerouslySetInnerHTML={{__html:data.title}} />
            {data.points !== null ? (
              <div className="points xCenter gap-4">

                {data.points.map((point, index) => {
                  return(
                    <div key={"point"+index} className="point d-flex align-items-start gap-3">
                      <span className="icon fs-4 d-flex align-items-start" style={{color: "var(--bgTheme)"}}><AiFillCheckCircle /></span>
                      <div className="textContent">
                        <div className="xCenter gap-2">
                          <h5 className="m-0" style={{fontSize: "1.143rem"}}>{point.title}</h5>
                          <p className="m-0 textSecondary">{point.text}</p>
                        </div>
                      </div>
                    </div>
                  )
                })}

              </div>
            ) : "" }

          </div>

            {data.companiesData !== null ? (
              <div className="companies xCenter gap-3">
                <p className="m-0 textSecondary">{data.companiesData.title}</p>
                <div className="yCenter  gap-md-4 flex-wrap justify-content-between">
                  
                  {data.companiesData.companies.map((company,index) => {
                    return(
                      <div key={"company"+index} className="companyLogo" style={{width: "128px"}}><img src={company.img} alt="company" className='w-100 h-100' /></div>
                    )
                  })}

                </div>
              </div>
            ) : ""}

        </div>
      </div>
    )
  }
}

export default AuthInformation
