import React from 'react'
import "./TopNav.css"
import { PiCaretDownBold } from 'react-icons/pi'
import { Link } from 'react-router-dom'

function TopNav() {

    const data = {
        navs: [
          {
            nav: "Direct URL", 
            path: "/dashboard",
          },
          {
            nav: "Custom URL",
            path: "/dashboard/custom",
          },
          {
            nav: "Prompt Url",
            path: "/dashboard/prompt",
          },
          {
            nav: "Prompt + Custom URL",
            path: "/dashboard/prompt-custom",
          },
          {
            nav: "Online Live Quiz",
            path: "/dashboard/online-quiz",
          },
        ]
    }

  return (
    <div className='container-fluid px-3 sticky-top allCenter' style={{backgroundColor: "var(--bannerBg)", zIndex: "9"}}>
      <div className="yCenter justify-content-evenly gap-2 gap-sm-3 gap-md-4 flex-wrap text-white">
        {data.navs.map((nav, index) => {
          return(
              <div key={"linkKey"+index} className="jump underlineEffect position-relative xCenter" style={{transition: "all 100ms ease"}}>
                
                <div className="link yCenter gap-2">
                  <Link to={nav.path} className='text-decoration-none text-white py-2'>{nav.nav}</Link>
                  {(nav.dropdown) ? (<span className="iconWrapper caret" style={{transition: "transform 700ms ease"}}><PiCaretDownBold /></span>) : ""}
                </div>
                  
                {(nav.dropdown) ? (
                    <div className='dropdown position-absolute start-0 d-flex flex-column overflow-hidden bgShadow' style={{width: "max-content", top: "calc(100%)", maxHeight: "0px", transition: "max-height 700ms ease-in-out"}}>
                      {nav.dropdown.map((dropLink, index) => {
                        return(
                            <Link key={"dropdown"+index} to={dropLink.path} className="text-decoration-none p-2 bg-white underlineEffect border-bottom">{dropLink.link}</Link>
                        )
                      })}
                    </div>
                ) : ""}

              </div>
          )
        })}
      </div>
    </div>
  )
}

export default TopNav
