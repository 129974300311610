import React, { useEffect, useState } from 'react'
import { getHead } from '../functions';
import Head from '../components/head/Head';
import HeroProtected from '../components/heroProtected/HeroProtected';
import RouteHistory from '../components/routeHistory/RouteHistory';

function Dashboard() {
  const [refreshHistory,setRefreshHistory] = useState(0);

  const handleHistory = ()=>{
    setRefreshHistory(refreshHistory+1);
  }
  

  const [heroProtectedData, setHeroProtectedData] = useState();
  
  useEffect(() => {
    const getHeadData = async () => {
      const headData = await getHead("heroProtected");
      setHeroProtectedData(headData);
    }

    getHeadData();

  }, [])
  return (
    <>
      <Head data={heroProtectedData} />
      <HeroProtected handleHistory={handleHistory}/>
      <RouteHistory refreshHistory={refreshHistory}/>
    </>
  )
}

export default Dashboard
