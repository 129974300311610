import React from 'react'

function EditUrl() {
  return (
    <div className='container'>
        <div className="col-sm-4">
            <div className="routeInformation">
                <h2>Route Information</h2>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro in voluptate eum repellendus corporis molestias deleniti deserunt optio sunt quas iusto cupiditate dicta ipsa commodi excepturi expedita minus mollitia </p>
            </div>
        </div>
        <div className="col-sm-8"></div>
    </div>
  )
}

export default EditUrl