import React from 'react'
import { SlCalender } from "react-icons/sl";
import { BiLinkExternal } from "react-icons/bi";

function LatestLinks() {

    const data = {
        title: "Latest Links",
        linksData: [
            {
                name: "dizzi.link/eusx",
                target: "https://youtube.com/",
                dateCreated: "Mar 25, 23",
            },
            {
                name: "dizzi.link/eusx",
                target: "https://youtube.com/",
                dateCreated: "Mar 25, 23",
            },
            {
                name: "dizzi.link/eusx",
                target: "https://youtube.com/",
                dateCreated: "Mar 25, 23",
            },
        ]
    }

  return (
    <div className='latestLinksData xCenter gap-3 lighterBg p-3 rounded-1'>

        <div className="titleContainer yCenter justify-content-between">
            <p className="title m-0 fw500">{data.title}</p>
            <a href="#LinksTable" className='text-decoration-none text-primary'>Show All</a>
        </div>

        <div className="links xCenter gap-2">

            {data.linksData.map((link, index) => {
                return(
                    <div key={"latestLink"+index} className="bg-white rounded-1 yCenter justify-content-between py-2 px-3 flex-wrap">
                        <p className="m-0">{link.name}</p>
                        <div className='yCenter gap-sm-4'>
                            <div className="dateCraetedWrapper yCenter gap-2">
                                <span className="iconWrapper" style={{fontSize: "15px"}}><SlCalender /></span>
                                <p className="m-0">{link.dateCreated}</p>
                            </div>
                            <a rel="noreferrer" href={link.target} target='_blank' className="iconWrapper text-primary"><BiLinkExternal /></a>
                        </div>
                    </div>
                )
            })}

        </div>
      
    </div>
  )
}

export default LatestLinks
