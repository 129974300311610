import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Navigate, useParams } from 'react-router-dom';
import { fetchQuestions, fetchTest, handleQuestionAction, liveTest, parseCsv, stopTest, verifyHostPassword } from '../functions';
import "./Test.css"
import Leaderboard from '../components/quiz/Leaderboard';
import QuestionStats from '../components/quiz/QuestionStats';
import { io } from 'socket.io-client';
import QuestionPreview from '../components/quiz/QuestionPreview';
import QuestionShimmer from '../components/quiz/QuestionShimmer';
import DetailBox from '../components/quiz/DetailBox';
import QuestionPalette from '../components/quiz/QuestionPalette';
import QuestionDescription from '../components/quiz/QuestionDescription';
import TopPerformer from '../components/quiz/TopPerformer';
import LiveChat from '../components/quiz/LiveChat';

function HostTest() {
    const {test_id} = useParams();
    const [isLive,setIsLive] = useState(false);
    const [showResult,setShowResult] = useState(false);
    const [test,setTest] = useState();
    const [totalMarks,setTotalMarks] = useState(100);
    const [isVerified,setIsVerified] = useState(false);
    const [hostPassword,setHostPassword] = useState("");

    // live chat view controll
    const allMessagesRef  = useRef([]);
    const [liveChatView,setLiveChatView] = useState(false);
    const [newMessage,setNewMessage] = useState();


    // questions
    const activeQuestionsRef = useRef(null);
    const [questions,setQuestions] = useState([]);
    const [currentPreview,setCurrentPreview] = useState();
    const [previewRefresh, setPreviewRefresh] = useState(0);
    const [description,setDescription] = useState(false);

    // Ans Stats
    // const [totalParticipents,setTotalParticipents] = useState(0);
    const [rightAnswered,setRightAnswered] = useState(0);
    const [wrongAnswered,setWrongAnswered] = useState(0);
    

    // Participants Top Performers
    const participantsRef = useRef([]);
    const [participants,setParticipants] = useState([]);
    // const [overallTopPerformer,setOverallTopPerformer] = useState();

    const [correctTop3,setCorrectTop3] = useState();
    const [fastestTop3,setFastestTop3] = useState();
  

    // socket 
    const socketRef = useRef(null);

    // ====== Functions ===========================
    const checkHostPassword = async ()=>{
        const verification = await verifyHostPassword({
            test_id,hostPassword
        });
        if(verification){
            setIsVerified(verification);
        }
    }

    
    const handleParticipantJoined = ({id,candidateName,marks})=>{
        const searchParticipant = participantsRef.current.filter((item)=>item.id === id);
        if(searchParticipant.length === 0){
            participantsRef.current =[...participantsRef.current,{id,name:candidateName,marks}];
            console.log(participantsRef.current);
            setParticipants([...participantsRef.current]);
        }
    }
    const handleParticipantLeave = ({id})=>{
            participantsRef.current = participantsRef.current.filter(participant=>participant.id !== id);
            console.log(participantsRef.current);
            setParticipants([...participantsRef.current]);
    }

    // messages 
    const setAllMessagesRef = (current)=>{
        allMessagesRef.current = current;
    }

    const broadcastMessage = (message)=>{
        socketRef.current.emit("broadcastMessage",message);
    }

    // Test Live & Socket 
    const makeTestLive = async()=>{
        setDescription();
        setCurrentPreview();
        await liveTest(test_id);
        socketRef.current.emit('hostLiveTest',test_id);
        setIsLive(true);
    }
    const handleStopTest = async ()=>{
        await stopTest(test_id);
        socketRef.current.emit('stopLiveTest',test_id);
        setIsLive(false);
        setShowResult(true);
    }
    const previewQuestion = (id)=>{
        setDescription();
        setCurrentPreview();
        const que = questions.filter(question=> question._id === id);
        setCurrentPreview(...que);
        setPreviewRefresh(que[0]._id);
    }
    const showDescription = ()=>{
        setPreviewRefresh("show Description");
        setDescription(!description)
    }
    

    const handleAnswerAnalytics = async (data)=>{
        const now = new Date();
        setNewMessage({sender : data.name,message : `Answered Option ${data.choosen} for Q.${data.qid}`,time:now})
        console.log(data);
        const queRef = activeQuestionsRef.current.filter(question=> question._id === data.qid)[0];
        const analytics = JSON.parse(queRef.ansAnalytics);
        console.log(analytics);
        if(data.choosen == queRef.correct){
            participantsRef.current = participantsRef.current.map(participant=>participant.id === data.socket_id ? {...participant,marks : (Number(participant.marks)+Number(queRef.marks))} : participant);
            analytics.rightAnswers++;
            if(analytics.correctTop3[0].name === ""){
                analytics.correctTop3[0] = {
                    name : data.name,
                    ans : data.choosen,
                    timeTaken : queRef.time - data.timeRemaining,
                }
            }else if(analytics.correctTop3[1].name === ""){
                analytics.correctTop3[1] = {
                    name : data.name,
                    ans : data.choosen,
                    timeTaken : queRef.time - data.timeRemaining,
                }
            }else if(analytics.correctTop3[2].name === ""){
                analytics.correctTop3[2] = {
                    name : data.name,
                    ans : data.choosen,
                    timeTaken : queRef.time - data.timeRemaining,
                }
            }
            setRightAnswered(analytics.rightAnswers);
        }else{
            analytics.wrongAnswers++;
            setWrongAnswered(analytics.wrongAnswers);
        }

        if(analytics.fastestTop3[0].name === ""){
            analytics.fastestTop3[0] = {
                name : data.name,
                ans : data.choosen,
                timeTaken : queRef.time - data.timeRemaining,
            }
        }else if(analytics.fastestTop3[1].name === ""){
            analytics.fastestTop3[1] = {
                name : data.name,
                ans : data.choosen,
                timeTaken : queRef.time - data.timeRemaining,
            }
        }else if(analytics.fastestTop3[2].name === ""){
            analytics.fastestTop3[2] = {
                name : data.name,
                ans : data.choosen,
                timeTaken : queRef.time - data.timeRemaining,
            }
        }

        setFastestTop3(analytics.fastestTop3);
        setCorrectTop3(analytics.correctTop3);
        
        // Save Question Analytics
        // -> Use Ref For Current Session
        activeQuestionsRef.current = activeQuestionsRef.current.map(question=>question._id === data.qid ? {...question,ansAnalytics:JSON.stringify(analytics)} : question);
        // -> Api Call
        const currentQue = activeQuestionsRef.current.filter(que=> que._id === data.qid);
        await handleQuestionAction(test_id,...currentQue);
    }
    const activateQuestion = async (id)=>{
        setDescription()
        setFastestTop3();
        setCorrectTop3();
        const que = questions.filter(question=> question._id === id);
        setCurrentPreview(...que);
        setPreviewRefresh(que[0]._id);

        if(isLive){
            // refrsh stats
            const queRef = activeQuestionsRef.current.filter(question=> question._id === id)[0];
            console.log(queRef);
            const analytics = JSON.parse(queRef.ansAnalytics);
            setRightAnswered(analytics.rightAnswers);
            setWrongAnswered(analytics.wrongAnswers);
            setFastestTop3(analytics.fastestTop3);
            setCorrectTop3(analytics.correctTop3);
            socketRef.current.emit('makeQuestionActive',id);
            // Save Activated Question
            // -> Use Ref For Current Session
            activeQuestionsRef.current = activeQuestionsRef.current.map(que=> que._id === id ? {...que,active:true} : que);
            // -> Api Call
            const currentQue = activeQuestionsRef.current.filter(que=> que._id === id);
            await handleQuestionAction(test_id,...currentQue);
        }
    }
    const checkQuestionActive =(id)=>{
        const que = activeQuestionsRef.current.filter((que)=>{return que._id === id});
        return que[0].active;
    }
    const setAnswerReveal = async (id)=>{
        // Save Revealed Question Answers
        // -> Use Ref For Current Session
        activeQuestionsRef.current = activeQuestionsRef.current.map(que=> que._id === id ? {...que,ansReveal:true} : que);
        // -> Api Call
        const currentQue = activeQuestionsRef.current.filter(que=> que._id === id);
        await handleQuestionAction(test_id,...currentQue);
    }
    const checkAnswerReveal =(id)=>{
        const que = activeQuestionsRef.current.filter((que)=>{return que._id === id});
        return que[0].active && !que[0].ansReveal;
    }
    const revealAnswer = async ()=>{
        // Emit For Socket Reveal Ans
        if(isLive){
            socketRef.current.emit('revealAnswer',currentPreview._id);
            await setAnswerReveal(currentPreview._id);
            setParticipants(participantsRef.current);
            // const topPerformer = participantsRef.current.reduce((prev, current) => {
            //     return (current.marks > prev.marks) ? current : prev;
            // }, participantsRef.current[0]);
            // setOverallTopPerformer(topPerformer)
            setPreviewRefresh("reveal ans");
        }
    }
    const setOptionsReveal = async (id)=>{
        // Save Revealed Question Answers
        // -> Use Ref For Current Session
        activeQuestionsRef.current = activeQuestionsRef.current.map(que=> que._id === id ? {...que,optionsReveal:true} : que);
        // -> Api Call
        const currentQue = activeQuestionsRef.current.filter(que=> que._id === id);
        await handleQuestionAction(test_id,...currentQue);
    }
    const checkOptionsReveal =(id)=>{
        const que = activeQuestionsRef.current.filter((que)=>{return que._id === id});
        return que[0].optionsReveal;
    }
    const revealOptions = async ()=>{
        // Emit For Socket Reveal Options
        if(isLive){
            socketRef.current.emit('revealOptions',currentPreview._id);
            await setOptionsReveal(currentPreview._id);
            setPreviewRefresh("reveal options");
        }
    }
    // next prev questions
    const prevQuestion = async (toSet)=>{
        const que = questions.filter((que)=>{return que._id === currentPreview._id});
        const currentIndex = questions.indexOf(que[0]);
        const prevQue = questions[currentIndex-1];
        if(prevQue && toSet){
            await activateQuestion(prevQue._id);
        }
        return !!prevQue;
    }
    const nextQuestion = async (toSet)=>{
        const que = questions.filter((que)=>{return que._id === currentPreview._id});
        const currentIndex = questions.indexOf(que[0]);
        const nextQue = questions[currentIndex+1];
        if(nextQue && toSet){
            await activateQuestion(nextQue._id);
        }
        return !!nextQue;
    }

    useEffect(() => {
        const getData =  async ()=>{
            const data = await fetchTest(test_id);
            setTest(data);
            if(data.isLive === '1'){
                setIsLive(true);
                socketRef.current.emit('hostLiveTest',test_id);
            }
            let ques = await fetchQuestions(test_id);
            if(ques.length === 0){
                ques = await parseCsv(data.file);
            }

            setQuestions(ques);
            activeQuestionsRef.current = ques;
            
        }

        const wrapper = async ()=>{
            // Connect Socket
            if (!socketRef.current) {
                socketRef.current = io("https://graceful-florina-learningsessions.koyeb.app",{query: {testId: test_id}});
          
                socketRef.current.on('connect', () => {
                  console.log('Connected to the server');
                  if(isLive){
                    socketRef.current.emit('hostLiveTest',test_id);
                  }
                  
                });
                socketRef.current.on('candidateJoined', (data) => {
                  console.log('user joiend',data.candidateName);
                  handleParticipantJoined(data);
                  
                });
                socketRef.current.on('fetchAnswerAnalytics', async (data) => {
                    await handleAnswerAnalytics(data);
                });
                socketRef.current.on('receiveMessage', async (message) => {
                    console.log("message received form ",message);
                    setNewMessage(message);
                });
                socketRef.current.on('userDisconnected', async (data) => {
                    console.log("user disconnected");
                    handleParticipantLeave(data);
                });
                socketRef.current.on('disconnect', async (data) => {
                    console.log("user disconnected");
                    console.log(data);
                });
          
            }
            await getData();
        }
        wrapper();        
    }, [isLive]);


    // use Memo Question Preview
    const questionPreviewComponent = useMemo(() => {
        return (
            <QuestionPreview 
                refreshMe={previewRefresh}
                nextQuestion={nextQuestion} 
                prevQuestion={prevQuestion} 
                showDesc={showDescription} 
                checkQuestionActive={checkQuestionActive}
                checkOptionsReveal={checkOptionsReveal}
                checkAnswerReveal={checkAnswerReveal}
                question={{...currentPreview}}
                isAdminSide={true}
                revealAnswer={revealAnswer}
                revealOptions={revealOptions}
            />
        );
    }, [previewRefresh,currentPreview])
    const liveChatComponent = useMemo(() => {
        return (
            <LiveChat 
                allMessagesRef={allMessagesRef.current} 
                setAllMessagesRef={setAllMessagesRef} 
                message={newMessage} 
                broadcastMessage={broadcastMessage} 
                isAdminSide={true}
            />
        );
    }, [newMessage])


    if(!isVerified){
        return (
            <div className='container mt-3 pb-5'>
                <div className="row pb-5">
                    <div className="col-md-4 mx-auto">
                        <div className="unlockHost wrapper py-3 px-2 border-1 rounded-2 lighterBg yCenter gap-2 flex-column my-4">
                            <h3 className='text-center'>Enter Host Password to Start Quiz Test</h3>
                            <p className='text-center text-muted mb-1'>Host Password is password entered during creation of test, it is required to host test</p>
                            <input className='form-control' value={hostPassword} onChange={(e)=>setHostPassword(e.target.value)} type="text" placeholder='Enter Host Password' />
                            <button onClick={checkHostPassword} className="btn btn-primary w-100">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="container-lg">
            <div className="row row-gap-5 questionDetails detailsTile mt-3">
                <div className="col-12">
                    <div className="testInformation d-flex justify-content-between">
                        <h3 className='text-start mb-3 text-dark fw-bold'>{(questions.length > 0) ? questions[0].test : "Test Name Will Refect Here"}</h3>
                        <div className="testActions">
                            {
                                (isLive) ? <button onClick={handleStopTest} className="btn btn-danger">Stop Test</button> : <button onClick={() => { makeTestLive();}} className="btn btn-primary">Live Test</button>
                            }
                        </div>
                    </div>
                </div>
                <hr />
                <div className="col-sm-3">
                    <DetailBox title={"Total Questions"} icon={"https://dizzi.link/assets/icons/user.png"} value={questions.length}/>
                </div>
                <div className="col-sm-3">
                    <DetailBox title={"Total Participants"} icon={"https://dizzi.link/assets/icons/user.png"} value={participants.length}/>
                </div>
                <div className="col-sm-6">
                    <QuestionPalette questions={questions} previewQuestion={(isLive)?activateQuestion:previewQuestion} currentPreview={currentPreview} checkQuestionActive={checkQuestionActive} checkAnswerReveal={checkAnswerReveal} />
                </div>
            </div>
            <div className="row my-3 gap-4 gap-md-0">
                <div className="col-md-9">
                    <div className="widget controlpanel">
                        <img id='questionWatermark' src="https://learningsessions.in/wp-content/uploads/2020/10/logo_black_retina-300x99.png" alt="" srcSet="" />
                        <div className="topHeading mb-3">
                            <h6 className="text-muted">Question Panel</h6>
                            {
                                (currentPreview && checkQuestionActive(currentPreview._id)) 
                                ?
                                    <div className="questionStats">
                                        <QuestionStats test_id={test_id} rightAnswered={rightAnswered}  wrongAnswered={wrongAnswered} />
                                    </div>
                                : <></>
                            }
                        </div>
                        {
                            (currentPreview) ?
                                questionPreviewComponent
                            : 
                                <>
                                    <h5 className="text-muted mt-3">Choose Question From Palette</h5>
                                    <QuestionShimmer/>
                                </>
                        }
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="leaderboardCover widget">
                        {
                            liveChatView ?
                            <>
                                <button onClick={()=>setLiveChatView(false)} className="btn btn-primary p-0 px-1 m-0 mb-2 float-end">Leaderboard</button>
                                {liveChatComponent}
                            </>
                            :
                            <>
                                <button onClick={()=>setLiveChatView(true)} className="btn btn-primary p-0 px-1 m-0 mb-2 float-end">Live Chat</button>
                                <Leaderboard 
                                    test_id={test_id} 
                                    participantsRef={participants} 
                                    totalMarks={
                                        (questions && questions.length>0) ? questions.reduce((sum, question) => {
                                            const marks = parseFloat(question.marks);
                                            return !isNaN(marks) ? sum + marks : sum;
                                        }, 0): ""
                                    } 
                                />
                            </>
                        }
                    </div>
                </div>
            </div>
            {
                (description) ? 
                    <div className="row">
                        <div className="col">
                            <div className="questionDescription widget mb-3">
                                <QuestionDescription description={currentPreview.description}/>
                            </div>
                        </div>
                    </div>
                : <></>
            }
            {
                correctTop3 && fastestTop3 && currentPreview ?
                    <div className="row">
                        <div className="col">
                            <div className="widget">
                                <h6 className="text-muted mb-3">Top Performer</h6>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <h6 className="text-muted mb-2">Fastest Answer</h6>
                                        <TopPerformer first={fastestTop3[0]} second={fastestTop3[1]} third={fastestTop3[2]}/>
                                    </div>
                                    {
                                        !checkAnswerReveal(currentPreview._id) ? 
                                            <div className="col-sm-6">
                                                <h6 className="text-muted mb-2">Correct Answer</h6>
                                                <TopPerformer first={correctTop3[0]} second={correctTop3[1]} third={correctTop3[2]}/>
                                            </div>
                                        : <></>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                : <></>
            }
            {/* {
                showResult ? <Navigate to={"/SplashResult/"+test_id}/> : <></>
            } */}
        </div>
    )
}

export default HostTest