import React from 'react'
import { AiFillCheckCircle } from 'react-icons/ai'

function LoadingAlert({isVisible,isLoading}) {
    if(isVisible){
        return (
        <div className='loadingAlertCover'>
            <div className="backgroundMask"></div>
            <div className="loadingAlert">
                {(isLoading) ? 
                    <>
                        <div class="spinner-border text-primary" role="status"></div>
                        <h3 className="message m-0">Saving</h3>
                    </>
                    :
                    <>
                        <AiFillCheckCircle color="green" size={40}/>
                        <h3 className="message m-0">Saved</h3>
                    </>
                }
            </div>
        </div>
        )
    }
}

export default LoadingAlert