import React, { useState } from 'react'
import Shortner from '../shortner/Shortner'
import Result from '../result/Result'
import "./Hero.css"

function Hero({data,handleHistory}) {
    const [isGenerated,setIsGenerated] = useState(false);

    const handleGenerated = (bool)=>{
        setIsGenerated(bool)
    }
  return (
    <div id='Hero' className='container py-5 hero'>
        <div className="row row-gap-3">
            <div className="col-md-8 px-lg-2">
                <div className="wrapper xCenter gap-3 gap-md-4">
                    <div className="textWrapper topTextWrapper mb-2 pt-2">
                            <span className='m-0 h5 topText rounded-pill px-3 py-1 px-sm-4 py-sm-2 text-primary bg-primary-opacity'>{data.top}</span>
                    </div>
                    <div className="pe-0 pe-lg-5">
                        <div className="heading pe-0 pe-lg-5 me-0 me-lg-5">
                            <h1 className='m-0 extraLargeFont fw-semibold lh-sm'>Unlock traffic with dizzi's link tool</h1>
                        </div>
                    </div>
                    <div className="para pe-0 pe-lg-5">
                        <p className='m-0 textSecondary pe-0 pe-lg-5 me-0 me-lg-5'>{data.para}</p>
                    </div>
                    <div className="shortnerWrapper xCenter gap-2 pt-2 pt-md-3">
                        <div className="shortner" style={{width: "500px"}}>
                            <Shortner handleHistory={handleHistory} handleGenerated={handleGenerated}/>
                        </div>
                        <p className='m-0' style={{"paddingLeft": "12px"}}>{data.shortnerText}</p>
                    </div>
                </div>
            </div>
            <div className="col-md-4 pt-5 pt-md-0">
                <Result/>
            </div>
        </div>
    </div>
  )
}

export default Hero
