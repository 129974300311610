import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './common.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter,Outlet,RouterProvider, useLocation} from "react-router-dom";
import Home from './pages/Home';
import Navbar from './components/navbar/Navbar';
import Footer from "./components/footer/Footer"
import Loader from './components/loader/Loader';
import TopNav from './components/topNav/TopNav';
import EditForm from './pages/EditForm';
import LatestLinks from './components/latestLinks/LatestLinks';
import Shortner from './components/shortner/Shortner';
import Profile from './pages/Profile';
import Direct from './components/linkFeatures/direct/Direct';
import CustomUrl from './components/linkFeatures/customUrl/CustomUrl';
import PromptUrl from './components/linkFeatures/promptUrl/PromptUrl';
import PromptWithCustom from './components/linkFeatures/promptWithCustom/PromptWithCustom';
import Signup from './pages/Signup';
import Login from './pages/Login';
import PrivateRoute from './pages/PrivateRoute';
import { AuthProvider, useAuth } from './pages/AuthContext';
import Dashboard from './pages/Dashboard';
import Responses from './pages/Responses';
import Checkout from './pages/Checkout';
import EditUrl from './pages/EditUrl';
import Analytics from './pages/Analytics';
import OnlineQuiz from './components/linkFeatures/onlineQuiz/OnlineQuiz';
import HostTest from './pages/HostTest';
import TakeTest from './pages/TakeTest';
import SplashResult from './pages/SplashResult';


// localStorage.clear();
// const checkUserStatus = ()=>{
//   const status = localStorage.getItem('token');
//   if(status){
//     return true;
//   }else{
//     return false;
//   }
// }
// let isLogedIn  = checkUserStatus();

function AppLayout() {

  const { isAuthenticated,login } = useAuth();

  const { pathname } = useLocation();
  useEffect(() => {
    login();
    window.scrollTo(0, 0);
  }, [pathname]);
  
  return (
        <>
            {isAuthenticated ? (
              <div className="sticky-top">
                <Navbar isAuthenticated={isAuthenticated} />
                <TopNav />
              </div>
              ) : 
              (
                <>
                  <div className="sticky-top">
                    <Navbar isAuthenticated={isAuthenticated} />
                  </div>
                </>
              )
            }
            
          <Outlet/>
          <Footer />
        </>
  )
}


const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    children:[
      {
        path: "/",
        index: true,
        element: <PrivateRoute element={<Home/>} navigateTo={"/dashboard"} isTrue={false} />
      },
      {
        path: "/checkout",
        index: true,
        element: <PrivateRoute element={<Checkout/>} navigateTo={"/dashboard"} isTrue={false} />
      },
      {
        path: "/dashboard",
        element: <PrivateRoute element={<Dashboard/>} navigateTo={"/"} isTrue={true} />,
        children: [
          {
            path: "/dashboard",
            element: <PrivateRoute element={<Direct/>} navigateTo={"/auth"} isTrue={true} />,
          },
          {
            path: "direct",
            element: <PrivateRoute element={<Direct/>} navigateTo={"/auth"} isTrue={true} />,
          },
          {
            path: "custom",
            element:<PrivateRoute element={<CustomUrl/>} navigateTo={"/auth"} isTrue={true} />,
          },
          {
            path: "prompt",
            element: <PrivateRoute element={<PromptUrl/>} navigateTo={"/auth"} isTrue={true} />,
          },
          {
            path: "prompt-custom",
            element: <PrivateRoute element={<PromptWithCustom/>} navigateTo={"/auth"} isTrue={true} />,
          },
          {
            path: "online-quiz",
            element: <PrivateRoute element={<OnlineQuiz/>} navigateTo={"/auth"} isTrue={true} />,
          },
        ]
      },
      {
        path: "/dashboard/edit/url/:route",
        element: <PrivateRoute element={<EditUrl/>} navigateTo={"/"} isTrue={true} />,
      },
      {
        path : "/responses/:route",
        element : <PrivateRoute element={<Responses/>} navigateTo={"/auth"} isTrue={true} />,
      },
      {
        path : "/analytics/:route",
        element : <PrivateRoute element={<Analytics/>} navigateTo={"/auth"} isTrue={true} />,
      },
      {
        path: "/profile",
        element: <PrivateRoute element={<Profile/>} navigateTo={"/auth"} isTrue={true} />,
        children: [
          {
            path: "/profile",
            element: <PrivateRoute element={<LatestLinks/>} navigateTo={"/auth"} isTrue={true} />
          },
          {
            path: "latest",
            element: <PrivateRoute element={<LatestLinks/>} navigateTo={"/auth"} isTrue={true} />
          },
          {
            path: "main",
            element: <Shortner />
          },
          {
            path: "overview",
            element: <Loader />
          },
        ],
      },
      {
        path: "/editform/:route",
        element: <PrivateRoute element={<EditForm/>} navigateTo={"/auth"} isTrue={true} />,
      },
    ]
  },
  {
    path: "/auth",
    element: <PrivateRoute element={<Login/>} navigateTo={"/dashboard"} isTrue={false} />
  },
  {
    path: "/auth/signup",
    element: <PrivateRoute element={<Signup/>} navigateTo={"/dashboard"} isTrue={false} />
  },
  {
    path: "/hostTest/:test_id",
    index: true,
    element: <HostTest/>
  },
  {
    path: "/takeTest/:test_id",
    index: true,
    element: <TakeTest/>
  },
  {
    path: "/liveTestResult/:test_id/:mobile",
    index: true,
    element: <SplashResult/>
  },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <RouterProvider router={appRouter} />
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
