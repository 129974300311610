import React, { useEffect, useRef, useState } from 'react'
import { _getCookie, _setCookie, generateQr, generateRandomString } from '../../functions';
import { FaGlobe, FaMarker } from 'react-icons/fa'
import TileShimmer from '../shimmer/TileShimmer'
import EnterTestForm from './EnterTestForm';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Field, Form, Formik } from 'formik';
import { verifyNumber } from '../../watiFunctions';
import { BsCheck2Circle } from 'react-icons/bs';
import WaveLoader from '../waveLoader/WaveLoader';
import { Link } from 'react-router-dom';

function CandidateWatingRoom({ test_id, testName, totalMarks, handleEnterRoom, isLive, readyToFire, setCandidateRef }) {
    const data = {
        logo: "https://learningsessions.in/wp-content/uploads/2020/10/logo_black_retina-300x99.png",
        testDetails: {
            title: "Indian Economy and Indian Financial System",
            language: "English",
            marks: 100
        },
        rightPanelData: {
            banners: [
                "https://learningsessions.in/wp-content/uploads/2023/08/Promote-1.jpg",
                "https://learningsessions.in/wp-content/uploads/2020/09/jaiib-1024-%C3%97-1024-px.png",
                "https://learningsessions.in/wp-content/uploads/2022/06/1-BY-1.jpg"
            ],
            formData: {
                title: "Book Your Free Class Now",
                subTitle: "Join 100 + MN Registered IIBF Users",
                submitBtnText: "Schedule your Class",
            }
        },
        formAndWaitingRoomData: {
            formData: {
                fields: {
                    name : {
                        name: "Full Name",
                        required: true,
                    },
                    mobile:{
                        name: "Mobile",
                        required: true
                    }

                },
                termsConditionsText: "By continue, you confirm that you've read and accepted our <a href='#'>Terms and Conditions</a> and our <a href='#'>Cookie Policy</a>.",
                submitBtnText: "Verify Mobile Number",
            },
            afterJoinText: "Hooray! You have entered the Room.<br />Wait for the Test to be started.",
        },
    }
    const courses = {
        title: "Explore Courses",
        courses: [
            {
                poster: "https://courses-assets-v2.classplus.co/_next/image?url=/api/proxyimage?url=https%3A%2F%2Fali-cdn-wl-assets.classplus.co%2Fproduction%2Fsingle%2Fgegkt%2F0556887a-5164-4a34-a98c-6922c577604e.jpg&w=384&q=75",
                target: "https://gegkt.courses.store/402633?utm_source%3Dother%26utm_medium%3Dtutor-course-referral%26utm_campaign%3Dcourse-overview-webapp",
                btnText: "Explore Course"
            },
            {
                poster: "https://courses-assets-v2.classplus.co/_next/image?url=/api/proxyimage?url=https%3A%2F%2Fali-cdn-wl-assets.classplus.co%2Fproduction%2Fsingle%2Fgegkt%2Ff80fcb0d-f02a-4ca2-9b7a-098c39189382.jpg&w=384&q=75",
                target: "https://gegkt.courses.store/280553?utm_source%3Dother%26utm_medium%3Dtutor-course-referral%26utm_campaign%3Dcourse-overview-webapp",
                btnText: "Explore Course"
            },
            {
                poster: "https://courses-assets-v2.classplus.co/_next/image?url=/api/proxyimage?url=https%3A%2F%2Fali-cdn-wl-assets.classplus.co%2Fproduction%2Fsingle%2Fgegkt%2F92f01908-1771-49cc-ba6b-1a4851767b54.png&w=256&q=75",
                target: "https://gegkt.courses.store/277182?utm_source%3Dother%26utm_medium%3Dtutor-course-referral%26utm_campaign%3Dcourse-overview-webapp",
                btnText: "Explore Course"
            },
            {
                poster: "https://courses-assets-v2.classplus.co/_next/image?url=/api/proxyimage?url=https%3A%2F%2Fali-cdn-wl-assets.classplus.co%2Fproduction%2Fsingle%2Fgegkt%2F211b55e2-297b-405a-92e6-2239afc061b6.jpg&w=256&q=75",
                target: "https://gegkt.courses.store/402633?utm_source%3Dother%26utm_medium%3Dtutor-course-referral%26utm_campaign%3Dcourse-overview-webapp",
                btnText: "Explore Course"
            },
            {
                poster: "https://courses-assets-v2.classplus.co/_next/image?url=/api/proxyimage?url=https%3A%2F%2Fali-cdn-wl-assets.classplus.co%2Fproduction%2Fsingle%2Fgegkt%2Fe0ffc2c2-1cbe-47b0-ae43-0967ba718a97.png&w=256&q=75",
                target: "https://gegkt.courses.store/280550?utm_source%3Dother%26utm_medium%3Dtutor-course-referral%26utm_campaign%3Dcourse-overview-webapp",
                btnText: "Explore Course"
            },
        ],
    };

    const link = `https://wa.me/919646900030?text=DIZZILINKLIVETEST%20DO%20NOT%20COPY%2C%20FORWARD%20OR%20SHARE%20THIS%20MESSAGE%20`;
    const [formSubmited,setFormSubmited] =  useState();
    const [whatsAppVerify,setWhatsAppVerify] =  useState();
    const [whatsAppNumber,setWhatsAppNumber] =  useState();

    const verificationCode = useRef(generateRandomString(16));




    // useEffect(() => {
    //     const user = _getCookie("dizziLinkTestDeatils");
    //     if (user) {
    //         const userDetails = JSON.parse(user);
    //         for (const detail in userDetails) {
    //             if (Object.hasOwnProperty.call(userDetails, detail)) {
    //                 const value = userDetails[detail];
    //                 const stateSetter = eval(`set${detail.charAt(0).toUpperCase() + detail.slice(1)}`);
    //                 if (typeof stateSetter === 'function') {
    //                     stateSetter(value);
    //                 }
    //             }
    //         }
    //     }

    // }, [])


    // useEffect(() => {
    //     const getData = async () => {
    //         if (mobile) {
    //             const candidate = await fetchParticipant(test_id, mobile);
    //             if (candidate) {
    //                 setCandidateRef(candidate);
    //             }
    //         }
    //     }

    //     getData();
    // }, [mobile])

  


    useEffect(() => {
        // generate a whatsaplink for verification
        if(whatsAppNumber && whatsAppVerify){
            _setCookie("dizziLinkNumberVerified", whatsAppNumber, 24 * 30); // 30 Days
            handleEnterRoom(formSubmited);
        }
        
    }, [whatsAppVerify]);
    useEffect(() => {
        // generate a whatsaplink for verification
        generateQr(link+verificationCode.current);
    }, [formSubmited]);

    useEffect(()=>{
        if(whatsAppNumber && !whatsAppVerify){
            const interval  = setInterval(async () => {
                const isVerified = await verifyNumber("+91"+whatsAppNumber,verificationCode.current);
                console.log("number is verifyed",isVerified);
                if(isVerified){
                    setWhatsAppVerify(true);
                    clearInterval(interval);
                }
            }, 10000);
        }
    },[whatsAppNumber])

    const handleDetails = (details) => {
        _setCookie("dizziLinkTestDeatils", JSON.stringify(details), 24 * 30); // 30 Days
        setFormSubmited(details);
        setWhatsAppNumber(details.mobile);
        const number = _getCookie("dizziLinkNumberVerified");
        if(number && number === details.mobile){
            setWhatsAppVerify(true);
        }
    }

    return (
        <div id='enterTest' className='container-lg py-2 pb-3 py-md-5'>
            <div className="row" style={{ rowGap: "3rem" }}>
                <div className="col-lg-9 pe-md-5">
                    <nav>
                        <div className="yCenter justify-content-between gap-2 flex-wrap">
                            <div className="testLogo" style={{ width: "280px" }}>
                                {data.logo ? <img src={data.logo} alt="Logo-Loading-Error" className='w-100 h-100' /> : <TileShimmer />}
                            </div>
                            {data.testDetails ?
                                <div className='testMarksLanguage mt-2 mt-md-0 d-flex flex-sm-column gap-3 flex-grow-1 flex-wrap align-items-end justify-content-between h5' style={{rowGap: "0"}}>
                                    <div className="testMarks yCenter gap-2">
                                        <span style={{ fontSize: "0.9rem" }}><FaMarker /></span>
                                        <p className="m-0">Marks: {totalMarks}</p>
                                    </div>
                                    <div className="testLanguage yCenter gap-2">
                                        <span style={{ fontSize: "0.9rem" }}><FaGlobe /></span>
                                        <p className="m-0">Language: English</p>
                                    </div>
                                </div>
                                :
                                <div style={{ width: "280px" }}><TileShimmer /></div>
                            }
                        </div>
                    </nav>

                    {data.testDetails ? <h2 className="m-0 my-3 text-center text-sm-start">Test: {testName}</h2> : <TileShimmer />}
                    {
                        !formSubmited ? 
                            data.formAndWaitingRoomData ? 
                                <div className='mt-4'>
                                    <EnterTestForm handleDetails={handleDetails} test_id={test_id} setCandidateRef={setCandidateRef} data={data.formAndWaitingRoomData} />
                                </div>
                            : <TileShimmer/>
                        : 
                        !whatsAppVerify ?
                        <>
                            <div className="allCenter gap-3 mt-4 mt-md-5">
                                <p className="m-0 text-center h5 scanQRLabel">Scan this QR Code to Verify</p>
                                <div className="xCenter gap-1">
                                    <div id="qrWrapper" className='border rounded-3 p-3 cursorPointer' data-bs-toggle="modal" data-bs-target="#proceedVerification" style={{width: "260px", maxWidth: "100%"}}>
                                        <div className="h-100 w-100">
                                            <TileShimmer/>
                                        </div>
                                    </div>
                                    <p className="m-0 text-primary text-decoration-underline cursorPointer" data-bs-toggle="modal" data-bs-target="#proceedVerification">Verify</p>
                                </div>
                                <p className="m-0 lighterText">Note: a whatsapp message will be sent from <span className="currentNumberContainer px-2 p-1 rounded-2 lighterBg"><span className="currentNumber text-nowrap">{whatsAppNumber}<Link to="#" onClick={()=>setFormSubmited()} className='ms-2'>Change Number</Link></span></span> to our server</p>

                                <div id='proceedVerification' className="modal fade">
                                    <div className="modal-dialog modal-dialog-centered" style={{width: "fit-content"}}>
                                        <div className="modal-content rounded-3 position-relative p-3">
                                            <button type="button" className="btn-close opacity-100 bg-white position-absolute p-1 rounded-circle border border-1 border-dark"  style={{top: "-10px", right: "-10px", backgroundSize: "10px"}} data-bs-dismiss="modal" aria-label="Close"></button>
                                            <div className="modal-body">
                                                <div className="xCenter gap-3">
                                                    <p className="m-0 lighterText">A WhatsApp message will be sent from <span className="currentNumber px-2 p-1 rounded-2 lighterBg"><span className="currentNumber text-nowrap">{whatsAppNumber}<Link to="#" data-bs-dismiss="modal" aria-label="Close" onClick={()=>setFormSubmited()} className='ms-2'>Change Number</Link></span></span> to our Server to Verify your number.</p>
                                                    <p className="m-0">Click below to continue</p>
                                                    <div data-bs-dismiss="modal" aria-label="Close">
                                                        <Link target='_blank' to={link+verificationCode.current} className="btn btn-primary text-uppercase w-100">PROCEED</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        : 
                        <>
                            <div className="allCenter gap-3 mt-5">
                                <p className="m-0 text-center h5">Number Successfully Verified</p>
                                <div className='border rounded-3 p-4 xCenter gap-2' style={{width: "260px", maxWidth: "100%"}}>
                                    <span><BsCheck2Circle className='text-success' style={{fontSize: "7rem"}} /></span>
                                    <p className="m-0 fw-bold h1 text-success">Verified</p>
                                </div>
                            </div>
                            {
                                !isLive && readyToFire ? 
                                    <div className="waveLoader my-4" style={{overflow:"hidden"}}>
                                        <p className='text-center text-success mb-4' style={{fontSize: "1.2rem"}}>You Will Be Entered in room once Test started</p>
                                        <WaveLoader lineColor="#CC0000"/>
                                    </div>
                                : <></>
                            }

                        </>
                    }
                </div>
                <div className="col-lg-3">
                    <div className="xCenter gap-4">

                        {data.rightPanelData.formData ?
                            <div className="rightPanelForm rounded-4 border p-4 p-sm-5 p-md-3 p-lg-4">
                                <div className="xCenter text-center gap-3">
                                    <h5 className="m-0" style={{ color: "#6c757d" }}>{data.rightPanelData.formData.subTitle}</h5>
                                    <h5 className="m-0">{data.rightPanelData.formData.title}</h5>
                                    <div className="mt-2">
                                        <Formik initialValues={{
                                            name: "",
                                            number: "",
                                            course: "",
                                            bank: "",
                                            submit: data.rightPanelData.formData.submitBtnText
                                        }}>
                                            <Form>
                                                <div className="xCenter gap-3">
                                                    <Field type="text" name="name" placeholder="Enter your name" className="form-control" />
                                                    <Field type="number" name="number" placeholder="Enter your number" className="form-control" />
                                                    <Field component="select" name="course" className="form-control">
                                                        <option value="course">Course</option>
                                                        <option value="JAIIB">JAIIB</option>
                                                        <option value="CAIIB">CAIIB</option>
                                                        <option value="PPC">Promotions</option>
                                                        <option value="SSM">Certification</option>
                                                    </Field>
                                                    <div>
                                                        <Field type="text" name="bank" list="banks" placeholder="Enter your Bank" className="form-control" />
                                                        <datalist id="banks">
                                                            <option value="Bank of Badoda" />
                                                            <option value="Punjab National Bank" />
                                                            <option value="HDFC Bank" />
                                                            <option value="ICICI Bank" />
                                                            <option value="State Bank of India" />
                                                        </datalist>
                                                    </div>
                                                    <Field type="submit" name="submit" className="form-control text-white fw500 mt-2" style={{ backgroundColor: "#cc0000" }} />
                                                </div>
                                            </Form>
                                        </Formik>
                                    </div>
                                </div>

                            </div> : <TileShimmer />
                        }


                        {/*=================== Courses for Mobile  ===================*/}
                        <div className="postersSlider rightPanelCourses d-md-none">
                            <Splide options={{
                                type: "loop",
                                autoplay: true,
                                interval: 1500,
                                arrows: false,
                            }} aria-label='Banners'>
                                {courses.courses ? (
                                    <>
                                        {courses.courses.map((course, index) => {
                                            if(course) {
                                                return(
                                                    <SplideSlide key={"course"+index}>
                                                        <Link to={course.target} target='_blank'>
                                                            <div className="border coursePoster position-relative w-100">
                                                                <div className="posterImg"><img src={course.poster} alt="Poster-Loading-Error" className='w-100 h-100' /></div>
                                                                <div className="position-absolute" style={{bottom: "5%", left: "5%"}}>
                                                                    <button className="exploreBtn rounded-3 text-white text-nowrap transition">
                                                                        {course.btnText}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </SplideSlide>
                                                )
                                            } else { return <TileShimmer />}
                                        })}
                                    </>
                                ) : <TileShimmer />}
                            </Splide>
                        </div>

                    </div>
                </div>
            </div>
            {/*=================== Courses for Desktop  ===================*/}
            <div className='rightPanelCourses d-none d-md-block mt-5'>
                <h2 className="m-0 mb-4 h3">{courses.title}</h2>
                <div className="row" style={{rowGap: "1.5rem"}}>
                    {courses.courses ? (
                        <>
                            {courses.courses.map((course, index) => {
                                if(course) {
                                    return(
                                        <Link key={"course"+index} to={course.target} target='_blank' className="col-md-6 col-lg-3">
                                            <div className="shadow coursePoster position-relative w-100">
                                                <div className="posterImg"><img src={course.poster} alt="Poster-Loading-Error" className='w-100 h-100' /></div>
                                                <div className="position-absolute" style={{bottom: "5%", left: "5%"}}>
                                                    <button className="exploreBtn rounded-3 text-white text-nowrap transition">
                                                        {course.btnText}
                                                    </button>
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                } else { return <TileShimmer />}
                            })}
                        </>
                    ) : <TileShimmer />}
                </div>
            </div>
        </div>
    )
}

export default CandidateWatingRoom