import React from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";

function BarChart({ chartData }) {
  
  const chartOptions = {
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <Bar data={chartData} options={chartOptions} />
  );
}

export default BarChart;
