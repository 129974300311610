import React, { useEffect, useState } from "react";
import ModifyForm from "../components/modifyForm/ModifyForm";
import ColorCustomizations from "../components/colorCustomizations/ColorCustomizations";
import { getPromptForm, modifyPromptForm } from "../functions";
import { Navigate, useParams } from "react-router-dom";
import PostShimmer from "../components/shimmer/PostShimmer";
import LoadingAlert from "../components/loadingAlert/LoadingAlert";

function EditForm() {
  let { route } = useParams();

  const[isNotFound,setIsNotFound] = useState(false);
  const[isLoading,setIsLoading] = useState(false);
  const[isVisible,setIsVisible] = useState(false);

  const [companyAttributes,setCompanyAttributes] = useState();
  const [fileUploaded,setFileUploaded] = useState(true);
  const [companyLogo,setCompanyLogo] = useState();
  const [formAttributes,setFormAttributes] = useState();
  const [uiAttributes,setUiAttributes] = useState();

  const handleFileUpload = async(event)=>{
    const file = event.target.files[0]
    if (file) {
        const url = 'https://dizzi.link/api/uploadFile'; 
        const data = new FormData(); 
        data.append('deleteCode', '1234'); 
        data.append('file', file);
        const options = { 
            method: 'POST', 
            headers: { 
                'x-api-key': '89bc723f-0e24-4feb-8981-f96960ce807d', 
            },  
            body: data,
        };

        try{
          const response = await fetch(url, options);
          const data = await response.json();
          modifyCompanyAttributes('logo',data.url);
          setFileUploaded(true);
          setCompanyLogo(data.url)
          console.log(data.url);
        }catch(error){
          console.log("Error uploading file: ", error);
        }
    } else {
        console.log("No File Selected");
    }
  }

  const modifyCompanyAttributes = (key,value)=>{
    const attributes = companyAttributes;
    attributes[key] = value;
    setCompanyAttributes(attributes);
  }
  
  const modifyFormAttributes = (key,value)=>{
    const attributes = formAttributes;
    attributes[key] = value;
    setFormAttributes(attributes);
    console.log(formAttributes);
  }
  const modifyUiAttributes = (value)=>{
    setUiAttributes(value);
  }

  const changePromptAttribute = async ()=>{
      setIsLoading(true);
      setIsVisible(true);
      await modifyPromptForm(route,{
      companyAttributes,
      formAttributes,
      uiAttributes
    });
    setIsLoading(false);
    setTimeout(() => {
      setIsVisible(false);
    }, 500);
  }



  useEffect(() => {
    const getData = async ()=>{
      const attributes = await getPromptForm(route);
      if(attributes !== false){
          setCompanyAttributes(attributes.companyAttributes);
          setFileUploaded(!!attributes.companyAttributes.logo);
          setCompanyLogo(attributes.companyAttributes.logo);
          setFormAttributes(attributes.formAttributes);
          setUiAttributes(attributes.uiAttributes);
      }else{
        setIsNotFound(true)
      }
    }
    getData();
  }, [route])
  

  return (
    <div className='container-fluid py-5'>
      {
        (isNotFound) ? <Navigate to={"/dashboard/prompt"}/> : <></>
      }

      <div className="container">

        <div className="row" style={{ rowGap: "2.5rem" }}>
          {
            (companyAttributes) 
            ?
            <div id="companyDetails" className="col-12">
              <div className="xCenter gap-3">
                <div className="companyFields d-flex justify-content-between">
                  <div className="xCenter gap-1">
                    <p className='fs-5 m-0'>Company Details</p>
                    <p className="m-0 lighterText">Enter Company Details Which are Available on Prompt Form </p>
                  </div>
                  <div className="saveBtn">
                    <button onClick={changePromptAttribute} className="btn btn-primary">Save</button>
                  </div>
                </div>

                <div className="row" style={{ rowGap: "1rem" }}>

                    <div className="col-md-6">
                      <div className="xCenter gap-3 lighterBg rounded-4 p-3 bgShadow border">

                        <div className="xCenter pageField" data-name="Name" data-key="name">
                          <p className="m-0">Name</p>
                          <div className="inputWrapper">
                            <input defaultValue={companyAttributes.name} onInput={(e)=>{modifyCompanyAttributes('name',e.target.value)}} type="text" name="name" id="companyName" className="form-control companyTextInput" placeholder="ABC Company Pvt. Ltd."/>
                          </div>
                        </div>

                        <div className="xCenter pageField" data-name="Logo" data-key="logo">
                          <p className="m-0">Logo</p>
                          <div className="inputWrapper">
                            {
                              (fileUploaded) ?
                              <>
                                <input value={companyLogo} onInput={(e)=>{setCompanyLogo(e.target.value);modifyCompanyAttributes('logo',e.target.value)}} type="text" name="logo" id="companyLogo" className="form-control companyTextInput" placeholder="Enter Valid Url"/>
                                <button onClick={()=>{setFileUploaded(false)}} className="mt-3 mb-0 float-end fs-6 border-light">Upload</button>
                              </>
                              :
                              <>
                                <input onChange={(e)=>{handleFileUpload(e)}} type="file" name="logo" id="companyLogo" className="form-control companyFileInput" />
                                <button onClick={()=>{setFileUploaded(true)}} className="mt-3 mb-0 float-end fs-6 border-light">  Url</button>
                              </>
                            }
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="xCenter gap-3 lighterBg rounded-4 p-3 bgShadow border h-100">

                        <div className="xCenter pageField" data-name="Description" data-key="description">
                          <p className="m-0">Prompt Description</p>
                          <div className="inputWrapper">
                            <textarea defaultValue={companyAttributes.description} onInput={(e)=>{modifyCompanyAttributes('description',e.target.value)}} name="description" id="description" className="form-control companyTextInput w-100" rows="1" placeholder="...."/>
                          </div>
                        </div>

                        <div className="xCenter pageField" data-name="address" data-key="address">
                          <p className="m-0">Address</p>
                          <div className="inputWrapper">
                            <textarea defaultValue={companyAttributes.address} onInput={(e)=>{modifyCompanyAttributes('address',e.target.value)}} name="address" id="address" className="form-control companyTextInput w-100" rows="1" placeholder="ABC Street, West Mall 122300"/>
                          </div>
                        </div>

                      </div>
                    </div>

                </div>
              </div>
            </div>
            :
            <></>
          }

          <div className="col-md-6">
            <div className="">
              <ModifyForm formAttributes={formAttributes} modifyFormAttributes={modifyFormAttributes} />
            </div>
          </div>

          <div className="col-md-6">
            <div className="">
              {
                (uiAttributes) ? 
                  <ColorCustomizations uiAttributes={uiAttributes}  modifyUiAttributes={modifyUiAttributes} />
                :
                <PostShimmer/>
              }
            </div>
          </div>
        </div>
      </div>
      <LoadingAlert isLoading={isLoading} isVisible={isVisible}/>
    </div>
  );

}

export default EditForm;
