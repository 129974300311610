import axios from "axios";
import React from 'react';
import ReactDOM from 'react-dom';
import { QRCode } from 'react-qrcode-logo';
import Papa from 'papaparse';
const Api = "https://dizzi.link/";
// const Api = "http://localhost:8080/url-shortner-api/";
let accessKey = "89bc723f-0e24-4feb-8981-f96960ce807d";

const fetchPage = async (pageKey) => {
    try {
        const response = await axios.post(`${Api}pages/getPage`, { page: pageKey }, {
            headers: {
                'x-api-key': accessKey,
                'Content-Type': 'application/json',
            },
        })
        console.log(response);
        return response.data.page;
    } catch (error) {
        console.error("Error fetching data:", error);
    }
}

const getShortUrl = async (payload) => {
    try {
        const response = await axios.post(`${Api}/api/getShortUrl`, payload, {
            headers: {
                'x-api-key': localStorage.getItem('token') ? localStorage.getItem('token') : accessKey,
                'Content-Type': 'application/json',
            },
        })
        // const shortUrl = response.data.url;
        console.log(response.data);
        return response.data.url;
    } catch (error) {
        console.error("Error Shorting URL");
    }
}


const generateQr = (url) => {

    const qrCodeElement = (
        <QRCode
            value={url} // here you should keep the link/value(string) for which you are generation promocode
            size={500} // the dimension of the QR code (number)
            logoImage="https://s.dizzi.link/img/qrLogo.png" // URL of the logo you want to use, make sure it is a dynamic url
            logoHeight={100}
            logoWidth={100}
            logoOpacity={1}
            enableCORS={true} // enabling CORS, this is the thing that will bypass that DOM check
            qrStyle="dots" // type of qr code, wether you want dotted ones or the square ones
            eyeRadius={30} // radius of the promocode eye
            id={"QR"}
            logoPadding={1.5}
            removeQrCodeBehindLogo = {false}
        />
    );
    const render = async ()=>{
        const qrWrapper = document.getElementById('qrWrapper');
        if(qrWrapper){
            const qrCodeCanvas = await ReactDOM.render(qrCodeElement, qrWrapper);
            if(qrWrapper.querySelector('canvas')){
                qrWrapper.querySelector('canvas').classList = "w-100 h-100";
            }
        }
    }
    render();
};

function validateUrl(url) {
    const urlExpression = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
    if (urlExpression.test(url)) {
        return true;
    }
    return false;
}

function extractCodeFromURL(url) {
    const parts = url.split('/');
    const code = parts[parts.length - 1];
    return code;
}

function removeHttp(text) {
  const link = text;
  const cleanedText = link.replace(/^(https?:\/\/)/i, '');
  return cleanedText;
}

const focusEnd = (element) => {
    const text = element.innerText;
    const length = text.length;
    const range = document.createRange();
    const selectedText = window.getSelection();
    range.setStart(element.childNodes[0], length);
    range.collapse(true);
    selectedText.removeAllRanges();
    selectedText.addRange(range);
    element.focus();
}

// function to get a cookie value by name
function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}
function _getCookie(name) {
    const cookies = document.cookie.split('; ');
    for (const cookie of cookies) {
        const [cookieName, cookieValue] = cookie.split('=');
        if (cookieName === name) {
            return decodeURIComponent(cookieValue);
        }
    }
}

// function to set a cookie
function _setCookie(key, value, hours) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + hours);
    document.cookie = `${key}=${value}; expires=${expirationDate.toUTCString()};`;
}
// function to set a cookie
function setCookie(name, value, minutes) {
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + minutes * 60 * 1000); // Convert minutes to milliseconds
  const expires = `expires=${expirationDate.toUTCString()}`;
  document.cookie = `${name}=${value}; ${expires}; path=/`;
}

const showHideElement = (element, eventElement) => {
    element.classList.toggle("d-none");
    if(eventElement) {
        eventElement.classList.toggle("toggleRotate");
    }
}

const getHead = async (page) => {
    // temp data
    const headData = {
      title: "Dizzi's | Link Shortner",
      keywords: "",
      description: "",
    }

    return headData;
}

const windowScrollToTop = async () => {
    window.scrollTo(0,0);
}
// Auth Functions
const googleAuth = async (jwt) => {
    try {
        const response = await axios.post(`${Api}auth/google`, { jwt }, {
            headers: {
                'x-api-key': accessKey,
                'Content-Type': 'application/json',
            },
        })
        // handle token
        const token = response.data.token;
        localStorage.setItem("token",token);
        return true;
    } catch (error) {
        console.error("Error :", error);
    }
}
const authUser = async (credentials) => {
    try {
        const response = await axios.post(`${Api}auth/login`, credentials, {
            headers: {
                'x-api-key': accessKey,
                'Content-Type': 'application/json',
            },
        })
        // handle token
        console.log(response.data);
        if(response.data.status === 200){
            const token = response.data.token;
            localStorage.setItem("token",token);
            return true;
        }
        return false;
    } catch (error) {
        console.error("Error :", error);
    }
}
const registerUser = async (details) => {
    try {
        const response = await axios.post(`${Api}auth/register`,details, {
            headers: {
                'x-api-key': accessKey,
                'Content-Type': 'application/json',
            },
        })
        // handle token
        console.log(response.data);
        if(response.data.status === 200){
            const token = response.data.token;
            localStorage.setItem("token",token);
            return true;
        }
        return false;
    } catch (error) {
        console.error("Error :", error);
    }
}
const checkAvailableRoute = async (route) => {
    try {
        const response = await axios.post(`${Api}api/checkAvailableRoute`,{route}, {
            headers: {
                'x-api-key': accessKey,
                'Content-Type': 'application/json',
            },
        })
        // handle token
        // console.log(response.data);
        if(response.data.isAvailable === true){
            return true;
        }
        return false;
    } catch (error) {
        console.error("Error :", error);
    }
}
const getLinkHistory = async () => {
    try {
        const response = await axios.post(`${Api}api/linkHistory`,{}, {
            headers: {
                'x-api-key': localStorage.getItem('token') ? localStorage.getItem('token') : accessKey,
                'Content-Type': 'application/json',
            },
        })
        console.log(response.data);
        return response.data.history;
    } catch (error) {
        console.error("Error :", error);
    }
}
const getRouteResponses = async (route) => {
    try {
        const response = await axios.post(`${Api}api/routeResponses`,{route}, {
            headers: {
                'x-api-key': localStorage.getItem('token') ? localStorage.getItem('token') : accessKey,
                'Content-Type': 'application/json',
            },
        })
        console.log(response.data);
        return response.data.responses;
    } catch (error) {
        console.error("Error :", error);
    }
}
// const authenticateRoute = async (route) => {
//     try {
//         const response = await axios.post(`${Api}api/checkAvailableRoute`,{route}, {
//             headers: {
//                 'x-api-key': localStorage.getItem('token') ? localStorage.getItem('token') : accessKey,
//                 'Content-Type': 'application/json',
//             },
//         })
//         // handle token
//         // console.log(response.data);
//         if(response.data.isAvailable === true){
//             return true;
//         }
//         return false;
//     } catch (error) {
//         console.error("Error :", error);
//     }
// }
const getPromptForm = async (route) => {
    try {
        const response = await axios.post(`${Api}api/getPromptForm`,{route}, {
            headers: {
                'x-api-key': localStorage.getItem('token') ? localStorage.getItem('token') : accessKey,
                'Content-Type': 'application/json',
            },
        })
        console.log(response.data);
        if(response.data.status === 200){
            return JSON.parse(response.data.form);
        }
        return false;
    } catch (error) {
        console.error("Error :", error);
    }
}
const modifyPromptForm = async (route,attribute) => {
    try {
        const response = await axios.post(`${Api}api/modifyPromptForm`,{route,attribute}, {
            headers: {
                'x-api-key': localStorage.getItem('token') ? localStorage.getItem('token') : accessKey,
                'Content-Type': 'application/json',
            },
        })
        console.log(response.data);
        // if(response.data.isAvailable === true){
        //     return true;
        // }
        // return false;
    } catch (error) {
        console.error("Error :", error);
    }
}
const handleDeleteRoute = async (route) => {
    try {
        const response = await axios.post(`${Api}api/deleteRoute`,{route}, {
            headers: {
                'x-api-key': localStorage.getItem('token') ? localStorage.getItem('token') : accessKey,
                'Content-Type': 'application/json',
            },
        })
        console.log(response.data);
        if(response.data.status === 401){
            return true;
        }
        return false;
    } catch (error) {
        console.error("Error :", error);
    }
}
const handleEditRoute = async (route,url) => {
    try {
        const response = await axios.post(`${Api}api/editRoute`,{route,url}, {
            headers: {
                'x-api-key': localStorage.getItem('token') ? localStorage.getItem('token') : accessKey,
                'Content-Type': 'application/json',
            },
        })
        console.log(response.data);
        if(response.data.status === 401){
            return true;
        }
        return false;
    } catch (error) {
        console.error("Error :", error);
    }
}
const getAnalytics = async (route)=>{
    try {
        const response = await axios.post(`${Api}api/getAnalytics`,{route}, {
            headers: {
                'x-api-key': localStorage.getItem('token') ? localStorage.getItem('token') : accessKey,
                'Content-Type': 'application/json',
            },
        })
        console.log(response.data);
        if(response.data.status === 200){
            return response.data.analytics;
        }
        return [];
    } catch (error) {
        console.error("Error :", error);
    }
}
const copyOnClick = function(copyText){
    const textarea = document.createElement("textarea")
    textarea.value = copyText;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    // copy alert
    return true;
}

const generateUniqueTestId = ()=> {
    const timestamp = new Date().getTime(); // Get the current timestamp
    const random = Math.random().toString(36).substring(2, 10); // Generate a random string
    const uniqueString = `${timestamp}${random}`; // Combine timestamp and random string

    return uniqueString;
}
const verifyHostPassword = async (credentials)=> {
    try {
        const response = await axios.post(`${Api}api/verifyHostPassword`, credentials, {
            headers: {
                'x-api-key': accessKey,
                'Content-Type': 'application/json',
            },
        })
        // handle token
        console.log(response.data);
        if(response.data.status === 200){
            return response.data.isVerified;
        }
        return false;
    } catch (error) {
        console.error("Error :", error);
    }
}
const fetchTest = async (test_id)=> {
    try {
        const response = await axios.post(`${Api}api/getTest`, {test_id}, {
            headers: {
                'x-api-key': accessKey,
                'Content-Type': 'application/json',
            },
        })
        // console.log(response.data);
        if(response.data.status === 200){
            return response.data.test;
        }
        return false;
    } catch (error) {
        console.error("Error :", error);
    }
}
const liveTest = async (test_id)=> {
    try {
        const response = await axios.post(`${Api}api/liveTest`, {test_id}, {
            headers: {
                'x-api-key': accessKey,
                'Content-Type': 'application/json',
            },
        })
        console.log(response.data);
        if(response.data.status === 200){
            return response.data.isLive;
        }
        return false;
    } catch (error) {
        console.error("Error :", error);
    }
}
const stopTest = async (test_id)=> {
    try {
        const response = await axios.post(`${Api}api/stopTest`, {test_id}, {
            headers: {
                'x-api-key': accessKey,
                'Content-Type': 'application/json',
            },
        })
        console.log(response.data);
        if(response.data.status === 200){
            return response.data.isLive;
        }
        return false;
    } catch (error) {
        console.error("Error :", error);
    }
}
const parseCsv = async (csvFileURL)=> {

    return new Promise((resolve,reject)=>{
        const onComplete = (data) => {
            resolve(data.data);
        }
        Papa.parse(csvFileURL, {
            download: true,
            delimiter: ",",
            header: true,
            skipEmptyLines: true,
            complete: onComplete,
            error: (e) => {
                console.error("Error: ", e);
                reject(e);
            },
        });
    })
}
const validateQuestions = (questions)=>{
    // validate questions array
    const requiredKeys = ['_id', 'course', 'test', 'section', 'type', 'question', 'A', 'B', 'correct', 'description', 'marks', 'time', 'difficulty_level'];
    const optionalKeys = ['C', 'D', 'E'];
    const errorMessages = {
        _id : "Please enter unique question id on line number $line",
        default : "Error found! against $key on line number $line"
    };
    let validation = true;
    for (let index = 0; index < questions.length; index++) {
        for (let index2 = 0; index2 < requiredKeys.length; index2++) {
            if(!questions[index][requiredKeys[index2]] || questions[index][requiredKeys[index2]] === ""){
                console.log(questions[index][requiredKeys[index2]]);
                let validationString = (errorMessages[requiredKeys[index2]]) ? errorMessages[requiredKeys[index2]] : errorMessages['default'];
                console.log(index);
                validationString = validationString.replace("$line",index+2);
                validationString = validationString.replace("$key",requiredKeys[index2]);
                return validationString;
            }
        }
    }
    return true;
}
const fetchQuestions = async (test_id)=> {

    try {
        const response = await axios.post(`${Api}api/fetchQuestions`, {test_id}, {
            headers: {
                'x-api-key': accessKey,
                'Content-Type': 'application/json',
            },
        })
        // console.log(response.data);
        if(response.data.status === 200){
            return response.data.questions;
        }
        return [];
    } catch (error) {
        console.error("Error :", error);
    }
}
const handleQuestionAction = async (test_id,question)=> {

    try {
        const response = await axios.post(`${Api}api/handleQuestionAction`, {test_id,question}, {
            headers: {
                'x-api-key': accessKey,
                'Content-Type': 'application/json',
            },
        })
        console.log(response.data);
        // if(response.data.status === 200){
        //     return response.data.questions;
        // }
        // return [];
    } catch (error) {
        console.error("Error :", error);
    }
}
const fetchParticipant = async (test_id,mobile)=> {
    try {
        const response = await axios.post(`${Api}api/fetchParticipant`, {test_id,mobile}, {
            headers: {
                'x-api-key': accessKey,
                'Content-Type': 'application/json',
            },
        })
        console.log(response.data);
        if(response.data.status === 200){
            return response.data.participant;
        }
        return false;
    } catch (error) {
        console.error("Error :", error);
    }
}
const fetchResult = async (test_id,mobile)=> {
    try {
        const response = await axios.post(`${Api}api/fetchResult`, {test_id,mobile}, {
            headers: {
                'x-api-key': accessKey,
                'Content-Type': 'application/json',
            },
        })
        console.log(response.data);
        if(response.data.status === 200){
            return response.data.result;
        }
        return false;
    } catch (error) {
        console.error("Error :", error);
    }
}
const fetchParticipants = async (test_id,sortBy,order)=> {
    try {
        const response = await axios.post(`${Api}api/fetchParticipants`, {test_id,sortBy,order}, {
            headers: {
                'x-api-key': accessKey,
                'Content-Type': 'application/json',
            },
        })
        console.log(response.data);
        if(response.data.status === 200){
            return response.data.participants;
        }
        return [];
    } catch (error) {
        console.error("Error :", error);
    }
}
const handleParticipantAction = async (test_id,participant)=> {

    try {
        const response = await axios.post(`${Api}api/handleParticipantAction`, {test_id,participant}, {
            headers: {
                'x-api-key': accessKey,
                'Content-Type': 'application/json',
            },
        })
        // console.log(response.data);
        if(response.data.status === 200){
            return true;
        }
        return false;
    } catch (error) {
        console.error("Error :", error);
    }
}
const addResponse = async (route,response)=> {

    try {
        const result = await axios.post(`${Api}api/addResponse`, {route,response}, {
            headers: {
                'x-api-key': accessKey,
                'Content-Type': 'application/json',
            },
        })
        console.log(result.data);
    } catch (error) {
        console.error("Error :", error);
    }
}
const secondsToMinutes = (seconds)=> {
    const min = Math.floor(seconds / 60);
    const sec = seconds % 60;
    return {
        min : min.toString().padStart(2, '0'),
        sec : sec.toString().padStart(2, '0')
    }
}
const formatRelativeTime = (date)=> {
    const now = new Date();
    const elapsed = now - date;

    const seconds = Math.floor(elapsed / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    
    if (hours > 24) {
        return `${days} days ago`;
    } else if (minutes > 60) {
        return `${hours} hours ago`;
    } else if (seconds > 60) {
        return `${minutes} minutes ago`;
    } else if (seconds > 1) {
        return `${seconds} seconds ago`;
    } else {
        return "Just now";
    }
}

const userInfoUpdate = async (test_id,user)=> {
    try {
        const response = await axios.post(`${Api}api/participantJoined`, {test_id,user}, {
            headers: {
                'x-api-key': accessKey,
                'Content-Type': 'application/json',
            },
        })
        console.log(response.data);

        if(response.data.status === 200){
            // save cookie for matching on rejoining
            const expirationDate = new Date();
            expirationDate.setDate(expirationDate.getDate() + 1);
            // Set the first cookie
            document.cookie = `userJoinedTestId=${test_id}; expires=${expirationDate.toUTCString()};`;
            // Set the second cookie
            document.cookie = `userJoinedName=${user.name}; expires=${expirationDate.toUTCString()};`;
            return true;
        }
        return false;
    } catch (error) {
        console.error("Error :", error);
    }
}
const generateRandomString = (length)=>{
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    // const timestamp = new Date().getTime();
    // let randomString = timestamp.toString(10); // Convert timestamp to base36 string
    let randomString = "DIZZI";
    while (randomString.length < length) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters.charAt(randomIndex);
    }
    return randomString;
}


export {fetchPage, getShortUrl, generateQr, validateUrl, extractCodeFromURL, removeHttp, focusEnd, getCookie,_getCookie,_setCookie, setCookie, showHideElement, getHead, windowScrollToTop,googleAuth,authUser,registerUser,checkAvailableRoute,getLinkHistory,getRouteResponses,getPromptForm,modifyPromptForm,handleDeleteRoute,handleEditRoute,getAnalytics,copyOnClick,generateUniqueTestId,verifyHostPassword,fetchTest,liveTest,stopTest,parseCsv,validateQuestions,fetchQuestions,handleQuestionAction,fetchParticipant,fetchResult,fetchParticipants,handleParticipantAction,secondsToMinutes,formatRelativeTime,userInfoUpdate,generateRandomString,addResponse};