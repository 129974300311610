import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import 'chart.js/auto'

function DoughnutChart({chartData}) {

  const chartOptions = {
    responsive: true,
    // maintainAspectRatio: false,
    height: 300,
    scales: {
        x: {
            display: false, // Hide the x-axis (horizontal) completely
        },
        y: {
            display: false, // Hide the y-axis (vertical) completely
        },
    },
    // plugins: {
    //   legend: {
    //     display: false, // Hide the legend
    //   },
    // },
  };

  return (
    <div className="doughnutChartComponent">
      <Doughnut data={chartData} options={chartOptions}/>
    </div>
  )
}

export default DoughnutChart
