import Service from "./Service";
import PostShimmer from "../shimmer/PostShimmer";
import TileShimmer from "../shimmer/TileShimmer";

function Services({data}) {
 
  return (
    <div id="Features" className="container py-5">
      <div className="row ">
        <div className="col-12">
        {(data.title === null) ? 
            <TileShimmer/>
        : 
            <>
              <h5 className="gradientText yCenter gap-2">{data.subTitle}</h5>
              <h2 className="h1 fw-semibold" dangerouslySetInnerHTML={{ __html: data.title }} />
            </>
        }
        </div>
      </div>
      <div className="row mt-3">
        {data.serviceCards.map((serviceCard, index) => {
          return (
            (serviceCard === null) ?
              <div key={"serviceCover"+index} className="col-4">
                <PostShimmer/>
              </div>
            :
              <div key={"serviceCover"+index} className="col-sm-6 col-md-4 mb-5 mb-sm-3">
                <Service key={"service"+index} serviceCard={serviceCard} index={index}/>
              </div>
          )
        })}
      </div>
    </div>
  )
}

export default Services
