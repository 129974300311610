import React, { useEffect, useState } from 'react'
import { Link, Navigate } from 'react-router-dom'
import "./LoginForm.css"
import { Field, Form, Formik } from 'formik'
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google'
import { authUser, googleAuth } from '../../functions'
import { useAuth } from '../../pages/AuthContext'

function LoginForm() {

    const {login} = useAuth();

  const [status,setStatus] = useState(false);
  const [passwordReady,setPasswordReady] = useState(false);
  const [displayMessage,setDisplayMessage] = useState();

  const data = {
    title: "Welcome Back !",
    subTitle: "Dont have an account?",
    checkBoxTitle: "I want to receive Infobio news, trends, offers, or blogs by email.",
    orTitle: "OR CONTINUE WITH",
  }

  const checkUsername = ()=>{
    const email = document.getElementById('email');
    const mobile = document.getElementById('mobile');
    const password = document.getElementById('password');
    const inputDivider = document.getElementById('inputDivider');
    if(email.value =="" && mobile.value == ""){
        setDisplayMessage("Enter Either Email/Mobile")
    }else{
        setDisplayMessage();
        inputDivider.classList.add('d-none')
        email.parentElement.classList.add('d-none')
        mobile.parentElement.classList.add('d-none')
        password.parentElement.classList.remove('d-none');
        setPasswordReady(true)
    }
  }
 
  
    return (
    <GoogleOAuthProvider clientId="1069036692510-72otes5tp0pdk9jcd8cfsifdlq3smdmo.apps.googleusercontent.com">
        <div className='signupForm px-md-5 py-md-2'>
            <div className='py-4 py-md-3 px-3 px-md-5 xCenter gap-3 gap-md-4'>
                <div className="xCenter gap-3">
                    <h3 className="m-0">{data.title}</h3>
                    <p className="m-0 textSecondary">{data.subTitle} <Link to="/auth/signup" className='fw500'>Signup Now</Link></p>
                </div>
                {
                    (displayMessage) ? <h5 className="displayMessage text-danger">{displayMessage}</h5> : <></>
                }
                <div className="fields xCenter gap-3 gap-md-4" style={{color: "#545452"}}>
                    <Formik
                        initialValues={{
                            email: '',
                            mobile: '',
                            password: '',
                        }}
                        onSubmit={async (values) => {
                            const isSucceed = await authUser(values);
                            if(isSucceed){
                                login();
                            }else{
                                setDisplayMessage("Invalid Credentials / User Not Found");
                            }
                        }}
                        >
                            <Form>
                                <div className="field xCenter gap-1">
                                    <label htmlFor="email">Email</label>
                                    <Field 
                                        id="email" 
                                        name="email" 
                                        type="email" 
                                        className="p-2 rounded-0" 
                                        placeholder="Enter Your E-Mail" 
                                    />
                                </div>
                                <div id='inputDivider' className="line my-3 yCenter gap-3">
                                    <hr className="m-0 w-50 border-1" />
                                    <span className="textSecondary text-uppercase inlineText" style={{fontSize: "0.857rem", fontWeight: "600"}}>Or Continue With Mobile</span>
                                    <hr className="m-0 w-50 border-1" />
                                </div>
                                <div className="field xCenter gap-1">
                                    <label htmlFor="mobile">Mobile</label>
                                    <Field 
                                        id="mobile" 
                                        name="mobile" 
                                        type="text" 
                                        className="p-2 rounded-0" 
                                        placeholder="Enter Your Mobile" 
                                    />
                                </div>
                                <div className="field xCenter gap-1 d-none">
                                    <label htmlFor="password">Password</label>
                                    <Field 
                                        id="password" 
                                        name="password" 
                                        type="password" 
                                        className="p-2 rounded-0" 
                                        placeholder="Enter Your password" 
                                        required
                                        minLength="8"
                                    />
                                </div>
                                {
                                    (passwordReady) ?
                                        <button className='btn btn-success rounded-0 w-100 mt-3' type="submit">Proceed Securely</button>
                                    :
                                        <button onClick={checkUsername} className='btn btn-primary rounded-0 w-100 mt-3' type="button">Continue</button>

                                }
                            </Form>
                    </Formik>
                </div>

                <p className="m-0">By continue, you confirm that you've read and accepted our <Link to="">Terms and Conditions</Link> and our <Link to="">Privacy Notice</Link>.</p>

                <div className="line my-1 yCenter gap-3">
                    <hr className="m-0 w-50 border-1" />
                    <span className="textSecondary text-uppercase inlineText" style={{fontSize: "0.857rem", fontWeight: "600"}}>{data.orTitle}</span>
                    <hr className="m-0 w-50 border-1" />
                </div>
                
                <GoogleLogin
                    onSuccess={async (credentialResponse) => {
                        const isSucceed = await googleAuth(credentialResponse.credential);
                        if(isSucceed){
                            login();
                        }
                    }}
                    onError={() => {
                        setDisplayMessage("Opps! Something Went Wromg Try Again")
                    }}
                    useOneTap
                />
            </div>
        </div>
    </GoogleOAuthProvider>
    )
  }

export default LoginForm