import React from 'react'
import { HiOutlineLockClosed } from 'react-icons/hi'

function QuestionPalette({questions,currentPreview,previewQuestion,checkQuestionActive,checkAnswerReveal}) {
  return (
    <div className="controlPalette">
        {
            questions.map((question, index) => {
                if (question._id === "") { return }
                return (
                    <div key={"paletteQueDot" + index} onClick={() => previewQuestion(question._id)}
                        className={(currentPreview && currentPreview._id === question._id)
                            ? "questionDot active" : checkQuestionActive(question._id) ? "questionDot activated" : "questionDot"}>

                        {checkQuestionActive(question._id) ? question._id : <HiOutlineLockClosed size={30} />}
                        {checkAnswerReveal(question._id) ? <span className="revealPendingDot"></span> : ""}
                    </div>
                )
            })
        }
    </div>
  )
}

export default QuestionPalette