import React, { useEffect, useState } from 'react'
import "./ColorCustomizations.css"
import { BiSolidUser } from "react-icons/bi";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { showHideElement, windowScrollToTop } from '../../functions';
import { PiCaretDownBold } from "react-icons/pi";
import TileShimmer from '../shimmer/TileShimmer';

function ColorCustomizations({uiAttributes,modifyUiAttributes}) {
  const data = {
    title: "Customizations",
  }

  const predefinedGradients = [
    {
      primaryColor: "#0d6efd",
      secondryColor: "#00ffdd",
      btnTextColor: "#ffffff"
    },
    {
      primaryColor: "#0041a3",
      secondryColor: "#55FD0D",
      btnTextColor: "#ffffff"
    },
    {
      primaryColor: "#ffffff",
      secondryColor: "#000000",
      btnTextColor: "#cc0000"
    },
    {
      primaryColor: "#000000",
      secondryColor: "#ffffff",
      btnTextColor: "#ffffff"
    },
    {
      primaryColor: "#0041a3",
      secondryColor: "#55FD0D",
      btnTextColor: "#ffffff"
    },
    {
      primaryColor: "#0041a3",
      secondryColor: "#55FD0D",
      btnTextColor: "#ffffff"
    },
    {
      primaryColor: "#0041a3",
      secondryColor: "#55FD0D",
      btnTextColor: "#ffffff"
    },
    {
      primaryColor: "#0041a3",
      secondryColor: "#55FD0D",
      btnTextColor: "#ffffff"
    },
    {
      primaryColor: "#0041a3",
      secondryColor: "#55FD0D",
      btnTextColor: "#ffffff"
    },
  ]

  const [isSolidActive,setIsSolidActive]  = useState(uiAttributes.isSolidActive);
  const [solidColor1, setSolidColor1] = useState(uiAttributes.solidColor1);
  const [solidColor2, setSolidColor2] = useState(uiAttributes.solidColor2);
  const [textColor, setTextColor] = useState(uiAttributes.textColor);

  const [inputColor,setInputColor] = useState(uiAttributes.inputColor);
  const [inputIconColor,setInputIconColor] = useState(uiAttributes.inputIconColor);

  useEffect(() => {
    modifyUiAttributes({
      isSolidActive,
      solidColor1,
      solidColor2,
      textColor,
      inputColor,
      inputIconColor
    })
  
  }, [isSolidActive,solidColor1,solidColor2,textColor,inputColor,inputIconColor])
  


  
  // const saveColorData = () => {
  //   const selectedColorType = document.querySelector("[data-radio-selected='true']").getAttribute("data-color-type");
  //   const colorsData = {
  //     fieldsColor: document.getElementById("fieldColor").getAttribute("data-color-value"),
  //     iconsColor: document.getElementById("iconColor").getAttribute("data-color-value"),
  //     btnTextColor: document.getElementById("btnTextColor").getAttribute("data-color-value"),
  //     colorType: selectedColorType,
  //     gradientHeadings: document.getElementById("gradientTextBtn").getAttribute("data-gradient-text"),
  //   };
  //   if(selectedColorType === "gradient") {
  //     colorsData.color1 = document.getElementById("color1").getAttribute("data-color-value");
  //     colorsData.color2 = document.getElementById("color2").getAttribute("data-color-value");
  //   } else {
  //     colorsData.color = document.getElementById("color1").getAttribute("data-color-value");
  //   }

  //   console.log(colorsData);
  //   modifyUiAttributes(colorsData)
  //   return colorsData;
  // }

  // function colorOutputs(radio) {
  //   const colorType = radio.getAttribute("data-color-type")
  //   if(radio.checked) {
  //     if(colorType === "solid") {
  //       document.getElementById("color2Parent").classList.add("d-none");
  //       document.getElementById("colorResult").style.backgroundImage = "";
  //       document.getElementById("colorResult").style.backgroundColor = "var(--colorPrimary)";
  //     } else {
  //       document.getElementById("color2Parent").classList.remove("d-none");
  //       document.getElementById("colorResult").style.backgroundColor = "";
  //       document.getElementById("colorResult").style.backgroundImage = "linear-gradient(90deg, var(--colorPrimary), var(--colorSecondary))";
  //     }
  //   }
  // }
  
  // function changeColorValue(color, target, targetRoot, parentColorPlate) {
  //   color.value = target.value;
  //   document.documentElement.style.setProperty(targetRoot, color.value);
  //   parentColorPlate.setAttribute("data-color-value", color.value);
  // }
  
  // function makeGradientText(gradientTextSwitch, textToChange) {
  //   if(gradientTextSwitch.checked) {
  //     textToChange.classList.add("gradientText");
  //     gradientTextSwitch.setAttribute("data-gradient-text", true)
  //   } else {
  //     textToChange.classList.remove("gradientText");
  //     gradientTextSwitch.setAttribute("data-gradient-text", false)
  //   }
  // }

  // function showRootVariableValue(colorChoose, colorValue, rootVariable) {
  //   const color = getComputedStyle(document.documentElement).getPropertyValue(rootVariable)
  //   colorChoose.value = color;
  //   colorValue.value = color;
  // }

  // const getSelectedRadio = (radio) => {
  //   if (radio.checked) {
  //     radio.setAttribute("data-radio-selected", true);
  //   } else {
  //     radio.setAttribute("data-radio-selected", false);
  //   }
  // }

  // const disableGradientTextBtn = (activeStyle, gradientTextSwitchBtn ) => {
  //   if(activeStyle === "solid") {
  //     if(gradientTextSwitchBtn.checked) {
  //       gradientTextSwitchBtn.checked = false;
  //       makeGradientText(gradientTextSwitchBtn, document.getElementById("textResult"))
  //     }
  //     gradientTextSwitchBtn.setAttribute("disabled", true);
  //     gradientTextSwitchBtn.classList.remove("cursorPointer");
  //   } else {
  //     gradientTextSwitchBtn.removeAttribute("disabled");
  //     gradientTextSwitchBtn.classList.add("cursorPointer");
  //   }
  // }

  // const setDefinedGradient = (Gradient) => {
  //   document.getElementById("gradientTextBtn").checked = true;
  //   makeGradientText(document.getElementById("gradientTextBtn"), document.getElementById("textResult"))

  //   // const activeGradient = document.createElement("div");
  //   // activeGradient.classList = Gradient.classList;
  //   // activeGradient.classList.replace("tilesHoverEffect", "bg-white");
  //   // activeGradient.innerHTML = Gradient.innerHTML;

  //   // document.getElementById("predefindedGradients").querySelector(".firstSample").innerHTML = "";
  //   // document.getElementById("predefindedGradients").querySelector(".firstSample").append(activeGradient);

  //   document.documentElement.style.setProperty("--colorPrimary", Gradient.getAttribute("data-primary"));;
  //   document.documentElement.style.setProperty("--colorSecondary", Gradient.getAttribute("data-secondary"));;
  //   document.documentElement.style.setProperty("--btnTextColor", Gradient.getAttribute("data-text"));;

  //   const colorPlates = document.querySelectorAll(".colorPlate");
  //   colorPlates.forEach(colorPlate => {
  //     const colorChoose = colorPlate.querySelector(".colorChoose");
  //     const colorValue = colorPlate.querySelector(".colorValue");
  //     const rootColorVariable = colorPlate.getAttribute("data-color-root")

  //     const color = getComputedStyle(document.documentElement).getPropertyValue(rootColorVariable)
  //     colorPlate.setAttribute("data-color-value", color);

  //     showRootVariableValue(colorChoose, colorValue, rootColorVariable);
  //   })

  //   saveColorData();
  // }

  // useEffect(() => {
  //   windowScrollToTop();

  //   const selectColorTypeBtns = document.querySelectorAll(".colorTypeBtn");
  //   const colorPlates = document.querySelectorAll(".colorPlate");
  //   const gradientTextSwitch = document.getElementById("gradientTextBtn");

  //   // setChoosedGradient(template);
    
  //   colorPlates.forEach(colorPlate => {
  //     const colorChoose = colorPlate.querySelector(".colorChoose");
  //     const colorValue = colorPlate.querySelector(".colorValue");
  //     const rootColorVariable = colorPlate.getAttribute("data-color-root")

  //     const color = getComputedStyle(document.documentElement).getPropertyValue(rootColorVariable)
  //     colorPlate.setAttribute("data-color-value", color);

  //     showRootVariableValue(colorChoose, colorValue, rootColorVariable);
  //     // // apply from api
  //     // showRootVariableValue(colorChoose, colorValue, rootColorVariable);

  //     colorChoose.addEventListener("input", () => {
  //       changeColorValue(colorValue, colorChoose, rootColorVariable, colorPlate);
  //       saveColorData();
  //     })
      
  //     colorValue.addEventListener("input", () => {
  //       changeColorValue(colorChoose, colorValue, rootColorVariable, colorPlate);
  //       saveColorData();
  //     })
  //   })

  //   makeGradientText(gradientTextSwitch, document.getElementById("textResult"))
  //   gradientTextSwitch.addEventListener("change", () => {
  //     makeGradientText(gradientTextSwitch, document.getElementById("textResult"))
  //     saveColorData();
  //   })

  //   colorOutputs(document.getElementById("solidRadio"))
  //   selectColorTypeBtns.forEach(selectColorTypeBtn => {
  //     getSelectedRadio(selectColorTypeBtn);
  //     selectColorTypeBtn.addEventListener("change", (e) => {
  //       colorOutputs(e.currentTarget)

  //       gradientTextSwitch.checked = true;
  //       makeGradientText(gradientTextSwitch, document.getElementById("textResult"))

  //       const currentCheckboxValue = e.currentTarget.getAttribute("data-color-type")
  //       selectColorTypeBtns.forEach(btn => {
  //         if(btn.getAttribute("data-color-type") !== currentCheckboxValue) {
  //           btn.checked = false;
  //           getSelectedRadio(btn);
  //         } else {
  //           btn.checked = true;
  //           getSelectedRadio(btn);
  //         }
  //       })
  //       getSelectedRadio(selectColorTypeBtn);
  //       disableGradientTextBtn(document.querySelector("[data-radio-selected='true']").getAttribute("data-color-type"), gradientTextSwitch)
  //       saveColorData();
  //     })
  //   })

  //   disableGradientTextBtn(document.querySelector("[data-radio-selected='true']").getAttribute("data-color-type"), gradientTextSwitch)
    
  //   saveColorData();
  // },[])
  if(uiAttributes){
    return (
      <div className='colorCustomizations xCenter gap-3'>

        <p className='fs-5 m-0 py-2' style={{ color: "var(--bgThemeDark)" }}>{data.title}</p>

        <div className="colorTypes">
          <div className='xCenter gap-2 lighterBg border rounded-4 p-3 bgShadow w-auto'>
            <p className="m-0" style={{ fontWeight: "500" }}>Color Type</p>
            <div className="types yCenter gap-3 justify-content-between flex-wrap">
              <p className="m-0">Select Color Type</p>
              <div className="yCenter gap-3 flex-wrap selectColorType">
                {/* *********************** check box *********************** */}
                <div className="type solid yCenter gap-2 gap-sm-3 border py-2 px-3  rounded-3 bg-white">
                  <span className="inputWrapper cursorPointer allCenter">
                    <input checked={isSolidActive} onChange={(e)=>{setIsSolidActive(isSolidActive ? false : true)}} type="checkbox" name="colorType" id="solidRadio"  className=' cursorPointer colorTypeBtn switch'/>
                  </span>
                  <label htmlFor="solidRadio" className='cursorPointer'>Solid</label>
                </div>
                {/* *********************** check box *********************** */}
                <div className="type gradient yCenter gap-2 gap-sm-3 border py-2 px-3  rounded-3 bg-white">
                  <span className="inputWrapper allCenter cursorPointer">
                    <input checked={!isSolidActive} onChange={(e)=>{setIsSolidActive(isSolidActive ? false : true)}} type="checkbox" name="colorType" id="gradientRadio"  data-color-type="gradient" className='colorTypeBtn cursorPointer  switch'/>
                  </span>
                  <label htmlFor="gradientRadio" className='cursorPointer'>Gradient</label>
                </div>
                {/* *********************** check box *********************** */}
              </div>
            </div>
          </div>
        </div>

        <div className="colors xCenter gap-2 lighterBg border rounded-4 p-3 bgShadow">
          <p className="m-0" style={{ fontWeight: "500" }}>Color Scheme</p>
          <div className="colorPlates xCenter gap-2 p-3 border rounded-3">
            {/* *********************** color input  *********************** */}
            <div className='yCenter gap-3 justify-content-between'>
              <p className="m-0 inlineText">Color 1</p>
              <div id='color1' className="colorPlate color1 yCenter justify-content-end gap-3 flex-wrap" data-color-root="--colorPrimary">
                <div className="inputWrapper border  h-100 px-2 colorCodeResponsive bg-white smFullWidth" style={{width: "128px"}}>
                  <input value={solidColor1} onInput={(e)=>{setSolidColor1(e.target.value)}} type="text" name="color" id="color1value" placeholder='Color Code' className='text-uppercase colorValue ' />
                </div>
                <input value={solidColor1} onInput={(e)=>{setSolidColor1(e.target.value)}} type="color" name="color" className='colorChoose cursorPointer smFullWidth' id='colorChoose1' style={{ width: "100px" }} />
              </div>
            </div>
            {/* *********************** color input  *********************** */}
            {
              (!isSolidActive) ?
                <div id='color2Parent' className='xCenter gap-2'>
                  {/* *********************** color input  *********************** */}
                  <div className='yCenter gap-3 justify-content-between'>
                    <p className="m-0 inlineText">Color 2</p>
                    <div id='color2' className="colorPlate color2 yCenter gap-3 flex-wrap justify-content-start justify-content-sm-end" data-color-root="--colorSecondary">
                      <div className="inputWrapper border  h-100 px-2 colorCodeResponsive bg-white" style={{width: "128px"}}>
                        <input  value={solidColor2} onInput={(e)=>{setSolidColor2(e.target.value)}} type="text" name="color" id="color2value" placeholder='Color Code' className='text-uppercase colorValue ' />
                      </div>
                      <input value={solidColor2} onInput={(e)=>{setSolidColor2(e.target.value)}} type="color" name="color" id='colorChoose2' style={{ width: "100px" }} className='colorChoose cursorPointer' />
                    </div>
                  </div>
                  {/* *********************** color input  *********************** */}

                  {/* *********************** predefinded gradients ***********************  */}
                  
                  <div id='predefindedGradients'>
                    <p className="m-0 inlineText yCenter gap-2">Predefined Gradients <span className="caret cursorPointer" style={{transition: "transform 700ms ease-in-out"}} onClick={(e) => showHideElement(e.currentTarget.parentElement.nextElementSibling, e.currentTarget)}><PiCaretDownBold /></span></p>
                  
                    <div class="dropdownMenu yCenter flex-wrap bg-light p-2 justify-content-start gap-2 border rounded-2 mt-2 d-none">

                      {predefinedGradients ? (
                        predefinedGradients.map((gradient, index) => {
                          return(
                            <div onClick={()=>{setSolidColor1(gradient.primaryColor); setSolidColor2(gradient.secondryColor)}} className='p-1 tilesHoverEffect cubicTransition rounded-2'>
                              <div key={"gradientSample"+index} className='listItem cursorPointer rounded-2 border smallBox' style={{backgroundImage: `linear-gradient(to right, ${gradient.primaryColor}, ${gradient.secondryColor})`}}>
                              </div>
                            </div>
                          )
                        })
                      ) : (
                        <div>No predefined gradients available</div>
                      )}

                    </div>
                    
                  </div>

                </div>
              : <></>
            }

            <div className='yCenter gap-3 flex-wrap justify-content-start justify-content-sm-between mb-2'>
              <p className="m-0 inlineText">Button Text</p>
              <div id='btnTextColor' className="colorPlate yCenter justify-content-start justify-content-sm-end gap-3 flex-wrap" data-color-root="--btnTextColor">
                <div className="inputWrapper border  h-100 px-2 colorCodeResponsive bg-white smFullWidth" style={{width: "128px"}}>
                  <input value={textColor} onInput={(e)=>{setTextColor(e.target.value)}}  type="text" name="color" id="btnTextColorValue" placeholder='Color Code' className='text-uppercase colorValue ' />
                </div>
                <input value={textColor} onInput={(e)=>{setTextColor(e.target.value)}}  type="color" name="color" className='colorChoose cursorPointer smFullWidth' id='btnTextColorChoose' style={{ width: "100px" }} />
              </div>
            </div>

            <div className="colors yCenter gap-4">
              <p className="m-0 inlineText">Result</p>
              <button id='colorResult' className="btn colorResult w-100 border-0 fw-semibold" style={{height: "40px", color: textColor, background : isSolidActive ? solidColor1 : `linear-gradient(to right, ${solidColor1}, ${solidColor2})`}}>This is Button Preview</button>
            </div>

            <div className="colors yCenter gap-1 justify-content-between flex-wrap mt-2">
              <p className="m-0" style={{ fontWeight: "500" }}>Page Heading</p>
              <div className="bg-white textResult yCenter flex-wrap flex-sm-nowrap justify-content-center justify-content-sm-between gap-3 position-relative py-2 px-3 border rounded-3 w-100">
                {/* <div className="toggleGradientText yCenter flex-wrap gap-3">
                  <div className="inputWrapper gridCenter">
                    <input type="checkbox" name="checkbox" className="rounded-pill switch cursorPointer" id='gradientTextBtn'/>
                  </div>
                  <p className='m-0' style={{whiteSpace: "nowrap"}}>Gradient Headings</p>
                  <span className="inforOnHover allCenter cursorPointer transition position-relative" data-hover-info="Select 'Gradient' to customize" style={{ opacity: "70%"}}><AiOutlineInfoCircle /></span>
                </div> */}
                <h5 className={isSolidActive ? "m-0 fw-medium textResult" : "m-0 fw-medium textResult gradientText"} style={isSolidActive ? {color: solidColor1} : {backgroundImage : `linear-gradient(to right, ${solidColor1}, ${solidColor2})`}} id="textResult">This is Page Heading Text Preview</h5>
              </div>
            </div>

          </div>

          
        </div>

        <div className="colorPlates xCenter gap-2 p-3 lighterBg border rounded-4 bgShadow">

          <div className='yCenter gap-3 justify-content-between'>
            <p className="m-0">Fields Color</p>
            <div id='fieldColor' className="colorPlate yCenter gap-2 gap-lg-3 flex-wrap justify-content-end" data-color-root="--fieldColor">
              <input value={inputColor} onInput={(e)=>{setInputColor(e.target.value)}} type="color" name="color"  id='fieldColor' style={{ width: "100px" }} className='colorChoose cursorPointer'/>
              <div className="inputWrapper border  h-100 px-2 colorCodeResponsive colorCodeWidth position-relative bg-white">
                <input value={inputColor} onInput={(e)=>{setInputColor(e.target.value)}} type="text" name="color" id="fieldColorValue" placeholder='Color Code' className='text-uppercase w-100 colorValue' />
                <span className="inforOnHover position-absolute allCenter cursorPointer transition" data-hover-info="Use Light Colors" style={{right: "5%", top: "20%", opacity: "70%"}}><AiOutlineInfoCircle /></span>
              </div>
            </div>
          </div>

          <div className='yCenter gap-3 justify-content-between'>
            <p className="m-0">Icons Color</p>
            <div id='iconColor' className="colorPlate yCenter gap-2 gap-lg-3 flex-wrap justify-content-end" data-color-root="--iconColor">
              <input value={inputIconColor} onInput={(e)=>{setInputIconColor(e.target.value)}} type="color" name="color" id='iconColor' style={{ width: "100px" }} className='colorChoose cursorPointer' />
              <div className="inputWrapper border  h-100 px-2 colorCodeResponsive colorCodeWidth bg-white">
                <input value={inputIconColor} onInput={(e)=>{setInputIconColor(e.target.value)}} type="text" name="color" id="iconColorValue" placeholder='Color Code' className='text-uppercase w-100 colorValue' />
              </div>
            </div>
          </div>

          <div className="colors yCenter gap-3 justify-content-between">
            <p className="m-0">Color Result</p>
            <div className="inputWrapper p-2 yCenter gap-2 smFullWidth" style={{backgroundColor: inputColor, width: "326PX", }}>
                <span className="iconWrapper" style={{color: inputIconColor}}><BiSolidUser /></span>
                <input type="text" name="lastName" placeholder="Input Color" style={{borderLeft: `1px solid ${inputIconColor}`, backgroundColor: inputColor}} className='px-2' />
            </div>
          </div>

        </div>

      </div>
    )
  }else{
    return <TileShimmer/>
  }
}

export default ColorCustomizations
