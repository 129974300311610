import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getRouteResponses } from '../functions';
import { DataTable } from 'simple-datatables';
import RouteHistory from '../components/routeHistory/RouteHistory';
import TileShimmer from '../components/shimmer/TileShimmer';

function Responses() {
    const {route} = useParams();
    const [isTableReady,setIsTableReady] = useState(false);
    useEffect(()=>{
        const getData = async ()=>{
            const responses = await getRouteResponses(route);
            document.querySelector("#routeResponsesTable").innerHTML = "";
            // console.log(responses);
            if(responses.length > 0){
                const lastResponseArray = responses.at(0);
                const lastResponse = JSON.parse(lastResponseArray.response);
                const responseKeys = Object.keys(lastResponse);
                const keys = Object.keys(lastResponse);
                keys.push("When?");
                keys.unshift("Sr No.")
                // console.log(keys);
                new DataTable("#routeResponsesTable",{
                    searchable : true,
                    sortable: true,
                    border: true,
                    classes: {
                        container: "table-responsive",
                        table : "datatable-table table table-bordered table-striped",
                    },
                    columns: [
                        { select: 0, width: "5%" },
                        { select: 1, width: "65%" },
                        { select: 2, width: "15%" },
                        { select: 3, width: "15%" },
                      ],
                    perPage : 5,
                    data : {
                        headings : keys.map((key)=>{
                            return key;
                        }),
                        data: responses.map((responseRow,index)=>{
                            const response = JSON.parse(responseRow.response);
                            console.log(response);
                            const row = responseKeys.map((key)=>{
                                return response[key];
                            });
                            row.push(responseRow.addedOn);
                            row.unshift(index+1);
                            return row;
                        })
                    }
                    
                });
            }else{
                new DataTable("#routeResponsesTable")
            }
            setIsTableReady(true);
        }
        getData();
    },[route])
  return (
    <>
    <div className='container-lg'>
        <h2 className='titleFont mt-5'>Responses</h2>
        <div className={(isTableReady) ? "row" : "d-none"}>
            <div className="col">
                <div className="table-responsive">
                    <div id="routeResponsesTable"></div>
                </div>
            </div>
        </div>
        {
            (!isTableReady) ? <TileShimmer/> : <></>
        }
    </div>
    <RouteHistory refreshHistory={true}/>
    </>
  )
}

export default Responses