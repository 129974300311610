import React from 'react'
import "./Banner.css"
import TileShimmer from '../../shimmer/TileShimmer'

function Banner({data}) {

  return (
    (data === null) ? 
      <TileShimmer/>
    :
      <div id='Start' className='container-fluid py-5 allCenter position-relative' style={{backgroundColor: "var(--bannerBg)", zIndex: "-2"}}>
        <div className="wrapper py-3 allCenter gap-3 text-white z-2">
          <p className='m-0 extraLargeFont h1 text-uppercase'>{data.heading}</p>
          <p className='m-0 fs-4'>{data.subHeading}</p>
          <button className='btn btn-primary rounded-0 boxShadowWhite cursorPointer z-3'>{data.btnText}</button>
        </div>
        <span className="imgWrapper position-absolute vectorWrapper" style={{width: "20%", left: "2%", zIndex: "-1"}}>
            <img src={data.leftVector} alt="vector" className='w-100 h-100 img-fluid'/>
        </span>
        <span className="imgWrapper position-absolute vectorWrapper" style={{width: "25%", left: "70%", zIndex: "-1"}}>
            <img src={data.rightVector} alt="vector" className='w-100 h-100 img-fluid'/>
        </span>
      </div>
  )
}

export default Banner
