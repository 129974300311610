import React, { useEffect, useState } from "react";
import "./Analytics.css"
import BarChart from "../components/charts/BarChart";
import DoughnutChart from "../components/charts/DoughnutChart";
import MapChart from "../components/charts/MapChart";
import { Link, useParams } from "react-router-dom";
import { getAnalytics } from "../functions";

function Analytics() {
    const {route} = useParams();


    const [analyticsData,setAnalyticsData] = useState({
        totalClicks:0,dailyClicks : [],refererData:[],devicesData:[],countriesData:[ ["Country", "Clicks"]]
    });
    useEffect(() => {
      const getData =  async ()=>{
        const data = await getAnalytics(route);
        setAnalyticsData(data);
      }
      getData()
      
    }, [route])

    const mapChart = analyticsData.countriesData;

    const dailyChart = {
        labels: analyticsData.dailyClicks.map(data => data.day),
        datasets: [
            {
                label: "Clicks",
                data: analyticsData.dailyClicks.map(data => data.clicks),
                backgroundColor: "#cc0000",
                // borderColor: "#0041a3",
                borderWidth: 1,
                tension: 0.4,
            },
            {
                label: "Trend",
                type: "line",
                data: analyticsData.dailyClicks.map(data => data.clicks),
                backgroundColor: "#0041a3",
                borderColor: "#0041a3",
                borderWidth: 1,
                tension: 0.4,
            },
        ],
    }

    const referres = {
        labels: analyticsData.refererData.map(data => data.source),
        datasets: [
            {
                label: "Clicks",
                data: analyticsData.refererData.map(data => data.clicks),
                backgroundColor: [
                    '#a678ff',
                    '#ffa5f0',
                    'rgb(54, 162, 235)',
                ],
                hoverOffset: 4
            },
        ],
    }

    const device = {
        labels: analyticsData.devicesData.map(data => data.source),
        datasets: [
            {
                label: "Clicks",
                data: analyticsData.devicesData.map(data => data.clicks),
                backgroundColor: [
                    'rgb(255, 99, 132)',
                    '#47ceff',
                    '#ffa351',
                ],
                hoverOffset: 4
            },
        ],
    }


    return (
        <div className="container-fluid lighterBg pt-4" id="analytics" data-page="analytics">
            <div className="anlyticsHero m-2 bg-white rounded-2 p-3 border-bottom allCenter gap-3">
                <h4 className="m-0">Lifetime Analytics</h4>
                <div className="yCenter flex-wrap justify-content-evenly gap-4 gap-md-5">
                    <div className="clicks allCenter gap-2">
                        <div className="analyticsIcon yCenter gap-2">
                            <div className="clicksIcon bg-success" />
                            <p className="m-0">Clicks: </p>
                        </div>
                        <div className="value d-flex flex-column align-items-start gap-2">
                            <p className="fs-4">{analyticsData.totalClicks}</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* charts ======================== */}
            <div className="row justify-content-center py-5">

                <div className="col-lg-10">

                    <div className="xCenter gap-5">
                        <div className="activity xCenter gap-4">
                            <h4 className="m-0">Activity</h4>
                            <div className="xCenter gap-3">

                                <div className="activityChart p-3 bg-white rounded-2">
                                    <div className="xCenter gap-2">
                                        <p className="title m-0 fw500">Lifetime &#40;Daily Chart&#41;</p>
                                        <BarChart chartData={dailyChart} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

                <div className="col-md-10 col-lg-8">

                    <div className="mt-5 xCenter gap-5">

                        {/* <div className="topLinks xCenter gap-4">
                            <h4 className="m-0">Top Performing Links</h4>
                            <div className="rounded-3 bg-white p-3 xCenter gap-3">
                                <div className="titles d-flex align-items-end justify-content-end">
                                    <p className="m-0 lighterText">Clicks</p>
                                </div>
                                <div className="links xCenter">
                                    <div className="yCenter justify-content-between">
                                        <div className="linkText">Free Link Shortner</div>
                                        <p className="m-0 fw500">92</p>
                                    </div>
                                    <hr className="my-2" />
                                    <div className="yCenter justify-content-between">
                                        <div className="linkText">Get Chance to Win Mobile</div>
                                        <p className="m-0 fw500">89</p>
                                    </div>
                                    <hr className="my-2" />
                                    <div className="yCenter justify-content-between">
                                        <div className="linkText">Increase Website Reach</div>
                                        <p className="m-0 fw500">64</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="topLocations xCenter gap-4">
                            <h4 className="m-0">Top Locations</h4>
                            <div className="rounded-3 bg-white p-3 xCenter gap-3">
                                <p className="m-0">Country</p>
                                <MapChart chartData={mapChart} />
                                <hr className="m-0" />
                                <div className="info px-3">
                                    <p className="m-0">
                                        <Link to="" className="text-black">
                                            <u>Learn more</u>
                                        </Link>{" "}about how we track your visitors by country and city
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="topReferres xCenter gap-4">
                            <h4 className="m-0">Top Referres</h4>
                            <div className="rounded-3 bg-white p-3 xCenter gap-3">
                                <p className="m-0">Social</p>
                                <div className="itemsCenter doughnutChart">
                                    <DoughnutChart chartData={referres} />
                                </div>
                                <hr className="m-0" />
                                <div className="info px-3">
                                    <p className="m-0">Visitors who come to your Linktree via a standalone URL (not via another platform) are called direct visitors.{" "}
                                        {/* <Link to="" className="text-black">
                                            <u>Learn more</u>
                                        </Link> */}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="deviceAnalytics xCenter gap-4">
                            <h4 className="m-0">Device Analytics</h4>
                            <div className="rounded-3 bg-white p-3 xCenter gap-3">
                                <p className="m-0">Device</p>
                                <div className="itemsCenter doughnutChart">
                                    <DoughnutChart chartData={device} />
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    );
}

export default Analytics;
