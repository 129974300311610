import React from 'react'
import AuthInformation from '../components/authInformation/AuthInformation'
import LoginForm from '../components/loginForm/LoginForm'

function Login() {
  return (
    <div className='container-fluid'>
        <div className="row">
            <div className="col-md-6 lightestBg">
                <div className="sticky-top px-md-3">
                    <div className="p-2 py-3" style={{width: "140px"}}>
                        <img src="https://s.dizzi.link/img/logo.png" alt="logo" className='w-100 h-100' />
                    </div>
                </div>
            </div>
            <div className="col-md-6"></div>
        </div>
      <div className="row">
        <div className="col-md-6 gx-0 lightestBg pb-4 px-3">
          <AuthInformation />
        </div>
        <div className="col-md-6 gx-0 "><LoginForm/></div>
      </div>
    </div>
  )
}

export default Login