import React from 'react'
import "./TileShimmer.css"
function TileShimmer() {
  return (
    <article>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="shimmer"></div>
    </article>
  )
}

export default TileShimmer